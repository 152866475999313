import { v4 as uuidv4 } from "uuid";
import { type RuleGroupType } from "react-querybuilder";
import { type SubmitViewProps } from "@models/submit-view-props.model";
import { type Sort } from "@models/sort.model";
import { type View } from "@models/view.model";
import { convertFilterValues } from "@logic/view.logic";

const getCreateViewModel = (name: string, viewData: SubmitViewProps, sort?: Sort): View => {
  const { shared, filter, moreOptions } = viewData;
  const filterViewModel = convertFilterValues(filter);

  const view = {
    id: uuidv4(),
    name,
    status: "pending",
    filter: filterViewModel as RuleGroupType,
    sort,
    moreOptions,
    created: {},
    shared
  } as const satisfies View;

  return view;
};

const getUpdateViewModel = (name: string, currentView: View, viewData: SubmitViewProps, sort?: Sort): View => {
  const { shared, filter, moreOptions } = viewData;
  const filterViewModel = convertFilterValues(filter);

  const view = {
    ...currentView,
    moreOptions,
    name,
    filter: filterViewModel as RuleGroupType,
    sort,
    shared
  } as const satisfies View;

  return view;
};

export { getCreateViewModel, getUpdateViewModel };
