import { Grid } from "@mui/material";
import { type GlobalSearchItemProps } from "@models/global-search-item-props.model";
import {
  getAdmissionDate,
  getNormalizedHighlights,
  mergeOriginalValueWithHighlights,
  getHighlightText
} from "@logic/global-search.logic";
import { ItemWrapper, SearchField } from "@components/generic/generic.component";

const GlobalSearchItem = (props: GlobalSearchItemProps): JSX.Element => {
  const { option, goToAccount, inputValue } = props;
  const admissionDate = getAdmissionDate(option);
  const normalizedHighlights = getNormalizedHighlights(option);
  const facility = normalizedHighlights.FACILITY?.[0];
  const highlightedFacility = mergeOriginalValueWithHighlights(
    option.data.facility!,
    normalizedHighlights.FACILITY!
  );
  const highlightedPatientName = normalizedHighlights.PATIENTNAME?.[0];
  const highlightedDbNum = normalizedHighlights.DBNUM?.[0];
  const highlightStatus = normalizedHighlights.STATUS?.[0];
  const higlightMRN = normalizedHighlights["MEDICALRECORDNUMBERS/NUMBER"]?.[0];
  const highlightInsClaimNumber = normalizedHighlights["INSURANCECLAIMNUMBERS/CLAIMNUMBER"]?.[0];

  if (facility) {
    delete normalizedHighlights.FACILITY;
  }

  if (highlightedPatientName) {
    delete normalizedHighlights.PATIENTNAME;
  }

  const getTitle = (title: string | undefined): string => {
    if (title && title?.length > 60) {
      return title;
    }

    return "";
  };

  return (
    <ItemWrapper
      onClick={() => goToAccount(option)}
    >
      <Grid container spacing={1} alignItems="flex-start" sx={{ paddingLeft: "0rem" }}>
        <Grid item xs={12} sx={{ padding: "0rem", width: "100%" }}>
          <Grid container sx={{ paddingLeft: "0rem" }}>
            <Grid item xs={4}>
              {!highlightedDbNum && (
                <SearchField variant="body2" className="pendo-ignore" data-dd-privacy="mask">
                  {option.data.dbnum?.toUpperCase()}
                </SearchField>
              )}

              {highlightedDbNum && (
                <SearchField
                  variant="body2"
                  color="textPrimary"
                  className="pendo-ignore"
                  data-dd-privacy="mask"
                  dangerouslySetInnerHTML={{
                    __html: `<span class="highlighted-text">${getHighlightText(inputValue, highlightedDbNum).toUpperCase()}</span>`
                  }}
                />
              )}
              {option.data.serviceLine && (
                <SearchField variant="body2">
                  {option.data.serviceLine}
                </SearchField>
              )}
              {!highlightedPatientName && (
                <SearchField
                  className="pendo-ignore"
                  data-dd-privacy="mask"
                  variant="body2"
                  title={getTitle(option.data.patientName)}
                >
                  {option.data.patientName?.toUpperCase()}
                </SearchField>
              )}

              {highlightedPatientName && (
                <SearchField
                  title={getTitle(highlightedPatientName)}
                  variant="body2"
                  color="textPrimary"
                  className="pendo-ignore"
                  data-dd-privacy="mask"
                  dangerouslySetInnerHTML={{
                    __html:
                      `<span class="highlighted-text">${getHighlightText(inputValue, highlightedPatientName).toUpperCase()}</span>`
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {highlightedFacility && (
                <SearchField
                  title={getTitle(highlightedFacility)}
                  variant="body2"
                  color="textPrimary"
                  className="pendo-ignore"
                  data-dd-privacy="mask"
                  dangerouslySetInnerHTML={{
                    __html:
                      `<span class="highlighted-text">${getHighlightText(inputValue, highlightedFacility).toUpperCase()}</span>`
                  }}
                />
              )}
              {!facility && option.data.facility && (
                <SearchField
                  className="pendo-ignore"
                  data-dd-privacy="mask"
                  variant="body2"
                  color="textPrimary"
                  title={getTitle(option.data.facility)}
                  sx={{ display: "block" }}
                >
                  {option.data.facility?.toUpperCase()}
                </SearchField>
              )}
              {admissionDate && (
                <SearchField className="pendo-ignore" data-dd-privacy="mask" variant="body2">
                  Admission Date {admissionDate}
                </SearchField>
              )}
              {!highlightStatus && (
                <SearchField variant="body2" title={getTitle(option.data?.status)}>
                  {option.data?.status}
                </SearchField>
              )}
              {highlightStatus && (
                <SearchField
                  variant="body2"
                  color="textPrimary"
                  title={getTitle(highlightStatus)}
                  dangerouslySetInnerHTML={{
                    __html:
                      `<span class="highlighted-text">${getHighlightText(inputValue, highlightStatus).toUpperCase()}</span>`
                  }}
                />
              )}
              {higlightMRN && (
                <SearchField
                  variant="body2"
                  color="textPrimary"
                  title={getTitle(higlightMRN)}
                  dangerouslySetInnerHTML={{
                    __html:
                    `<span class="highlighted-text">MRN: ${getHighlightText(inputValue, higlightMRN).toUpperCase()}</span>`
                  }}
                />
              )}
              {highlightInsClaimNumber && (
                <SearchField
                  variant="body2"
                  color="textPrimary"
                  title={getTitle(highlightInsClaimNumber)}
                  dangerouslySetInnerHTML={{
                    __html:
                    `<span class="highlighted-text">Insurance Claim Number:
                    ${getHighlightText(inputValue, highlightInsClaimNumber).toUpperCase()}</span>`
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {normalizedHighlights?.CLAIMNUMBER && (
                <SearchField
                  variant="body2"
                  color="textPrimary"
                  className="pendo-ignore"
                  data-dd-privacy="mask"
                  title={getTitle(normalizedHighlights?.CLAIMNUMBER?.[0])}
                  dangerouslySetInnerHTML={{
                    __html:
                      `<span class="highlighted-text">
                        Claim: ${getHighlightText(inputValue, normalizedHighlights?.CLAIMNUMBER?.[0])}
                      </span>`
                  }}
                />
              )}
              {!normalizedHighlights?.CLAIMNUMBER && option.data?.claimNumber && (
                <SearchField
                  className="pendo-ignore"
                  data-dd-privacy="mask"
                  variant="body2"
                  title={getTitle(option.data?.claimNumber)}
                >
                  Claim: {option.data?.claimNumber}
                </SearchField>
              )}
              {normalizedHighlights?.BILLNUMBER && (
                <SearchField
                  variant="body2"
                  color="textPrimary"
                  className="pendo-ignore"
                  data-dd-privacy="mask"
                  title={getTitle(option.data?.billNumber)}
                  dangerouslySetInnerHTML={{
                    __html:
                      `<span class="highlighted-text">
                        Bill: ${getHighlightText(inputValue, normalizedHighlights?.BILLNUMBER?.[0])}
                      </span>`
                  }}
                />
              )}
              {!normalizedHighlights?.BILLNUMBER && option.data?.billNumber && (
                <SearchField variant="body2" title={getTitle(option.data?.billNumber)}>
                  Bill: {option.data?.billNumber}
                </SearchField>
              )}
              {normalizedHighlights?.INVOICENUMBER && (
                <SearchField
                  variant="body2"
                  color="textPrimary"
                  className="pendo-ignore"
                  data-dd-privacy="mask"
                  title={getTitle(normalizedHighlights?.INVOICENUMBER?.[0])}
                  dangerouslySetInnerHTML={{
                    __html:
                      `<span class="highlighted-text">
                        Invoice: ${getHighlightText(inputValue, normalizedHighlights?.INVOICENUMBER?.[0])}
                      </span>`
                  }}
                />
              )}
              {normalizedHighlights?.SSN && (
                <SearchField
                  variant="body2"
                  color="textPrimary"
                  className="pendo-ignore"
                  data-dd-privacy="mask"
                  title={getTitle(normalizedHighlights?.SSN?.[0])}
                  dangerouslySetInnerHTML={{
                    __html:
                      `<span class="highlighted-text">
                        SSN: ***-**-<strong>
                          ${getHighlightText(inputValue, normalizedHighlights?.SSN?.[0], "SSN")?.slice(-4)}<strong>
                      </span>`
                  }}
                />
              )}
              {normalizedHighlights?.PATIENTDOB && (
                <SearchField
                  variant="body2"
                  color="textPrimary"
                  className="pendo-ignore"
                  data-dd-privacy="mask"
                  title={getTitle(normalizedHighlights?.PATIENTDOB?.[0])}
                  dangerouslySetInnerHTML={{
                    __html:
                      `<span class="highlighted-text">
                        Patient DOB: ${getHighlightText(inputValue, normalizedHighlights?.PATIENTDOB?.[0])}
                      </span>`
                  }}
                />
              )}
              {normalizedHighlights?.PHONENUMBER && (
                <SearchField
                  variant="body2"
                  color="textPrimary"
                  className="pendo-ignore"
                  data-dd-privacy="mask"
                  title={getTitle(normalizedHighlights?.PHONENUMBER?.[0])}
                  dangerouslySetInnerHTML={{
                    __html:
                      `<span class="highlighted-text">
                        Phone Number: ${getHighlightText(inputValue, normalizedHighlights?.PHONENUMBER?.[0])}
                      </span>`
                  }}
                />
              )}
              {normalizedHighlights?.ACCIDENTREPORTNUMBER && (
                <SearchField
                  variant="body2"
                  color="textPrimary"
                  className="pendo-ignore"
                  data-dd-privacy="mask"
                  title={getTitle(normalizedHighlights?.ACCIDENTREPORTNUMBER?.[0])}
                  dangerouslySetInnerHTML={{
                    __html:
                      `<span class="highlighted-text">
                        Accident Report Number: ${getHighlightText(inputValue, normalizedHighlights?.ACCIDENTREPORTNUMBER?.[0])}
                      </span>`
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ItemWrapper>
  );
};

export default GlobalSearchItem;
