import { v4 as uuidv4 } from "uuid";
import { type ShareValueType } from "@models/share-value-type.model";

const defaultShare: ShareValueType[] = [{
  key: uuidv4(),
  value: "",
  label: "",
  readonly: false
}];

export default defaultShare;
