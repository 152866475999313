import { type QueryKey, useMutation, type MutationFunction, useQueryClient, type UseMutateFunction } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { type BaseStore } from "@models/base-store.model";
import retry from "@utils/store.util";

const useAddStore = <TStore>(key: QueryKey, mutationFn: MutationFunction<void, TStore>,
  store: BaseStore<TStore>, successFn?: VoidFunction): UseMutateFunction<void, unknown, TStore> => {
  const storeRef = useRef(store);
  const queryClient = useQueryClient();
  const { status, error, mutate } = useMutation({
    mutationKey: key,
    mutationFn,
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({ queryKey: key, exact: true });
      storeRef.current.add?.(variables);
      successFn?.();
    },
    retry
  });

  useEffect(() => {
    storeRef.current.setIsLoading?.(status === "pending");
  }, [status]);

  useEffect(() => {
    storeRef.current.setError?.(error);
  }, [error]);

  return mutate;
};

export default useAddStore;
