import { AzureKeyCredential, SearchClient } from "@azure/search-documents";
import { type GlobalSearchItem } from "@models/global-search-item.model";
import { getList } from "@utils/api.util";

const URL = `${import.meta.env.VITE_GLOBAL_SEARCH_API_URL}/search`;
const SEARCH_SERVICE_URL = `https://${import.meta.env.VITE_SEARCH_SERVICE_NAME}.search.windows.net`;
const SEARCH_INDEX_NAME = import.meta.env.VITE_SEARCH_INDEX_NAME;

const getGlobalSearch = async (text: string, take?: number): Promise<GlobalSearchItem[]> => {
  const queryParams = take ? `?take=${take}` : "";
  const urlWithParams = `${URL}${queryParams}&text=${text}`;
  const token = localStorage.getItem("accessToken")!;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json"
  };
  return getList(urlWithParams, headers);
};

const cognitiveSearchClient = new SearchClient(
  SEARCH_SERVICE_URL,
  SEARCH_INDEX_NAME,
  new AzureKeyCredential(import.meta.env.VITE_SEARCH_API_KEY)
);

export { getGlobalSearch, cognitiveSearchClient };
