import { produce } from "immer";

const updateItemProperty = <T, TValue>(obj: T, property: string, value: TValue): T => {
  const updatedItem = produce(obj, draft => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (draft as any)[property] = value;
  });
  return updatedItem;
};

export default updateItemProperty;
