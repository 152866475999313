import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { type BaseStore } from "@models/base-store.model";
import { type Session } from "@models/session.model";
import { getError } from "@utils/error.util";
import updateItemProperty from "@utils/object.util";

interface Store extends BaseStore<Session> {
  session: Session;
}

const useSessionStore = create<Store>()(
  immer(
    devtools(
      (set): Store => ({
        session: {},

        setIsLoading: isLoading => {
          set(state => ({ ...state, isLoading }), false, "setIsLoading");
        },

        setError: err => {
          const error = getError(err);
          set(state => ({ ...state,
            session: { ...state.session, errors: error ? [...state.session.errors ?? [], error] : [] } }), false, "setError");
        },

        patch: patch => {
          set(state => ({ ...state, session: updateItemProperty(state.session, patch.property, patch.value) }), false, "patch");
        }
      }),
      { name: "Session" }
    )
  )
);

export default useSessionStore;
