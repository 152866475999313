import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { type ViewSearchResult } from "@models/view-search-result.model";
import { type PaginatedStore } from "@models/paginated-store.model";
import { getError } from "@utils/error.util";

export interface Store extends PaginatedStore<ViewSearchResult> {
  workedAccounts: string[];
  workedTodayAccounts: ViewSearchResult[];
  addWorkedAccount: (incidentId: string) => void;
  addWorkedAccounts: (incidentIds: string[]) => void;
  removeWorkedAccount: (incidentId: string) => void;
  setAccountWorkedToday: (incidentId: string) => void;
}

const useSearchStore = create<Store>()(
  devtools(
    (set): Store => ({
      data: [],
      totalCount: 0,
      totalBillCount: 0,
      workedAccounts: [],
      workedTodayAccounts: [],

      setAccountWorkedToday: (incidentId: string) => {
        set(state => {
          const workedAccountLoaded = state.data.filter(x => x.incidentId === incidentId).length > 0;
          const workedTodayAccounts = [...state.workedTodayAccounts];
          if (workedAccountLoaded) {
            const result = state.data.filter(x => x.incidentId === incidentId)[0];
            if (result) {
              workedTodayAccounts.push({
                ...result,
                isWorkedToday: true
              });
            }
          }

          return {
            ...state,
            workedTodayAccounts,
            data: state.data.filter(x => x.incidentId !== incidentId)
          };
        }, false, "setAccountWorkedToday");
      },

      setIsLoading: isLoading => {
        set(state => ({ ...state, isLoading }), false, "setIsLoading");
      },

      setError: err => {
        const error = getError(err);
        set(state => ({ ...state, error }), false, "setError");
      },

      reset: () => set({ data: [], totalCount: 0, totalBillCount: 0, workedAccounts: [], workedTodayAccounts: [] }, false, "reset"),

      addWorkedAccount: incidentId => {
        set(state => ({ ...state, workedAccounts: [...state.workedAccounts, incidentId] }), false, "addWorkedAccount");
      },

      removeWorkedAccount: incidentId => {
        set(state => (
          { ...state, workedAccounts: state.workedAccounts.filter(x => x !== incidentId) }
        ), false, "removeWorkedAccount");
      },

      addWorkedAccounts: incidentIds => {
        set(state => (
          { ...state, workedAccounts: [...state.workedAccounts, ...incidentIds.filter(x => !state.workedAccounts.includes(x))] }
        ), false, "addWorkedAccounts");
      },

      loadSingle: search => {
        set(state => ({
          ...state,
          data: search.data,
          totalCount: search.totalCount > 0 ? search.totalCount : state.totalCount,
          totalBillCount: search.totalBillCount > 0 ? search.totalBillCount : state.totalBillCount
        }), false, "loadSingle");
      }
    }),
    { name: "SearchStore" }
  )
);

export default useSearchStore;
