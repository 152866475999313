import type React from "react";
import { Card } from "@mui/material";
import { LoadingBlock } from "@components/generic/generic.component";

const tempArr = [1, 2, 3];

const LoadingLeftPlaceholder: React.FC = () => (
  <>
    {tempArr.map((item => (
      <Card sx={{ margin: "0 2rem 1rem 1rem" }} key={item}>
        <LoadingBlock variant="rounded" height="12rem" animation="wave" />
      </Card>
    )))}
  </>

);

export default LoadingLeftPlaceholder;
