import { type HubConnection, HubConnectionState } from "@microsoft/signalr";
import { type BasicView } from "@models/basic-view.model";
import { type View } from "@models/view.model";
import { postRawResponse, patch, getList, get, update } from "@utils/api.util";
import { type AxiosResponse } from "axios";

const URL = `${import.meta.env.VITE_WORK_QUEUE_API_URL}/queue-work`;

const extractGuid = (url: string): string | null => {
  const regex = /%20=%20([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})%20%7D/gi;
  const matches = url.match(regex);
  const guid = matches ? matches[0].replace(/%20=%20|%20%7D/g, "") : null;
  return guid;
};

const addViewToDBCallback = async (response: AxiosResponse, view: View, signalRHub?: HubConnection): Promise<void> => {
  const createdViewId = extractGuid(response.headers.location);
  const userIds = view.shared?.map(user => user.userId);
  const serializedSharedIds = JSON.stringify(userIds);

  if (signalRHub?.state === HubConnectionState.Connected
    && view.shared && view.shared.length > 0) {
    void signalRHub?.send("SendAnalystSharedBucket", createdViewId, JSON.stringify(serializedSharedIds));
  }
};

const addView = async (view: View, signalRHub?: HubConnection): Promise<void> => {
  const response = await postRawResponse(`${URL}`, view);
  await addViewToDBCallback(response, view, signalRHub);
};

const acceptView = async (workQueueId: string): Promise<void> => (
  patch(`${URL}/${workQueueId}/accept`)
);
const removeView = async (workQueueId: string): Promise<void> => (
  patch(`${URL}/${workQueueId}/remove`)
);
const undoRemoveView = async (workQueueId: string): Promise<void> => (
  patch(`${URL}/${workQueueId}/undo-remove`));

const updateView = async (view: View, signalRHub?: HubConnection): Promise<void> => {
  await update(`${URL}`, view);

  if (signalRHub?.state === HubConnectionState.Connected
    && view.shared && view.shared.length > 0) {
    void signalRHub?.send("SendAnalystSharedBucket", view.id, JSON.stringify(view.shared.map(user => user.userId)));
  }
};

const shareView = async (workQueueId: string, userIds: string[], signalRHub?: HubConnection): Promise<void> => {
  await patch(`${URL}/${workQueueId}/share`, undefined, {
    userIds: userIds.join(",")
  });

  if (signalRHub?.state === HubConnectionState.Connected
    && userIds.length > 0) {
    void signalRHub?.send("SendAnalystSharedBucket", workQueueId, JSON.stringify(userIds));
  }
};

const getViews = async (): Promise<View[]> => getList(`${URL}`);

const getView = async (workQueueId: string): Promise<View> => get(`${URL}/${workQueueId}`);

const getViewsByIds = async (workQueueIds: string[]): Promise<BasicView[]> => {
  let url = `${URL}/shared?`;
  workQueueIds.forEach((id, index) => {
    url += `workQueueIds=${id}${index < workQueueIds.length - 1 ? "&" : ""}`;
  });

  const response: BasicView[] = await get(url);
  return response;
};

export {
  addView, acceptView, removeView, undoRemoveView,
  getViews, getView, updateView, shareView, getViewsByIds
};
