import type { GroupBase } from "react-select";
import { type Response } from "react-select-async-paginate";
import { type FieldOption } from "@models/field-option.model";
import { get, post } from "@utils/api.util";
import { type ShareValueType } from "@models/share-value-type.model";

interface ApiResponse {
  results: FieldOption[];
  total: number;
}

interface ApiRequest {
  url: string;
  search?: string;
  page?: number;
  notPaginated?: boolean;
}

interface GetUsersForShareApiRequest extends ApiRequest {
  share: ShareValueType[]
  serviceLineId: string;
}

interface GetServiceLinesForShareApiRequest {
  url: string;
  search?: string;
}

interface GetViewFilterFieldLabelsApiRequest {
  labelsViewModel: Record<string, string[]>;
}

const managementApiUrl = import.meta.env.VITE_MANAGEMENT_API_URL;
const workQueueApiUrl = import.meta.env.VITE_WORK_QUEUE_API_URL;
const take = Number(import.meta.env.VITE_DEFAULT_PAGINATED_SELECT_TAKE);

const getOptions = (response: ApiResponse, notPaginated: boolean, search: string): FieldOption[] => {
  const options = response?.results ?? response;
  return notPaginated ? options.filter(x => x.label.toLowerCase().trim().includes(search.toLowerCase().trim())) : options;
};

const getFieldValues = async ({
  url,
  search = "",
  page = 0,
  notPaginated = true
}: ApiRequest): Promise<Response<FieldOption, GroupBase<FieldOption>, { page: number }>> => {
  const endpoint = notPaginated
    ? `${managementApiUrl}/${url}/summary`
    : `${managementApiUrl}/${url}/${take}/${page}/summary?keyword=${search}`;
  const response = await get<ApiResponse>(endpoint);
  const options = getOptions(response, notPaginated, search);
  const hasMore = (page + 1) * take < response?.total;

  return {
    options,
    hasMore,
    additional: {
      page: page + 1
    }
  };
};

const getUsersForShare = async ({ url, share, search = "", page = 0, serviceLineId }: GetUsersForShareApiRequest)
  : Promise<Response<FieldOption, GroupBase<FieldOption>, { page: number }>> => {
  const endpoint = `${workQueueApiUrl}/${url}/${take}/${page}/summary?keyword=${search}&serviceLineId=${serviceLineId}`;
  const excludeUsers = share.filter(s => s?.value?.length > 0).map(s => s.value);
  const response = await get<ApiResponse>(endpoint, { excludeUsers });
  const options = response?.results ?? response;
  const hasMore = (page + 1) * take < response?.total;

  return {
    options,
    hasMore,
    additional: {
      page: page + 1
    }
  };
};

const getUsers = async ({ url, search = "", page = 0 }: ApiRequest)
  : Promise<FieldOption[]> => {
  const endpoint = `${workQueueApiUrl}/${url}/${take}/${page}/summary?keyword=${search}`;
  const response = await get<ApiResponse>(endpoint);
  const options = response?.results ?? response;

  return options;
};

const getServiceLinesForShare = async ({ url, search = "" }: GetServiceLinesForShareApiRequest)
  : Promise<Response<FieldOption, GroupBase<FieldOption>, { page: number }>> => {
  const endpoint = `${workQueueApiUrl}/${url}?keyword=${search}`;
  const response = await get<ApiResponse>(endpoint);
  const options = response?.results ?? response;

  return {
    options,
    hasMore: false
  };
};

const getViewFilterFieldLabels = async ({ labelsViewModel }: GetViewFilterFieldLabelsApiRequest)
  : Promise<Record<string, FieldOption[]>> => {
  const endpoint = `${managementApiUrl}/viewFilters/viewLabels`;
  const response = await post<Record<string, FieldOption[]>, Record<string, string[]>>(endpoint, labelsViewModel);

  return response;
};

export {
  getUsers,
  getFieldValues,
  getUsersForShare,
  getServiceLinesForShare,
  getViewFilterFieldLabels
};
