import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@mui/material";
import { type ReactNode, useEffect, useState } from "react";
import { type AlertButton } from "@models/alert-button.model";

interface Props {
  show: boolean;
  title: string;
  message: ReactNode;
  buttons: AlertButton[];
  handleClose?: VoidFunction;
}

const Alert = ({ show, title, message, buttons, handleClose }: Props): JSX.Element => {
  const theme = useTheme();
  const [open, setOpen] = useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: theme.palette.secondary.dark,
          borderRadius: ".5rem"
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ color: "white" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="div" sx={{ color: "white" }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons.map((button, index) => (
          <Button key={index} onClick={button.onClick}>{button.text}</Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default Alert;

Alert.defaultProps = {
  handleClose: undefined
};
