import { Component, type ReactNode, type ErrorInfo } from "react";
import { datadogRum } from "@datadog/browser-rum";
import Error from "@pages/error/error.page";

interface Props {
  children: ReactNode;
  // eslint-disable-next-line react/require-default-props
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);

    // Send the error to DataDog.
    // TODO: Figure out how/if to use error source and tags.
    datadogRum.addError(error, {
      errorSource: "my_error_source",
      my_custom_tag: "my_custom_value"
    });
  }

  render(): ReactNode {
    const { hasError } = this.state;
    const { fallback, children } = this.props;
    if (hasError) {
      return fallback ?? <Error />;
    }

    return children;
  }
}

export default ErrorBoundary;
