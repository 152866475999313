import { type QueryFunction, useQuery, type QueryKey } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { type BaseStore } from "@models/base-store.model";

const useLoadStore = <TStore>(key: QueryKey, queryFn: QueryFunction, store: BaseStore<TStore>): void => {
  const { status, error, data } = useQuery({ queryKey: key, queryFn });
  const storeRef = useRef(store);

  useEffect(() => {
    storeRef.current.setIsLoading?.(status === "pending");
  }, [status]);

  useEffect(() => {
    storeRef.current.setError?.(error);
  }, [error]);

  useEffect(() => {
    storeRef.current.load?.((data ?? []) as TStore[]);
  }, [data]);
};

export default useLoadStore;
