import { Box, Grid, InputLabel, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { type SelectChangeEvent } from "@mui/material/Select";
import { useImmer } from "use-immer";
import { useTimeout } from "usehooks-ts";
import serviceLineStatsMock from "@mocks/service-line-stats.mock.json";
import { type ServiceLineStat } from "@models/service-line-stat.model";
import { BluePaper } from "@components/generic/generic.component";
import Svg from "@components/svg/svg.component";
import ServiceLineCardLoader from "./service-line-card-loader/service-line-card-loader.component";

const serviceLineStats = serviceLineStatsMock as ServiceLineStat[]; // TODO: Replace with real data
const arrowUp = "./assets/svg/arrow-up.svg";
const arrowDown = "./assets/svg/arrow-down.svg";

const ServiceLineCard = (): JSX.Element => {
  const [serviceLine, setServiceLine] = useImmer<string>("Aspirion");
  const [isLoading, setIsLoading] = useImmer(true);

  useTimeout(() => setIsLoading(false), 2000);

  const handleChangeServiceLine = (event: SelectChangeEvent): void => {
    setServiceLine(event.target.value);
  };

  const getServiceLineStat = (name: string): ServiceLineStat => {
    const data = serviceLineStats.find(item => item.name === name);
    return data!;
  };

  return (
    <BluePaper>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" }
        }}
        noValidate
        autoComplete="off"
      >
        <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Service Line</InputLabel>
          <Select
            labelId="service-line-filled-label"
            id="service-line-select-filled"
            value={serviceLine}
            onChange={handleChangeServiceLine}
          >
            {serviceLineStats.map((item, i) => (
              <MenuItem
                key={i}
                value={item.name}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        {isLoading ? (
          <ServiceLineCardLoader />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Svg img={getServiceLineStat(serviceLine).img} />
            </Grid>
            <Grid item xs={5}>
              <Box sx={{ mb: "2rem" }}>
                <Typography variant="caption">
                  Remit Amount
                </Typography>
                <Typography variant="h5">
                  ${getServiceLineStat(serviceLine).data.remitAmount.toLocaleString()}
                </Typography>
              </Box>
              <Box sx={{ mb: "0.5rem" }}>
                <Typography variant="caption">
                  Invoice Amount Month to Date
                </Typography>
                <Typography variant="h5">
                  ${getServiceLineStat(serviceLine).data.invoiceAmount.toLocaleString()}
                </Typography>
              </Box>
              <Box sx={{ mb: "0.5rem", display: "flex" }}>
                <Box>
                  <Typography variant="caption">
                    Last Month to Date
                  </Typography>
                  <Typography variant="body1">
                    ${getServiceLineStat(serviceLine).data.lastMonthToDate.toLocaleString()}
                  </Typography>
                </Box>
                <Box sx={{ ml: "2rem" }}>
                  <img
                    width={50}
                    src={getServiceLineStat(serviceLine).data.lastMonthToDate > 100000 ? arrowUp : arrowDown}
                    alt="arrow-svg"
                  />
                </Box>
              </Box>
              <Box sx={{ mb: "0.5rem" }}>
                <Typography variant="caption">
                  Month to Date Variance
                </Typography>
                <Typography variant="body1">
                  (-${getServiceLineStat(serviceLine).data.monthToDateVariance.toLocaleString()})
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </BluePaper>
  );
};

export default ServiceLineCard;
