import { styled } from "@mui/material";
import type React from "react";
import { LoadingBlock } from "@components/generic/generic.component";

const LoadingBlockPlaceholder = styled(LoadingBlock)(() => ({
  margin: "1rem 0 0 0"
}));

const LoadingContentPlaceholder: React.FC = () => (
  <LoadingBlockPlaceholder
    variant="rounded"
    height="calc(100vh - 8.25rem)"
    width="100%"
    animation="wave"
  />
);

export default LoadingContentPlaceholder;
