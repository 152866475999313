enum ServiceLine {
  MVA = "Motor Vehicle Accident",
  WC = "Workers' Compensation",
  PL = "Premises Liability",
  CD = "Coverage Discovery",
  VA = "Veterans Affairs",
  Tricare = "Tricare",
  OSM = "Out of state Medicaid",
  Denials = "Denials",
  AMB_WC = "Ambulance Billing WC",
  AMB_MVA_PL = "Ambulance Billing MVA/PL",
}

export default ServiceLine;
