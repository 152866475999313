import { Skeleton, Card, CardHeader, CardContent } from "@mui/material";

const ScrollSeekPlaceholder = (): JSX.Element => (
  <Card sx={{ margin: "0 2rem 1rem 1rem" }}>
    <CardHeader
      avatar={<Skeleton animation={false} variant="circular" width={40} height={40} data-testid="avatar-skeleton" />}
      title={(
        <Skeleton
          animation={false}
          height={20}
          style={{ marginBottom: 6 }}
          data-testid="title-skeleton"
        />
      )}
      subheader={<Skeleton animation={false} height={20} data-testid="subheader-skeleton" />}
    />
    <CardContent>
      <Skeleton animation={false} data-testid="content-skeleton" />
      <Skeleton animation={false} data-testid="content-skeleton" />
      <Skeleton animation={false} data-testid="content-skeleton" />
    </CardContent>
  </Card>
);

export default ScrollSeekPlaceholder;
