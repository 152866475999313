import { Grid, useTheme } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { BlueContentPaper } from "@components/generic/generic.component";
import AccountPlaceholder from "@assets/images/account-placeholder.webp";

const gridStyles = {
  height: "100%"
};

const CardContentContainer = ({ incidentId, workedOn }: { incidentId: string, workedOn: boolean }): JSX.Element => {
  const theme = useTheme();

  if (incidentId) {
    return (
      <AnimatePresence>
        <BlueContentPaper
          component={motion.div}
          initial={{ x: "100%", opacity: 0, scale: 1 }}
          animate={{ x: 0, opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          key="box"
          sx={{
            padding: "1rem",
            borderColor: theme.palette.secondary.light,
            borderWidth: `${workedOn ? "0.25rem 0.25rem 0.25rem 0" : "0"}`
          }}
        >
          <Grid container spacing={1} sx={{ ...gridStyles }}>
            <Grid item xs={12}>
              <iframe
                src={`${import.meta.env.VITE_LEGACY_COMPASS_URL}/incident/${incidentId}`}
                width="100%"
                height="100%"
                title="card-content-iframe"
                allow="clipboard-read; clipboard-write; display-capture"
              />
            </Grid>
          </Grid>
        </BlueContentPaper>
      </AnimatePresence>
    );
  }

  return (
    <AnimatePresence>
      <BlueContentPaper
        component={motion.div}
        initial={{ x: "100%", opacity: 0, scale: 1 }}
        animate={{ x: 0, opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
        key="box"
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ ...gridStyles, width: "100%", maxWidth: "100%", maxHeight: "100%" }}
        >
          <Grid item xs={3} sx={{ ...gridStyles, width: "100%", maxWidth: "100%", maxHeight: "100%" }}>
            <img
              src={AccountPlaceholder}
              alt="Account Placeholder Team"
              style={{ ...gridStyles, width: "100%", maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
            />
          </Grid>
        </Grid>
      </BlueContentPaper>
    </AnimatePresence>
  );
};

export default CardContentContainer;
