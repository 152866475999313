import axios, { type AxiosResponse } from "axios";
import authorizeInterceptor from "@interceptors/authorize.interceptor";

const instance = axios.create();
authorizeInterceptor(instance);

const getList = async <T>(url: string, headers?: object): Promise<T[]> => {
  const response = await instance.get<T[]>(url, { headers });
  return response.data;
};

const get = async <T>(url: string, headers?: object): Promise<T> => {
  const response = await instance.get<T>(url, { headers });
  return response.data;
};

const post = async <TResponse, TData>(url: string, data: TData, headers?: object): Promise<TResponse> => {
  const response = await instance.post<TResponse>(url, data, { headers });
  return response.data;
};

const postRawResponse = async <TResponse, TData>(url: string, data: TData, headers?: object): Promise<AxiosResponse> => {
  const response = await instance.post<TResponse>(url, data, { headers });
  return response;
};

const update = async <TResponse, TData>(url: string, data: TData, headers?: object): Promise<TResponse> => {
  const response = await instance.put<TResponse>(url, data, { headers });
  return response.data;
};

const patch = async <T>(url: string, data?: T, headers?: object): Promise<void> => {
  await instance.patch<T[]>(url, data, { headers });
};

const remove = async (url: string, headers?: object): Promise<void> => {
  await instance.delete(url, { headers });
};

export { getList, get, post, update, patch, remove, postRawResponse };
