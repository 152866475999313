import { type SyntheticEvent } from "react";
import { Box, Button, Divider, Grid } from "@mui/material";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import { type ViewContentProps } from "@models/view-content-props.model";
import SaveButton from "@components/save-button/save-button.component";
import { BlueContentPaper } from "@components/generic/generic.component";
import { type ShareValueType } from "@models/share-value-type.model";
import { type FieldOption } from "@models/field-option.model";
import ViewContentShare from "./view-content-share/view-content-share.component";
import ViewContentFilter from "./view-content-filter/view-content-filter.component";

const ViewContent = ({
  onSubmit, onCancel, filter, handleChangeFilter,
  share, setShare, handleSetTouched, isEdit, isViewCreator, showErrors, disableSaveButton
}: ViewContentProps): JSX.Element => {
  const handleOnSubmit = (isCopy: SyntheticEvent | boolean): void => onSubmit(Boolean(isCopy));

  const handleShareMore = (): void => {
    setShare(draft => {
      draft.push({
        key: uuidv4(), value: "", label: "", readonly: false
      });
    });

    handleSetTouched();
  };

  const handleSelectServiceLine = (key: string, serviceLine: FieldOption): void => {
    setShare(draft => {
      const index = draft.findIndex(s => s.key === key);
      if (index !== -1) {
        const temp = draft[index];
        if (!temp) {
          return;
        }

        temp.serviceLine = serviceLine;
        temp.value = "";
        temp.label = "";
      }
    });

    handleSetTouched();
  };

  const handleSelectShare = (item: ShareValueType, newValue: ShareValueType): void => {
    setShare(draft => {
      const index = draft.findIndex(s => s.key === item.key);
      if (index !== -1) {
        const temp = draft[index];
        if (!temp) {
          return;
        }

        temp.value = newValue?.value;
        temp.label = newValue?.label;
      }
    });

    handleSetTouched();
  };

  const handleRemoveShare = (item: ShareValueType): void => {
    setShare(draft => {
      draft.splice(draft.findIndex(s => s.key === item.key), 1);
    });

    handleSetTouched();
  };

  return (
    <BlueContentPaper
      component={motion.div}
      initial={{ x: "100%", opacity: 0, scale: 1 }}
      animate={{ x: 0, opacity: 1, scale: 1 }}
      transition={{ duration: 1 }}
      key="box"
    >
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="end" pb={2}>
              <Box display="inline-block">
                <Button
                  variant="outlined"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Box>
              <Box ml={2} display="inline-block">
                <SaveButton
                  variant="contained"
                  onClick={handleOnSubmit}
                  isEdit={isEdit}
                  isViewCreator={isViewCreator}
                  disabled={disableSaveButton}
                >
                  {isViewCreator ? "Save" : "Save as Copy"}
                </SaveButton>
              </Box>
            </Box>
            <Divider sx={{ backgroundColor: "primary.main" }} />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container spacing={{ xs: 0, md: 1 }} columns={48}>
          <Grid item md={48} xl={30}>
            <ViewContentFilter
              onChange={handleChangeFilter}
              filter={filter}
              showErrors={showErrors}
            />
          </Grid>
          <Grid item md={48} xl={18}>
            <ViewContentShare
              share={share}
              handleShareMore={handleShareMore}
              handleSelectShare={handleSelectShare}
              handleSelectServiceLine={handleSelectServiceLine}
              handleRemoveShare={handleRemoveShare}
            />
          </Grid>
        </Grid>
      </Box>
    </BlueContentPaper>
  );
};

export default ViewContent;
