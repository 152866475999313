import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, IconButton, Paper, styled, Typography } from "@mui/material";
import { motion, type AnimationProps, useAnimation } from "framer-motion";
import { useEffect, type MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import { type ViewAction } from "@custom-types/view-action.type";
import { type LeftPanelCardProps } from "@models/left-panel-card-props.model";

const Wrapper = styled(motion.div)<AnimationProps>(() => ({
  position: "relative",
  overflow: "hidden",
  height: "6rem",
  width: "21.4rem"
}));

const ActiveBluePaper = styled(Paper)<AnimationProps>(({ theme }) => ({
  width: "21.4rem",
  backgroundColor: theme.palette.secondary.light,
  borderColor: theme.palette.secondary.light,
  borderStyle: "solid",
  borderWidth: "0.0625rem",
  display: "flex",
  alignItems: "center",
  padding: "0.75rem 1rem",
  margin: "0.625rem 0 0.625rem 0",
  cursor: "pointer",
  borderRadius: "0.625rem",
  minHeight: "5rem",
  justifyContent: "space-between",
  "&:hover": {
    backgroundColor: theme.palette.primary.main
  },
  left: 0,
  position: "absolute"
}));

const InactiveBluePaper = styled(Paper)<AnimationProps>(({ theme }) => ({
  right: "-100%",
  width: "21.4rem",
  position: "absolute",
  backgroundColor: theme.palette.primary.dark,
  borderColor: theme.palette.primary.dark,
  borderStyle: "solid",
  borderWidth: "0.0625rem",
  display: "flex",
  alignItems: "center",
  padding: "0.75rem 1rem",
  margin: "0.625rem 0 0.625rem 0",
  cursor: "pointer",
  borderRadius: "0.625rem",
  minHeight: "5rem",
  WebkitAlignItems: "flex-start",
  justifyContent: "space-between"
}));

const LeftPanelCard = ({ view, onChangeStatus }: LeftPanelCardProps): JSX.Element => {
  const muiTheme = useTheme();
  const navigate = useNavigate();
  const cardControls = useAnimation();
  const undoDeleteControls = useAnimation();
  const sharedServiceLineControls = useAnimation();

  const handleViewClick = (event: MouseEvent, id: string): void => {
    event.stopPropagation();
    navigate(`/view/${id}`);
  };

  const changeStatus = (event: MouseEvent, id: string, action: ViewAction): void => {
    event.stopPropagation();

    if (action === "remove") {
      void cardControls.start({
        left: "-100%",
        transition: { duration: 0.5 }
      });
      void undoDeleteControls.start({
        right: 0,
        transition: { duration: 0.5 }
      });
    }

    if (action === "undo-remove") {
      void undoDeleteControls.start({
        right: "-100%",
        transition: { duration: 0.5 }
      });
      void cardControls.start({
        left: 0,
        transition: { duration: 0.5 }
      });
    }

    if (action === "accept") {
      void sharedServiceLineControls.start({
        right: "-100%",
        transition: { duration: 0.5 }
      });
      void cardControls.start({
        left: 0,
        transition: { duration: 0.5 }
      });
    }

    onChangeStatus(id, action);
  };

  useEffect(() => {
    if (view.type === "Shared" && !view.accepted && view.status !== "removed") {
      void cardControls.start({
        left: "-100%",
        opacity: 1,
        scale: 1,
        transition: { duration: 0.5 }
      });
      void sharedServiceLineControls.start({
        right: 0,
        transition: { duration: 0.5 }
      });
    } else {
      void cardControls.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.5 }
      });
    }
  }, []);

  const isRemoved = view?.status === "removed";

  const isCreated = view.type === "Created" && !isRemoved;
  const isServiceLine = view?.type === "ServiceLine";

  const displayedDate = isCreated ? (view.created?.editedOn ?? view.created.createdOn) : view.sharedOn;
  const formattedDate = dayjs(displayedDate).format("MM/DD/YYYY");
  const name = view.type === "Shared" ? view.created?.userName ?? "" : "You";
  const viewDescription = `${view.created?.editedOn ? "Edited" : "Created"} by ${name} - ${formattedDate}`;

  return (
    <Wrapper>
      <ActiveBluePaper
        onClick={e => handleViewClick(e, view.id)}
        component={motion.div}
        initial={{ opacity: 0, scale: 1 }}
        animate={cardControls}
      >
        <Box>
          <Typography variant="body2" color="textSecondary">
            {view.name}
          </Typography>
          {
            view.name && !isServiceLine && (
              <Typography variant="caption" sx={{ marginTop: "0.3125rem" }}>
                {viewDescription}
              </Typography>
            )
          }
        </Box>
        {!isServiceLine && (
          <IconButton
            sx={{ padding: 0, color: "primary.contrastText" }}
            size="small"
            aria-label="show more"
            aria-haspopup="true"
            onClick={e => changeStatus(e, view.id, "remove")}
          >
            <HighlightOffIcon data-testid="remove" />
          </IconButton>
        )}
      </ActiveBluePaper>

      <InactiveBluePaper
        component={motion.div}
        animate={undoDeleteControls}
      >
        <Box justifyContent="space-between" display="flex">
          <Box mr={1}>
            <WarningAmberIcon fontSize="small" style={{ color: muiTheme.palette.warning.main }} />
          </Box>
          <Box>
            <Typography variant="body2" style={{ color: muiTheme.palette.warning.main }}>
              Delete
            </Typography>
            <Typography
              variant="caption"
              sx={{ marginTop: "0.3125rem", color: muiTheme.palette.warning.main }}
            >
              {`${view.name} will delete on refresh`}
            </Typography>
          </Box>
        </Box>
        <IconButton
          size="small"
          aria-label="show more"
          aria-haspopup="true"
          onClick={e => changeStatus(e, view.id, "undo-remove")}
          sx={{ color: "primary.contrastText" }}
        >
          <Typography variant="body2" color="primary.contrastText">
            UNDO
          </Typography>
        </IconButton>
      </InactiveBluePaper>

      <InactiveBluePaper
        component={motion.div}
        animate={sharedServiceLineControls}
      >
        <Box justifyContent="space-between" display="flex">
          <Box mr={1}>
            <WarningAmberIcon fontSize="small" sx={{ color: muiTheme.palette.info.main }} />
          </Box>
          <Box>
            <Typography
              variant="body2"
              color={muiTheme.palette.info.main}
            >
              {view.name}
            </Typography>
            <Typography
              variant="caption"
              color={muiTheme.palette.info.main}
              sx={{ marginTop: "0.3125rem" }}
            >
              {view.created.userName} shared this with you
            </Typography>
          </Box>
        </Box>
        <IconButton
          size="small"
          aria-label="show more"
          aria-haspopup="true"
          onClick={e => changeStatus(e, view.id, "accept")}
          sx={{ color: "primary.contrastText" }}
        >
          <Typography
            variant="body2"
            color="primary.contrastText"
            sx={{ "&:hover": { color: "info.main" } }}
          >
            Accept
          </Typography>
        </IconButton>
      </InactiveBluePaper>
    </Wrapper>
  );
};

export default LeftPanelCard;
