const alphabetDropdownOptions = [
  { label: "A", name: "A" },
  { label: "B", name: "B" },
  { label: "C", name: "C" },
  { label: "D", name: "D" },
  { label: "E", name: "E" },
  { label: "F", name: "F" },
  { label: "G", name: "G" },
  { label: "H", name: "H" },
  { label: "I", name: "I" },
  { label: "J", name: "J" },
  { label: "K", name: "K" },
  { label: "L", name: "L" },
  { label: "M", name: "M" },
  { label: "N", name: "N" },
  { label: "O", name: "O" },
  { label: "P", name: "P" },
  { label: "Q", name: "Q" },
  { label: "R", name: "R" },
  { label: "S", name: "S" },
  { label: "T", name: "T" },
  { label: "U", name: "U" },
  { label: "V", name: "V" },
  { label: "W", name: "W" },
  { label: "X", name: "X" },
  { label: "Y", name: "Y" },
  { label: "Z", name: "Z" }
];

export default alphabetDropdownOptions;
