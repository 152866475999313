import { datadogRum } from "@datadog/browser-rum";
import version from "../version";

const initDataDog = (): void => {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATA_DOG_APP_ID,
    clientToken: import.meta.env.VITE_DATA_DOG_CLIENT_ID,
    site: "us3.datadoghq.com",
    service: "compass-2",
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input"
  });
  datadogRum.startSessionReplayRecording();
};

export default initDataDog;
