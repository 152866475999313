import { PublicClientApplication } from "@azure/msal-browser";

const config = {
  auth: {
    clientId: import.meta.env.VITE_CLIENT_ID,
    authority: import.meta.env.VITE_CLIENT_AUTHORITY,
    redirectUri: `${import.meta.env.VITE_BASE_APP_URL}/post_login.html`,
    postLogoutRedirectUri: `${import.meta.env.VITE_BASE_APP_URL}/login`
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
    secureCookies: true
  }
};

const userAgentApplication = new PublicClientApplication(config);
void userAgentApplication.initialize();

const graphScopes = [import.meta.env.VITE_GRAPH_SCOPES];

export { userAgentApplication, graphScopes };
