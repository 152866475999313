/* eslint-disable max-len */
import { type RuleGroupType } from "react-querybuilder";
import { type ViewSearchResult, type ViewFullSearchResult } from "@models/view-search-result.model";
import { type Sort } from "@models/sort.model";
import { get, patch, post } from "@utils/api.util";
import { convertFilterValues } from "@logic/view.logic";

const URL = `${import.meta.env.VITE_WORK_QUEUE_API_URL}/search`;

const getSearchResultsById = async (workQueueId: string, take: number, skip: number, sort: Sort):
  Promise<ViewSearchResult[]> => {
  const queryString = [
    `take=${take}`,
    `skip=${skip}`,
    `includeTotalCount=${(skip === 0).toString()}`,
    `sortColumn=${sort.sortColumn}`,
    `sortDescending=${sort.isDescendent.toString()}`]
    .join("&");
  return get(`${URL}/${workQueueId}?${queryString}`);
};

const getFullSearchResultsById = async (workQueueId: string, take: number, skip: number, sort: Sort, excludeWorkedToday: boolean):
  Promise<ViewFullSearchResult> => {
  const queryString = [
    `take=${take}`,
    `skip=${skip}`,
    `includeTotalCount=${(skip === 0).toString()}`,
    `sortColumn=${sort.sortColumn}`,
    `sortDescending=${sort.isDescendent.toString()}`,
    `excludeWorkedToday=${String(excludeWorkedToday)}`]
    .join("&");
  return get(`${URL}/${workQueueId}?${queryString}`);
};

const getWorkQueueNotWorkedTodayNextAccount = async (workQueueId: string, currentAccountId: string, currentAccountIndex: number, sort: Sort):
  Promise<ViewFullSearchResult> => {
  const queryString = [
    `currentAccountIndex=${currentAccountIndex}`,
    `sortColumn=${sort.sortColumn}`,
    `sortDescending=${sort.isDescendent.toString()}`]
    .join("&");
  return get(`${URL}/${workQueueId}/${currentAccountId}?${queryString}`);
};

const getSearchResultsByModel = async (rules: RuleGroupType | null | undefined, take: number, skip: number, sort: Sort):
  Promise<ViewSearchResult[]> => {
  const body = {
    take,
    skip,
    includeTotalCount: skip === 0,
    sort,
    filter: convertFilterValues(rules)
  };
  return post(URL, body);
};

const deleteBucketById = async (workQueueId: string):
  Promise<void> => {
  const url = `${import.meta.env.VITE_WORK_QUEUE_API_URL}/queue-work/${workQueueId}/remove`;
  return patch(url);
};

const undeleteBucketById = async (workQueueId: string):
  Promise<void> => {
  const url = `${import.meta.env.VITE_WORK_QUEUE_API_URL}/queue-work/${workQueueId}/undo-remove`;
  return patch(url);
};

export {
  getSearchResultsById,
  getSearchResultsByModel,
  getFullSearchResultsById,
  getWorkQueueNotWorkedTodayNextAccount,
  deleteBucketById,
  undeleteBucketById
};
