import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { type BaseStore } from "@models/base-store.model";
import { type BasicView } from "@models/basic-view.model";
import { updateListItem, updateListItemProperty } from "@utils/list.util";
import { getError } from "@utils/error.util";

interface Store extends BaseStore<BasicView> {
  views: BasicView[];
  addViews: (views: BasicView[]) => void;
}

const useBasicViewStore = create<Store>()(
  devtools(
    (set): Store => ({
      views: [],

      setIsLoading: isLoading => {
        set(state => ({ ...state, isLoading }), false, "setIsLoading");
      },

      setError: err => {
        const error = getError(err);
        set(state => ({ ...state, error }), false, "setError");
      },

      load: views => {
        set(state => ({ ...state, views }), false, "load");
      },

      add: view => {
        set(state => ({ ...state, views: [...state.views, view] }), false, "add");
      },

      addViews: views => {
        const getUniqueViews = (state: Store): BasicView[] => {
          const existingViewIds = new Set(state.views.map(v => v.id));
          const uniqueViews = views.filter(view => !existingViewIds.has(view.id));
          return uniqueViews;
        };

        set(state => ({ ...state, views: [...getUniqueViews(state), ...state.views] }), false, "addViews");
      },

      patch: patch => {
        set(state => ({ ...state, views: updateListItemProperty(state.views, patch.id!, "status", patch.value) }), false, "patch");
      },

      update: view => {
        set(state => ({ ...state, views: updateListItem(state.views, view.id, view) }), false, "update");
      }
    }),
    { name: "BasicView" }
  )
);

export default useBasicViewStore;
