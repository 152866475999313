const formatSSN = (value: string) : string => {
  if (!value) {
    return "";
  }

  if (value.length <= 3) {
    return value;
  }

  if (value.length <= 5) {
    return `${value.substring(0, 3)}-${value.substring(3, value.length)}`;
  }

  if (value.length > 5 && value.length < 10) {
    return `${value.substring(0, 3)}-${value.substring(3, 5)}-${value.substring(5, value.length)}`;
  }

  return value;
};

const isPartialSSN = (inputString : string) : boolean => {
  const ssnPattern = /^\d{0,3}-?(?:\d{2}-?)?\d{0,4}$/;
  return ssnPattern.test(inputString);
};

export { formatSSN, isPartialSSN };
