import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useState, type MouseEvent } from "react";
import { useMsal } from "@azure/msal-react";
import useSessionStore from "@store/session.store";
import { StyledMenu } from "@components/generic/generic.component";

const AccountInfo = (): JSX.Element => {
  const sessionStore = useSessionStore();
  const { session } = sessionStore;
  const { user } = session;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const open = !!anchorEl;
  const id = open ? "account-info" : undefined;

  const { instance } = useMsal();

  const initializeSignOut = async (): Promise<void> => {
    await instance.logoutRedirect();
  };

  const handleLegacyLink = (url: string): void => {
    let compassUrl = "";
    switch (url) {
      case "https://helix-qa.aspirion.com":
        compassUrl = "https://compass-qa.aspirion.com";
        break;
      case "https://helix-uat.aspirion.com":
        compassUrl = "https://compass-uat.aspirion.com";
        break;
      case "https://helix.aspirion.com":
        compassUrl = "https://compass.aspirion.com";
        break;
      default:
        compassUrl = "https://compass-uat.aspirion.com";
    }

    window.open(compassUrl, "_self");
  };

  return (
    <>
      <Button
        color="inherit"
        size="small"
        aria-describedby={id}
        onClick={(event: MouseEvent<HTMLButtonElement>) => handleClick(event)}
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
      >
        USER
      </Button>

      <StyledMenu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuItem>
          <Typography
            variant="body2"
            component="span"
            color={theme => theme.palette.grey[100]}
          >
            {user?.name}
          </Typography>
        </MenuItem>

        <MenuItem onClick={() => handleLegacyLink(import.meta.env.VITE_BASE_APP_URL)}>
          <Typography
            variant="body2"
            component="span"
            color={theme => theme.palette.grey[100]}
          >
            Go to legacy Compass
          </Typography>
        </MenuItem>
        <MenuItem onClick={initializeSignOut}>
          <Typography
            variant="body2"
            component="span"
            sx={{ color: "red" }}
          >
            Sign Out
          </Typography>
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default AccountInfo;
