import Alert, { type AlertColor } from "@mui/material/Alert";
import { useTheme } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

const MotionAlert = motion(Alert);

interface AnimatedAlertProps {
  show: boolean;
  message: string;
  severity: AlertColor;
  onClose: () => void;
}

const AnimatedAlert = ({ show, message, severity, onClose }: AnimatedAlertProps): JSX.Element => {
  const theme = useTheme();

  return (
    <AnimatePresence>
      {
        show && message?.length > 0 && (
          <MotionAlert
            variant="filled"
            severity={severity}
            onClose={onClose}
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            exit={{ width: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              whiteSpace: "nowrap",
              color: theme.palette.primary.contrastText,
              backgroundColor: severity === "info"
                ? theme.palette.primary.main
                : theme.palette.warning.dark
            }}
          >
            <div style={{ overflow: "hidden" }}>
              {message}
            </div>
          </MotionAlert>
        )
      }
    </AnimatePresence>
  );
};

export default AnimatedAlert;
