import type React from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Skeleton,
  Chip
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Select, { type SelectChangeEvent } from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { type ChangeEvent, useCallback, useState, useEffect, type SyntheticEvent, useRef } from "react";
import useSearchStore from "@store/search.store";
import { type RuleGroupType } from "react-querybuilder";
import defaultFilter from "@data/query-builder-default.data";
import ViewContentFilter from "@components/view-content/view-content-filter/view-content-filter.component";
import useStoreErrorsHook from "@hooks/store-errors.hook";
import { isViewFormValid, preProcessFilterForEdit } from "@logic/view.logic";
import { type Shared } from "@models/shared.model";
import { getCreateViewModel, getUpdateViewModel } from "@utils/view.util";
import useAddStore from "@hooks/add-store.hook";
import { addView, shareView, updateView } from "@services/view.service";
import startViewTransition from "@utils/view-transition.util";
import useViewStore from "@store/view.store";
import defaultSort from "@data/sort-default.data";
import SortType from "@enums/sort-type.enum";
import sortOptions from "@components/view-sort/sort-options";
import { type SortOptionsType } from "@models/sort-options-type.model";
import { type Sort } from "@models/sort.model";
import ConfirmationDialog from "@components/confirmation-dialog/confirm-dialog.component";
import { getUsers } from "@services/field.service";
import { type FieldOption } from "@models/field-option.model";
import { getSearchResultsByModel } from "@services/search.service";
import { type ViewSearchResult } from "@models/view-search-result.model";
import useLoadInfiniteStore from "@hooks/load-infinite-store.hook";
import { isQueryValid } from "@logic/query-builder.logic";
import { toast } from "react-toastify";
import useUpdateStore from "@hooks/update-store.hook";
import { type HubConnection } from "@microsoft/signalr";
import { type View } from "@models/view.model";
import { type BasicView } from "@models/basic-view.model";

interface BucketDrawerFormProps {
  open: boolean;
  title: string;
  onClose: () => void;
  handleSubmit: () => void;
  onPreview: (data: ViewSearchResult[], totalCount: number, totalBillCount: number, name: string) => void;
  signalRHub: HubConnection | undefined;
  currentView: View | undefined;
  views: BasicView[];
  isViewCreator: boolean;
  isEdit: boolean;
  fetchAndSetDetailView: (bucketId: string)=> void;
}

const sorts = Object.keys(sortOptions).flatMap(key => [
  {
    name: sortOptions[key as keyof SortOptionsType].name,
    label: `${sortOptions[key as keyof SortOptionsType].label} ${sortOptions[key as keyof SortOptionsType].ascOption}`,
    isDescendent: false
  },
  {
    name: sortOptions[key as keyof SortOptionsType].name,
    label: `${sortOptions[key as keyof SortOptionsType].label} ${sortOptions[key as keyof SortOptionsType].descOption}`,
    isDescendent: true
  }
]);

// let customTimeout:any;
const BucketDrawerForm: React.FC<BucketDrawerFormProps> = ({
  open, title, onClose, onPreview, handleSubmit,
  signalRHub = undefined, isViewCreator, currentView, views, isEdit, fetchAndSetDetailView }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [viewName, setViewName] = useState("");
  const [sort, setSort] = useState<Sort>(defaultSort);
  const [moreOptions] = useState(Boolean(true));
  const [showErrors, setShowErrors] = useState(false);
  const { errors, clearErrors } = useStoreErrorsHook();
  const [viewNameBEErrors, setViewNameBEErrors] = useState<string[]>([]);
  const [filter, setFilter] = useState<RuleGroupType>(defaultFilter);
  const [touched, setTouched] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [sharedUsers, setSharedUsers] = useState<FieldOption[]>([]);
  const [usersForShare, setUsersForShare] = useState<FieldOption[]>([]);
  const [recipients, setRecipients] = useState<FieldOption[]>([]);
  const [copy, setCopy] = useState<boolean>(false);
  const [localIsLoading, setLocalIsLoading] = useState<boolean>(false);
  const [usersForShareEErrors, setUsersForShareEErrors] = useState<string[]>([]);
  const [searchKey, setSearchKey] = useState<string>("");
  // disable buttons after once clicking.
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);
  const [viewCreatedByCurrentUser, setViewCreatedByCurrentUser] = useState<boolean>(false);

  const getShowDrawerInitialValue = (): boolean => {
    if (sessionStorage.getItem("showDrawer") === null) {
      return true;
    }

    return sessionStorage.getItem("showDrawer") === "true";
  };
  const [showDrawer, setShowDrawer] = useState<boolean>(() => getShowDrawerInitialValue());

  const shareUsersRef = useRef<HTMLInputElement>(null);

  const sessionStorageView = useCallback(() => {
    if (sessionStorage.getItem("previewView")) {
      const previewView: any = JSON.parse(sessionStorage.getItem("previewView") ?? "");
      return previewView;
    }

    return null;
  }, [sessionStorage.getItem("previewView")]);

  useEffect(() => {
    if (sessionStorage.getItem("previewView")) {
      const previewView: any = JSON.parse(sessionStorage.getItem("previewView") ?? "");
      setViewCreatedByCurrentUser(previewView.isViewCreator ?? isViewCreator);
    } else {
      setViewCreatedByCurrentUser(isViewCreator);
    }
  }, [isViewCreator]);

  const viewStore = useViewStore();
  const { data, totalCount, totalBillCount, ...searchStore } = useSearchStore();

  useEffect(() => {
    if (sessionStorage.getItem("previewView")) {
      setTimeout(() => { setShowDrawer(true); }, 1500);

      const previewView: any = JSON.parse(sessionStorage.getItem("previewView") ?? "");
      setViewName(previewView?.name ?? "");
      setSort(previewView?.sort ?? defaultSort);
      setFilter(previewView?.filter ?? defaultFilter);
      setUsersForShare(previewView?.usersForShare ?? []);
      setCopy(previewView?.isCopy ?? false);
      setSharedUsers(previewView?.sharedUsers ?? []);
      setRecipients(previewView?.recipients ?? []);
      setIsCollapsed(true);
      setTouched(true);
    }

    const openOnce = sessionStorage.getItem("openDrawerOnce");
    if (openOnce === "true") {
      setIsCollapsed(false);
      setTimeout(() => {
        sessionStorage.removeItem("openDrawerOnce");
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (!sessionStorage.getItem("previewView")) {
      return;
    }

    // handlePreview();
    const previewView: any = JSON.parse(sessionStorage.getItem("previewView") ?? "");

    if (previewView && filter !== defaultFilter) {
      handleSetTouched();
      searchStore.reset?.();
    }
  }, [filter, viewName]);

  useEffect(() => {
    if (isCollapsed) {
      sessionStorage.setItem("showDrawer", "false");
    } else {
      sessionStorage.setItem("showDrawer", "true");
    }
  }, [isCollapsed]);

  const resetErrors = (): void => {
    setShowErrors(false);
    setViewNameBEErrors([]);
    setUsersForShareEErrors([]);
    clearErrors();
  };

  const handleChangeSort = (event: SelectChangeEvent): void => {
    searchStore.reset!();
    const sortColumnValue = event.target.value.split("_")?.[0];
    const sortColumn: SortType = SortType[sortColumnValue as keyof typeof SortType];
    const isDescendentValue = event.target.value.split("_")?.[1];
    const isDescendent: boolean = isDescendentValue?.toLowerCase() === "true";
    const sortObj: Sort = {
      sortColumn,
      isDescendent
    };

    setSort(sortObj);
  };

  const handleSetTouched = useCallback((): void => {
    if (!touched) {
      setTouched(true);
    }
  }, [touched, setTouched]);

  const handleChangeFilter = (newFilter: RuleGroupType): void => {
    setFilter(newFilter);
    handleSetTouched();

    // Edge case for create/edit filter
    // Clear the loaded accounts for all the filter changes
    // If the filter is valid, the accounts will be loaded
    // This will prevent confusion for loaded results without a valid filter
    searchStore.reset?.();
  };

  const handleChangeViewName = (e: ChangeEvent<HTMLInputElement>): void => {
    setViewName(e.target.value);
    handleSetTouched();
  };

  const resetViewFieldsToDefaultValue = (): void => {
    resetErrors();
    setViewName("");
    setSort(defaultSort);
    setFilter(defaultFilter);
    setUsersForShare([]);
  };

  const handleViewAdded = async (): Promise<void> => {
    setTouched(false);
    handleSubmit();
    toast.success(copy ? "Bucket copied." : "Bucket created.", { theme: "colored", autoClose: false });

    resetViewFieldsToDefaultValue();
    setCopy(false);
    setIsDisableButton(false);
  };

  const handleViewUpdated = async (): Promise<void> => {
    setTouched(false);
    handleSubmit();
    toast.success("Bucket updated.", { theme: "colored", autoClose: false });

    resetViewFieldsToDefaultValue();
    setCopy(false);
    setIsDisableButton(false);
  };

  const addViewFn = useAddStore(
    ["views"],
    async view => {
      await addView(view, signalRHub);
    },
    viewStore,
    () => startViewTransition(async () => {
      await handleViewAdded();
    })
  );

  const updateViewFn = useUpdateStore(
    ["views"],
    async view => (viewCreatedByCurrentUser ? updateView(view, signalRHub)
      : shareView(view?.id, view.shared?.map(el => el.userId) ?? [], signalRHub)),
    viewStore,
    () => startViewTransition(async () => {
      await handleViewUpdated();
    })
  );

  const getSingleSearchResults = useCallback(async ({ pageParam }: { pageParam: unknown }): Promise<ViewSearchResult[]> => {
    const take = 25;
    let skip = 0;
    if (pageParam && !Number.isNaN(pageParam)) {
      skip = pageParam as number * 10;
    }

    if (filter !== null && filter !== undefined && isQueryValid(filter)) {
      searchStore.reset!();

      if (sessionStorage.getItem("previewView")) {
        const promi = getSearchResultsByModel(filter, take, skip, sort);
        return promi.then(res => {
          const returnedData: any = res;
          onPreview(returnedData.data, returnedData.totalCount, returnedData.totalBillCount, viewName);
          return res;
        });
      }

      return getSearchResultsByModel(filter, take, skip, sort);
    }

    return Promise.resolve([]);
  }, [sort, filter, searchStore]);

  useLoadInfiniteStore(["search", "", sort, filter, 3], getSingleSearchResults, searchStore);

  const handlePreview = (): void => {
    const name = viewName.trim();
    const isCopy = false;
    const shared = usersForShare
      .map(s => ({ userId: s.value, userName: s.label })) as Shared[];
    const viewData = { shared, filter, isCopy, moreOptions };
    if (name === "") {
      setViewNameBEErrors(["Name is required"]);
    }

    if (!isEdit && views.filter(el => el.name.toLocaleLowerCase() === viewName.toLocaleLowerCase()).length > 0) {
      return;
    }

    if (isViewFormValid(name, viewData)) {
      sessionStorage.setItem("previewView", JSON.stringify({
        ...currentView,
        ...viewData,
        name,
        sharedUsers,
        recipients,
        usersForShare,
        isEdit,
        sort,
        isViewCreator: viewCreatedByCurrentUser
      }));
      setIsCollapsed(true);
      onPreview(data, totalCount, totalBillCount, viewName);
    } else {
      setShowErrors(true);
    }
  };

  const handleSubmitView = async (isCopy: boolean): Promise<void> => {
    if (copy && !isCopy) {
      return;
    }

    const name = viewName.trim();
    setCopy(isCopy);
    const shared = usersForShare
      .map(s => ({ userId: s.value, userName: s.label })) as Shared[];
    const viewData = { shared, filter, isCopy, moreOptions };
    if (name === "") {
      setViewNameBEErrors(["Name is required"]);
    }

    if (isCopy && isEdit && JSON.stringify(recipients) !== JSON.stringify(usersForShare)) {
      setUsersForShareEErrors(["You cannot save a copy with new shared recipients."]);
      setViewName(currentView?.name ?? "");
      setCopy(false);
      return;
    }

    let theView;
    if (currentView) {
      theView = currentView;
    } else if ((sessionStorage.getItem("previewView")?.length ?? 0) > 0) {
      const previewView: any = JSON.parse(sessionStorage.getItem("previewView") ?? "");
      theView = previewView;
    }

    if (isViewFormValid(name, viewData)) {
      setTouched(false);
      resetErrors();

      if (isCopy || !isEdit) {
        if (isCopy) {
          viewData.shared = [];
        }

        const view = getCreateViewModel(name, viewData, sort);

        setIsDisableButton(true);
        addViewFn(view);
        setTimeout(() => {
          onClose();
        }, 700);
        localStorage.setItem("lastBucketId", view.id);
        fetchAndSetDetailView(view.id);
        setIsCollapsed(false);
      } else if (theView) {
        const view = getUpdateViewModel(name, theView, viewData, sort);

        if (view.shared && view.shared.length > 0
          && view?.created?.userId
          && view.shared.map(x => x.userId).includes(view?.created?.userId)
        ) {
          setShowErrors(true);
          setUsersForShareEErrors([`You cannot share the bucket with it's creator ${view.created.userName ?? ""}.`.trim()]);
        }

        setIsDisableButton(true);
        updateViewFn(view);
        setIsCollapsed(false);
      }
    } else {
      setTouched(true);
      setShowErrors(true);
    }
  };

  const handleEnableEditMode = async (): Promise<void> => {
    setLocalIsLoading(true);
    resetErrors();

    // Sort can be edited while in "View" mode => reset any possible changes
    if (currentView?.sort) {
      setSort({ ...currentView.sort });
    }

    // On edit, the existing shared users will be marked as readonly
    if (currentView?.shared && currentView.shared.length > 0) {
      const viewShared = currentView.shared.map(s => (
        { value: s.userId, label: s.userName }));
      setUsersForShare(viewShared);
      setRecipients(viewShared);
    }

    // The filter needs some processing to match the react-query-builder library format requirements
    if (currentView?.filter) {
      const processedFilterForEdit = await preProcessFilterForEdit(currentView?.filter);
      setFilter(processedFilterForEdit);
    }

    setViewName(currentView?.name ?? "");
    setTouched(false);
    setLocalIsLoading(false);
  };

  const handleClose = (): void => {
    if (touched) {
      setShowUnsavedChangesModal(true);
    } else {
      onClose();
    }
  };

  const cancelEdit = (): void => {
    // Reset any possible changes made on visible view fields (Edit => View)
    resetErrors();
    setViewName("");
    setTouched(false);
  };

  const handleConfirm = (): void => {
    setShowUnsavedChangesModal(false);
    cancelEdit();
    resetViewFieldsToDefaultValue();
    setCopy(false);
    setIsDisableButton(false);
    onClose();
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleUserChange = (_event: SyntheticEvent<Element, Event>, values: FieldOption[]) => {
    setUsersForShare(values);
    handleSetTouched();
  };

  const handleDrawerClose = (event: React.SyntheticEvent, reason: string): void => {
    if (reason !== "backdropClick") {
      handleClose();
    }
  };

  const handleDeleteChip = (option: FieldOption): void => {
    setUsersForShareEErrors([]);
    const updatedOptions = usersForShare.filter(el => el !== option);
    setUsersForShare(updatedOptions);
    setShowErrors(false);
    setIsDisableButton(false);
  };

  // Clear store before loading new data.
  useEffect(() => searchStore.reset!(), []);

  useEffect(() => {
    if (viewNameBEErrors.length > 0) {
      return;
    }

    const validationErrors = errors
      .filter(x => x.type === "validation")
      .map(x => x?.problemDetails?.errors);
    if (validationErrors.length === 0) {
      return;
    }

    const nameValidationErrors = validationErrors
      .filter(x => Object.keys(x ?? {}).some(y => y === "Name"))
      .map(x => x?.Name);
    if (nameValidationErrors.length === 0) {
      return;
    }

    setViewNameBEErrors(nameValidationErrors[0] ?? []);
    handleSetTouched();
    setShowErrors(true);
  }, [errors, viewNameBEErrors, setShowErrors, setViewNameBEErrors, handleSetTouched]);

  useEffect(() => {
    resetErrors();
    setIsDisableButton(false);

    if (isEdit) {
      return;
    }

    if (views
      .filter(el => el.type == "Created" && el.name.toLocaleLowerCase() === viewName.toLocaleLowerCase()).length > 0) {
      setViewNameBEErrors(["You already have a bucket with this name."]);
    } else {
      setViewNameBEErrors([]);
    }
  }, [isEdit, viewName, views]);

  useEffect(() => {
    const execute = async (): Promise<void> => {
      const res = await getUsers({ url: "user/share", search: searchKey });
      // sort the response and remove duplicated item
      const sortData = [...res]
        .reduce<typeof res>((unique, item) => {
          const labels = unique.map(uniqueItem => uniqueItem.label);
          if (!labels.includes(item.label)) {
            unique.push(item);
          }

          return unique;
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: "base" }));

      setSharedUsers(sortData);
    };
    void execute();
  }, [searchKey]);

  useEffect(() => {
    if (isEdit && currentView) {
      void handleEnableEditMode();
    }
  }, [isEdit, currentView]);

  const onDrawerClick = (): void => {
    if (isCollapsed) {
      setIsCollapsed(false);
    }
  };

  const getDrawerAnimation = (): string | undefined => {
    if (!open) {
      return undefined;
    }

    return `${isCollapsed ? "moveRightForPreview" : "moveLeftForCancelPreview"} 0.4s forwards`;
  };

  return (
    <>
      <Drawer
        disableEnforceFocus
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        onClick={onDrawerClick}
        hideBackdrop={isCollapsed}
        ModalProps={{ style: { position: isCollapsed ? "initial" : "fixed" }, disableEscapeKeyDown: true }}
        PaperProps={{
          style: {
            visibility: showDrawer ? "visible" : "hidden",
            animation: getDrawerAnimation()
          }
        }}
      >
        <Box
          style={{
            width: "44rem",
            cursor: "pointer",
            backgroundColor: isCollapsed ? "rgba(0, 0, 0, 0.5)" : "white",
            animation: isCollapsed ? "animateBackground 1.5s infinite alternate" : "none",
            pointerEvents: isCollapsed ? "none" : "auto"
          }}
        >
          <Box sx={{ padding: "1rem" }} justifyContent="space-between">
            <Typography variant="h4">
              {title}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: "0.5rem",
                top: "0.5rem",
                color: theme => theme.palette.grey[800]
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ overflowY: "auto", height: "calc(100vh - 7.125rem)" }}>
            <Box sx={{ padding: "1rem" }}>
              <Box>
                <Typography variant="h5">
                  Metadata
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {localIsLoading ? <Skeleton height={80} /> : (
                      <>
                        {!copy && (
                          <TextField
                            error={viewNameBEErrors?.length > 0}
                            id="standard-name"
                            label="Name"
                            type="text"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 30 }}
                            disabled={
                              (isEdit && currentView?.shared && currentView.shared.length !== 0)
                              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                              || (currentView?.serviceLines && currentView.serviceLines.length > 0)
                              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                              || (isEdit && sessionStorageView()?.shared && sessionStorageView().shared.length !== 0)
                              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                              || (sessionStorageView()?.serviceLines && sessionStorageView().serviceLines.length > 0)
                            }
                            value={viewName}
                            className={(touched && !viewName) || viewNameBEErrors?.length > 0 ? "has-error" : ""}
                            onChange={handleChangeViewName}
                          />
                        )}
                        {copy && (
                          <TextField
                            error={viewNameBEErrors?.length > 0}
                            id="standard-name-copy"
                            label="Name"
                            type="text"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 30 }}
                            value={viewName}
                            className={(touched && !viewName) || viewNameBEErrors?.length > 0 ? "has-error" : ""}
                            onChange={handleChangeViewName}
                          />
                        )}
                        {!isEdit && viewNameBEErrors?.length === 0
                          && (
                            <Typography
                              variant="body2"
                              sx={{ color: theme => theme.palette.grey[600] }}
                            >
                              {viewName.length}/30
                            </Typography>
                          )}
                        {
                          viewNameBEErrors?.length > 0 && viewNameBEErrors.map(viewNameBEError => (
                            <Typography key={viewNameBEError} variant="body2" className="form-error">
                              {viewNameBEError}
                            </Typography>
                          ))
                        }
                        {isEdit && viewNameBEErrors?.length === 0
                          && (
                            <Typography
                              variant="body2"
                              sx={{ color: theme => theme.palette.grey[400] }}
                            >
                              Save a copy to edit
                            </Typography>
                          )}
                      </>
                    )}

                  </Grid>
                  <Grid item xs={6}>
                    {localIsLoading ? <Skeleton height={80} /> : (
                      <>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="sort-simple-select-standard-label">Sort by</InputLabel>
                          <Select
                            labelId="sort-simple-select-standard-label"
                            id="sort-simple-select-standard"
                            value={`${sort.sortColumn}_${sort.isDescendent.toString()}`}
                            name="Sort"
                            label="Sort"
                            onChange={handleChangeSort}
                            disabled={
                              (!viewCreatedByCurrentUser && isEdit)
                              || (currentView?.serviceLines && currentView.serviceLines.length > 0)
                              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                              || (sessionStorageView()?.serviceLines && sessionStorageView().serviceLines.length > 0)
                            }
                          >
                            {sorts.map((item, i) => (
                              <MenuItem key={i} value={`${item.name}_${item.isDescendent.toString()}`}>{item.label}</MenuItem>))}
                          </Select>
                        </FormControl>
                        {isEdit && !viewCreatedByCurrentUser
                          && (
                            <Typography
                              variant="body2"
                              sx={{ color: theme => theme.palette.grey[400] }}
                            >
                              Save a copy to edit
                            </Typography>
                          )}
                      </>
                    )}

                  </Grid>
                  <Grid item xs={12}>
                    {localIsLoading ? <Skeleton height={80} /> : (
                      <>
                        <Autocomplete
                          multiple
                          id="users-standard"
                          options={sharedUsers}
                          getOptionLabel={option => {
                            const label = option.label ?? "";
                            return label;
                          }}
                          disableCloseOnSelect
                          onChange={handleUserChange}
                          onFocus={() => { setSearchKey(""); }}
                          ref={shareUsersRef}
                          value={(currentView?.serviceLines && currentView.serviceLines.length > 0)
                            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                            || (sessionStorageView()?.serviceLines && sessionStorageView().serviceLines.length > 0)
                            ? [] : usersForShare}
                          className={usersForShareEErrors?.length > 0 ? "has-error" : ""}
                          disabled={
                            (currentView?.serviceLines && currentView.serviceLines.length > 0)
                            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                            || (sessionStorageView()?.serviceLines && sessionStorageView().serviceLines.length > 0)
                          }
                          renderTags={(values, getTagProps) => values.map((option, index) => {
                            const isSpecialItem = recipients.includes(option);
                            return (
                              <Chip
                                label={option.label}
                                {...getTagProps({ index })}
                                onDelete={isEdit && isSpecialItem ? undefined : () => {
                                  handleDeleteChip(option);
                                }}
                              />
                            );
                          })}
                          renderOption={(props, option, { selected }) => (
                            <MenuItem
                              key={option.label}
                              value={option.value}
                              sx={{ justifyContent: "space-between" }}
                              {...props}
                            >
                              {option.label}
                              {selected ? <CheckIcon color="info" /> : null}
                            </MenuItem>
                          )}
                          renderInput={params => (
                            <TextField
                              {...params}
                              type="text"
                              variant="standard"
                              label="Share with (optional)"
                              onKeyDown={e => {
                                if (e.key === "Backspace" || e.key === "Delete") {
                                  e.stopPropagation();
                                }

                                if (e.key === "Enter" && shareUsersRef?.current != null) {
                                  shareUsersRef.current.dispatchEvent(
                                    new KeyboardEvent("keydown", { key: "ArrowDown", bubbles: true })
                                  );
                                }
                              }}
                              onChange={event => setSearchKey(event.target.value)}
                              error={usersForShareEErrors?.length > 0}
                              className={viewNameBEErrors?.length > 0 ? "has-error" : ""}
                            />
                          )}
                          clearIcon={!isEdit ? undefined : null}
                        />
                        {isEdit && usersForShareEErrors?.length === 0 && (
                          <Typography
                            variant="body2"
                            sx={{ color: theme => theme.palette.grey[600] }}
                          >
                            {viewCreatedByCurrentUser
                              ? "You originally created this bucket"
                              : `${currentView?.created?.userName ?? ""} originally created this bucket`}
                          </Typography>
                        )}
                        {
                          usersForShareEErrors?.length > 0 && usersForShareEErrors.map(error => (
                            <Typography key={error} variant="body2" className="form-error">
                              {error}
                            </Typography>
                          ))
                        }
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ padding: "1rem" }}>
              {localIsLoading ? (
                <Box>
                  <Skeleton height={60} width="45%" />
                  <Skeleton height={65} />
                  <Box display="flex">
                    <Skeleton height={60} width={100} />
                    <Box width={10} />
                    <Skeleton height={60} width={100} />
                  </Box>
                </Box>
              ) : (
                <ViewContentFilter
                  onChange={handleChangeFilter}
                  filter={filter}
                  showErrors={showErrors}
                  disabled={
                    (isEdit && !viewCreatedByCurrentUser)
                    || (currentView?.serviceLines && currentView.serviceLines.length > 0)
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    || (sessionStorageView()?.serviceLines && sessionStorageView().serviceLines.length > 0)
                  }
                />
              )}
            </Box>
          </Box>
          <Box sx={{ height: "3.5rem" }} display="flex">
            <Button
              variant="text"
              color="inherit"
              size="large"
              fullWidth
              onClick={handleClose}
            >
              <Typography variant="body1">
                CANCEL
              </Typography>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              onClick={() => handlePreview()}
            >
              <Typography variant="body1" color={theme => theme.palette.grey[100]}>
                PREVIEW
              </Typography>
            </Button>
            {isEdit && (
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                onClick={async () => handleSubmitView(true)}
                disabled={isDisableButton}
              >
                <Typography variant="body1" color={theme => theme.palette.grey[100]}>
                  SAVE COPY
                </Typography>
              </Button>
            )}
            {
              (!isEdit
                || (currentView?.serviceLines?.length ?? 0) === 0
                || (sessionStorage.getItem("previewView")?.length ?? 0) > 0) && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={async () => handleSubmitView(false)}
                  disabled={isDisableButton || copy}
                >
                  <Typography variant="body1">
                    {isEdit ? "SAVE" : "CREATE"}
                  </Typography>
                </Button>
              )
            }
          </Box>
        </Box>
      </Drawer>
      <ConfirmationDialog
        title="Discard unsaved changes?"
        message="If you continue, any unsaved changes will be lost."
        open={showUnsavedChangesModal}
        cancel={() => setShowUnsavedChangesModal(false)}
        confirm={handleConfirm}
      />
    </>
  );
};

export default BucketDrawerForm;
