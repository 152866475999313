import { type QueryKey, useMutation, type MutationFunction, useQueryClient, type UseMutateFunction } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { type BaseStore } from "@models/base-store.model";
import { type PatchVariables } from "@models/patch-variables.model";
import retry from "@utils/store.util";

const usePatchStore = <TStore, TParams, TPatchValue>(key: QueryKey,
  mutationFn: MutationFunction<void, PatchVariables<TParams, TPatchValue>>, store: BaseStore<TStore>,
  successFn?: VoidFunction): UseMutateFunction<void, unknown, PatchVariables<TParams, TPatchValue>> => {
  const storeRef = useRef(store);
  const queryClient = useQueryClient();
  const { status, error, mutate } = useMutation({ mutationKey: key,
    mutationFn,
    onSuccess: (_, variables) => {
      if (!variables.doNotInvalidateQuery) {
        void queryClient.invalidateQueries({ queryKey: key, exact: true });
      }

      storeRef.current.patch?.(variables.patch);
      successFn?.();
    },
    retry
  });

  useEffect(() => {
    storeRef.current.setIsLoading?.(status === "pending");
  }, [status]);

  useEffect(() => {
    storeRef.current.setError?.(error);
  }, [error]);

  return mutate;
};

export default usePatchStore;
