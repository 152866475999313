import { type SortOptionsType } from "@models/sort-options-type.model";
import SortType from "@enums/sort-type.enum";

const sortOptions: SortOptionsType = {
  admissionDate: {
    label: "Admission date by",
    name: SortType.AdmissionDate,
    ascOption: "oldest",
    descOption: "newest"
  },
  assignedTo: {
    label: "Assigned",
    name: SortType.AssignedTo,
    ascOption: "A-Z",
    descOption: "Z–A"
  },
  balance: {
    label: "Balance",
    name: SortType.Balance,
    ascOption: "low to high",
    descOption: "high to low"
  },
  dsw: {
    label: "Days since worked by",
    name: SortType.Dsw,
    ascOption: "oldest",
    descOption: "newest"
  },
  followupDate: {
    label: "Follow-up date by",
    name: SortType.FollowupDate,
    ascOption: "oldest",
    descOption: "newest"
  },
  timelyFiling: {
    label: "Timely filing by",
    name: SortType.TimelyFiling,
    ascOption: "oldest",
    descOption: "newest"
  },
  totalCharge: {
    label: "Total charge",
    name: SortType.TotalCharge,
    ascOption: "low to high",
    descOption: "high to low"
  }
};

export default sortOptions;
