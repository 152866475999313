import ServiceLine from "@enums/service-line.enum";
import MVAIcon from "@assets/icons/mva.svg";
import PremisesLiabilityIcon from "@assets/icons/premises-liability.svg";
import TricareIcon from "@assets/icons/tricare.svg";
import VAIcon from "@assets/icons/va.svg";
import WCIcon from "@assets/icons/worker-compensation.svg";
import DenialsIcon from "@assets/icons/denials.svg";
import OSMIcon from "@assets/icons/osm.svg";
import AMBWCIcon from "@assets/icons/amb_wc.svg";
import AMBMVAIcon from "@assets/icons/amb_mva.svg";
import CoverageDiscoveryIcon from "@assets/icons/coverage-discovery.svg";
import { type GlobalSearchItem } from "@models/global-search-item.model";
import { cognitiveSearchClient } from "@services/global-search.service";
import stringToEnum from "@utils/enum.util";
import formatDate from "@utils/date.util";
import formatPhoneNumber from "@utils/phone.util";
import { formatSSN, isPartialSSN } from "@utils/ssn.util";

const DUPLICATED_FIELDS: string[] = ["patientname", "dbnum", "facility", "admitdate"];
const SUGGESTER_NAME = "quickSearchSuggestions";

const getServiceLineIcon = (serviceLine: string): string => {
  // Validate input data (trust no input data).
  if (!serviceLine) {
    return "";
  }

  // Business logic.
  const serviceLineEnum = stringToEnum(serviceLine, ServiceLine);

  // Create success output.
  switch (serviceLineEnum) {
    case ServiceLine.MVA:
      return MVAIcon;
    case ServiceLine.WC:
      return WCIcon;
    case ServiceLine.Tricare:
      return TricareIcon;
    case ServiceLine.VA:
      return VAIcon;
    case ServiceLine.PL:
      return PremisesLiabilityIcon;
    case ServiceLine.Denials:
      return DenialsIcon;
    case ServiceLine.OSM:
      return OSMIcon;
    case ServiceLine.CD:
      return CoverageDiscoveryIcon;
    case ServiceLine.AMB_WC:
      return AMBWCIcon;
    case ServiceLine.AMB_MVA_PL:
      return AMBMVAIcon;
    default:
      return "";
  }
};

const getAdmissionDate = (option: GlobalSearchItem): string | null => {
  // Validate input data (trust no input data).
  if (!option?.data?.sinceWorked) {
    return null;
  }

  // Business logic.
  const sinceWorked = new Date(option.data.sinceWorked);
  const time = sinceWorked.getTime();
  if (!time) {
    return null;
  }

  // Create success output.
  return formatDate(sinceWorked);
};

const getNormalizedHighlights = (option: GlobalSearchItem): Record<string, string[]> => {
  // Validate input data (trust no input data).
  if (!option.highlights) {
    return {};
  }

  // Business logic.
  const highlights = Object.keys(option.highlights).reduce<Record<string, string[]>>((previousValue, key) => {
    const value = option.highlights?.[key]?.[0] ?? "";
    const jsonKey = key.toLowerCase().replace(/\s+/g, "");
    const upperKey = jsonKey.toUpperCase();
    if (value.includes("{")) {
      const jsonValue = JSON.parse(value) as Record<string, string>;
      const innerKeys = Object.keys(jsonValue);
      const innerValues = innerKeys.map(innerKey => jsonValue[innerKey]);
      previousValue[upperKey] = [innerValues.join(", ")];
    } else {
      previousValue[upperKey] = [value.replace(/(")/gi, "")];
    }

    return previousValue;
  }, {});

  // Create success output.
  return highlights;
};

const removeEmTagsFromString = (text: string) : string => text.replace(/<\/?em>/g, "");

const getRemainingHighlights = (
  option: GlobalSearchItem,
  normalizedHighlights: Record<string, string[]>
) : string => {
  // Validate input data (trust no input data).
  const highlights = Object.entries(normalizedHighlights);
  if (highlights.length === 0) {
    return "";
  }

  // Business logic.
  const remainingHighlights = highlights.filter(([key]) => !DUPLICATED_FIELDS.includes(key.toLowerCase()))
    .map(([key, value]) => {
      const originalKey = Object.keys(option.highlights ?? {}).find(k => k.toUpperCase() === key);
      const fieldName = originalKey ?? key;
      let fieldValue = value?.toString() ?? "";
      if (fieldName.toLowerCase().includes("phone")) {
        fieldValue = `<em>${formatPhoneNumber(removeEmTagsFromString(fieldValue))}</em>`;
      } else if (fieldName.toLowerCase().includes("ssn")) {
        fieldValue = `<em>${formatSSN(removeEmTagsFromString(fieldValue))}</em>`;
      }

      return `${originalKey ?? key}: ${fieldValue};`;
    })
    .join(" ");

  // Create success output.
  return remainingHighlights;
};

const getHighlightSearchText = async (text: string): Promise<string> => {
  // Validate input data (trust no input data).
  if (!text) {
    return "";
  }

  const words = text.split(/\s+/);
  const noOfWords = words.length;
  const lastWord = words[noOfWords - 1];
  let textForSuggest = text;
  const textIsFormattedPartialSSN = lastWord && isPartialSSN(lastWord) && lastWord.includes("-");
  // If the last word of the search phrase is a formatted partial ssn
  // we de-format if and so that it will be treated as a single word by
  // the api for autocomplete.
  let ssnSuggestionFound = false;
  let autocompleteItem;
  if (textIsFormattedPartialSSN) {
    words[noOfWords - 1] = lastWord.split("-").join("");
    textForSuggest = words.join(" ");

    // If we suspect theu user may be trying to type in an ssn,
    // We firstly try to retrieve a suggestion sourced only from the indexes SSNs.
    const searchResults = await cognitiveSearchClient.autocomplete(
      textForSuggest,
      SUGGESTER_NAME,
      { top: 1, searchFields: ["SSNSuggestColumn"] }
    );
    [autocompleteItem] = searchResults.results;

    if (autocompleteItem) {
      ssnSuggestionFound = true;
    }
  }

  // If no ssn suggestion was obtained or if it wasn't searched for at all
  // We try to get a suggestion from all the fields included in the suggester.
  if (!ssnSuggestionFound) {
    const searchResults = await cognitiveSearchClient.autocomplete(textForSuggest, SUGGESTER_NAME, { top: 1 });
    [autocompleteItem] = searchResults.results;

    if (!autocompleteItem) {
      return "";
    }
  }

  // Create success output.
  const queryPlusText = autocompleteItem?.queryPlusText ?? "";
  if (textIsFormattedPartialSSN) {
    words.splice(-1);
    const textWithoutSSN = words.join(" ");
    const suggestion = formatSSN(queryPlusText.slice(textWithoutSSN.length).trim());

    // If the suggestion returned isn't a partial/full ssn but the term autocompleted
    // is a partial SSN then we don't display it.
    // This logic should be updated if we need to autocomplete phone numbers.
    if (isPartialSSN(suggestion)) {
      return textWithoutSSN
        ? `${textWithoutSSN} ${suggestion}`
        : suggestion;
    }

    return textWithoutSSN;
  }

  const suggestion = queryPlusText.slice(textForSuggest.length);
  return `${text}${suggestion}`;
};

const retrieveEmphasizedSubstrings = (str: string) : string[] => {
  const regex = /<em>(.*?)<\/em>/g;
  const matches = str.match(regex);

  if (matches) {
    const substrings = matches.map(match => match.replace(/<\/?em>/g, ""));
    return substrings;
  }

  return [];
};

const emphasizeSubstring = (str: string, highlight: string) : string => {
  const substrStartIndex = str.indexOf(highlight);
  return `${str.substring(0, substrStartIndex)}<em>${highlight}</em>${str.substring(
    substrStartIndex + highlight.length,
    str.length
  )}`;
};

const mergeOriginalValueWithHighlights = (
  original: string,
  highlights: string[]
) : string => {
  if (!highlights) {
    return highlights;
  }

  let composedString = original;
  highlights.forEach(highlight => {
    const substrings = retrieveEmphasizedSubstrings(highlight);
    substrings.forEach(subHighlight => {
      composedString = emphasizeSubstring(composedString, subHighlight);
    });
  });
  return composedString;
};

const getHighlightText = (inputValue: string | undefined, str: string | undefined, option = ""): string => {
  if (!inputValue || !str) {
    return "";
  }

  const inputMatchAll = inputValue
    .toLocaleLowerCase()
    .replaceAll("/", " ");
  const strMatchAll = str
    .toLocaleLowerCase()
    .replace(/<(\/)*em>/g, "")
    .replaceAll("/", " ");

  if (inputMatchAll === strMatchAll || strMatchAll.includes(inputMatchAll)) {
    const value = option === "SSN" ? inputValue : `<strong>${inputValue}</strong>`;
    const resultString = strMatchAll
      .replace(inputValue.toLocaleLowerCase(), value);
    return resultString;
  }

  const strValues = strMatchAll.split(" ");
  let matchedStrValues = "";

  for (let i = 0; i < strValues.length; i += 1) {
    const strValue = strValues[i] ?? "";
    if (inputMatchAll.includes(strValue)) {
      matchedStrValues = matchedStrValues.concat(strValue, " ");
    }
  }

  if (matchedStrValues.length > 0) {
    matchedStrValues = matchedStrValues.trim();
    const value = option === "SSN" ? matchedStrValues : `<strong>${matchedStrValues}</strong>`;
    const resultString = strMatchAll
      .replace(matchedStrValues, value);
    return resultString;
  }

  if (inputMatchAll.includes(strMatchAll)) {
    const value = option === "SSN" ? str : `<strong>${str}</strong>`;
    const resultString = strMatchAll
      .replace(strMatchAll.toLocaleLowerCase(), value);
    return resultString;
  }

  const inputValues = inputValue.split(" ");

  if (inputValues.some(value => str?.toLowerCase().includes(value.toLocaleLowerCase()))) {
    const val = inputValues.find(value => str?.toLowerCase().includes(value.toLocaleLowerCase())) ?? "";
    const value = option === "SSN" ? val : `<strong>${val}</strong>`;
    const resultString = str.toLowerCase()
      .replace(/<(\/)*em>/g, "")
      .replaceAll("/", " ")
      .replace(val.toLocaleLowerCase(), value);
    return resultString;
  }

  return "";
};

export {
  getServiceLineIcon,
  getAdmissionDate,
  getNormalizedHighlights,
  getRemainingHighlights,
  getHighlightSearchText,
  mergeOriginalValueWithHighlights,
  getHighlightText
};
