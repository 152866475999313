import { Box, Paper, type PaperProps, styled, Skeleton, Toolbar, Typography } from "@mui/material";
import { type AnimationProps, type HTMLMotionProps, type ForwardRefComponent } from "framer-motion";
import AppBar from "@mui/material/AppBar";
import Menu, { type MenuProps } from "@mui/material/Menu";

const BoxContainer = styled("div")(({ theme }) => ({
  padding: "0.25rem 0.5rem 0.25rem 0.5rem",
  borderRadius: "0.5rem",
  borderColor: theme.palette.secondary.light,
  borderStyle: "solid",
  borderWidth: "0.0625rem",
  marginBottom: "0.125rem",
  display: "flex",
  alignItems: "justify",
  width: "100%"
}));

const BoxHeader = styled(Box)(() => ({
  height: "2.375rem"
}));

const BlueContentPaper = styled(Paper)<PaperProps & AnimationProps &
{ component: ForwardRefComponent<HTMLDivElement, HTMLMotionProps<"div">> }>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  borderColor: theme.palette.secondary.light,
  borderStyle: "solid",
  borderWidth: 0,
  padding: "1rem 1.5rem 1rem 1.5rem",
  margin: "1rem 0 0 0",
  borderRadius: "0",
  borderTopRightRadius: "0.5rem",
  borderBottomRightRadius: "0.5rem",
  width: "100%",
  height: "calc(100vh - 7.25rem)",
  overflow: "auto"
}));

const BluePaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  borderColor: theme.palette.secondary.light,
  borderStyle: "solid",
  borderWidth: "0.0625rem",
  padding: "0.75rem 1.0rem",
  marginBottom: "0.625rem",
  cursor: "pointer",
  borderRadius: "0.625rem"
}));

const StatusBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.text.disabled,
  width: "1rem",
  height: "1rem",
  display: "inline-block"
}));

const LoadingBlock = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  width: "100%",
  "&::after": {
    animationDuration: "1s",
    background: `linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 0.28),
      transparent
    )`
  }
}));

const StyledUl = styled("ul")(() => ({
  paddingLeft: 0
}));

const TopBar = styled(AppBar)(() => ({
  // borderBottom: `0.0625rem solid ${theme.palette.divider}`,
  backgroundColor: "#293056"
  // color: "#293056"
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 0,
    backgroundColor: "#293056",
    marginTop: theme.spacing(0),
    boxShadow:
    "0 0.125rem 0.25rem -0.0625rem rgba(0,0,0,0.2), 0 0.25rem 0.3125rem 0 rgba(0,0,0,0.14),0 0.0625rem 0.625rem 0 rgba(0,0,0,0.12)",
    minWidth: "10rem",
    "& .MuiMenu-list": {
      padding: "0.625rem 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(1.5)
      }
    }
  }
}));

const ItemWrapper = styled(Toolbar)(() => ({
  borderTop: "0.0625rem solid rgba(0, 0, 0, 0.12)",
  width: "100%",
  padding: "0.5rem 1rem",
  backgroundColor: "#ffffff",
  "&:hover": {
    backgroundColor: "#e8e8e8"
  }
}));

const SearchField = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  textOverflow: "ellipsis",
  overflow: "hidden"
}));

export {
  BoxContainer,
  BoxHeader,
  BlueContentPaper,
  BluePaper,
  StatusBox,
  LoadingBlock,
  StyledMenu,
  StyledUl,
  TopBar,
  ItemWrapper,
  SearchField
};
