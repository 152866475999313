import type React from "react";
import { useRef } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ArrowDropUp } from "@mui/icons-material";
import type { ActionMeta, MenuPlacement, PropsValue, MultiValue, SelectInstance, GroupBase } from "react-select";
import { getFieldValues } from "@services/field.service";
import AsyncPaginatedSelectStyles from "@utils/async-paginated-select-styles.util";
import { type FieldOption } from "@models/field-option.model";
import { type RenderDropdown } from "@models/render-dropdown.model";

interface PaginatedMultiSelectProps {
  value: PropsValue<FieldOption>;
  onChange: (event: MultiValue<FieldOption>) => void;
  className: string;
  name: string;
  placeholder: string;
  url: string;
  isClearable: boolean;
  disabled: boolean;
  closeMenuOnSelect: boolean;
  notPaginated: boolean;
  menuPlacement: MenuPlacement
}

const renderDropdown: React.FC<RenderDropdown> = ({ isFocused }) => (isFocused ? <ArrowDropUp /> : <ArrowDropDownIcon />);

const PaginatedMultiSelect = ({ value,
  className, onChange, name, placeholder, url,
  isClearable, menuPlacement, disabled = false, closeMenuOnSelect = false,
  notPaginated = false
}: PaginatedMultiSelectProps): JSX.Element => {
  const asyncPaginateRef = useRef<SelectInstance<FieldOption, true, GroupBase<FieldOption>>>(null);
  const customStyles = AsyncPaginatedSelectStyles();

  const onChangeLocal = (values: MultiValue<FieldOption>, selectValue: ActionMeta<FieldOption>): void => {
    if (isClearable && values === null && selectValue?.action === "clear") {
      onChange([]);
    } else {
      onChange(values.toSorted((a, b) => a.label.localeCompare(b.label)));
    }
  };

  return (
    <AsyncPaginate
      cacheUniqs={[name]}
      isClearable={isClearable}
      additional={{ page: 0 }}
      debounceTimeout={300}
      selectRef={asyncPaginateRef}
      className={className}
      isMulti
      classNamePrefix="custom_select"
      closeMenuOnSelect={closeMenuOnSelect}
      name={name}
      placeholder={placeholder}
      value={value}
      loadOptions={
        async (search: string, _prevOptions, additional?: { page: number; }) => (
          getFieldValues({ search, page: additional?.page ?? 1, url, notPaginated })
        )
      }
      onKeyDown={e => {
        if (e.key === "Enter" && asyncPaginateRef?.current != null) {
          asyncPaginateRef.current.openMenu("first");
        }
      }}
      styles={customStyles}
      components={{
        DropdownIndicator: renderDropdown,
        IndicatorSeparator: null
      }}
      onChange={onChangeLocal}
      menuPlacement={menuPlacement}
      isDisabled={disabled}
      data-cy={name}
    />
  );
};

export default PaginatedMultiSelect;
