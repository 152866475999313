import { Button, styled } from "@mui/material";
import { type MouseEvent } from "react";

const ErrorWrapper = styled("div")(() => ({
  display: "flex",
  height: "100vh",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "1rem"
}));

const Error = (): JSX.Element => {
  const restart = (event: MouseEvent): void => {
    event.preventDefault();
    window.location.reload();
  };

  return (
    <ErrorWrapper>
      <div>An error has occurred. Please restart the application.</div>
      <Button variant="contained" onClick={e => restart(e)}>
        Restart Application
      </Button>
    </ErrorWrapper>
  );
};

export default Error;
