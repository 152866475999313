import { createBrowserRouter, RouterProvider } from "react-router-dom";
import BucketsHome from "@pages/buckets-home/buckets-home.page";
import RootContainer from "@pages/root-container/root-container.page";
import { useEffect } from "react";
import AuthorizedLayout from "./layout/authorized/authorized.layout";
import BucketsAccountPage from "./pages/buckets-account/buckets-account.page";
import ViewDetail from "./pages/view-detail/view-detail.page";
import Login from "./pages/login/login.page";
import Home from "./pages/home/home.page";

const Router = (): JSX.Element => {
  useEffect(() => () => {
    sessionStorage.removeItem("previewView");
    sessionStorage.removeItem("showDrawer");
  }, []);
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />
    },
    {
      path: "/",
      element: <AuthorizedLayout />,
      children: [
        {
          path: "/",
          element: <RootContainer />,
          children: [
            {
              index: true,
              element: <BucketsHome />
            },
            {
              path: "/account/:accountId",
              element: <BucketsAccountPage />
            }
          ]
        },
        // {
        //   path: "/account/:id/:billId?",
        //   element: <AccountPage />
        // },
        {
          path: "/view",
          element: <ViewDetail />
        },
        {
          path: "/view/:id?",
          element: <ViewDetail />
        },
        {
          path: "/oldhome",
          element: <Home />
        },
        {
          path: "*",
          element: <BucketsHome />
        }
      ]
    }
  ]);
  return (
    <RouterProvider router={router} />
  );
};

export default Router;
