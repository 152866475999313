import { useState, useEffect, useCallback } from "react";
import type { SingleValue } from "react-select";
import { getFieldValues } from "@services/field.service";
import { type FieldOption } from "@models/field-option.model";
import SubMenu from "./submenu-item/submenu-item.component";

interface NestedSelectProps {
  value: FieldOption;
  url: string;
  onChange: (event: SingleValue<FieldOption>) => void;
}

const NestedSelect = ({ url, onChange, value }: NestedSelectProps): JSX.Element => {
  const [recomposeSubMenu, setRecomposeSubMenu] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<FieldOption[]>([]);

  const fetchData = useCallback(async (): Promise<void> => {
    const result = await getFieldValues({ url });
    setOptions(result.options as FieldOption[]);
  }, [url]);

  useEffect(() => {
    setIsLoading(true);
    fetchData()
      .finally(() => setIsLoading(false));
  }, [url, fetchData]);

  const handleClickOption = (option: FieldOption): void => {
    // Limit the selection just for the leaf nodes
    if ((option.children?.length ?? 0) === 0) {
      onChange(option);

      setRecomposeSubMenu(false);
      setTimeout(() => {
        setRecomposeSubMenu(true);
      }, 300);
    }
  };

  return (
    <SubMenu
      key={`${(options?.length) ?? "submenu"}`}
      keyValue={`${(options?.length) ?? "submenu"}`}
      isLoading={isLoading}
      options={options}
      menuLevels={options?.[0]?.maxMenuLevel ?? 0}
      onOptionClick={handleClickOption}
      value={value}
      recomposeSubMenu={recomposeSubMenu}
    />
  );
};

export default NestedSelect;
