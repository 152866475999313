import { type AxiosInstance } from "axios";
import { login } from "@services/authentication-manager.service";
import { userAgentApplication, graphScopes } from "@utils/msal.util";
import getNumber from "@utils/number.util";

const URL = `${import.meta.env.VITE_MANAGEMENT_API_URL}`;

const ssoFunction = async (): Promise<void> => {
  await userAgentApplication.initialize();
  await userAgentApplication.ssoSilent({
    scopes: graphScopes,
    authority: import.meta.env.VITE_CLIENT_AUTHORITY });
};

ssoFunction()
  .catch(err => console.error(err))
  .then()
  .catch(err => console.error(err));

const authorizeInterceptor = (instance: AxiosInstance): void => {
  // When the time left until token expiry is less than this, trigger token refresh.
  // Default to 5 minutes if not set.
  const tokenExpirationThreshold = getNumber(import.meta.env.VITE_TOKEN_EXPIRATION_THRESHOLD, 300000);

  instance.interceptors.request.use(async request => {
    let token = localStorage.getItem("accessToken")!;
    const currentTokenExpirationTime = parseInt(localStorage.getItem("accessTokenExpirationTime")!, 10);
    const timeUntilRefresh = currentTokenExpirationTime - tokenExpirationThreshold;
    const currentTime = new Date().getTime();

    // Check if the token is expired or about to expire (within 5 minutes).
    if (currentTime >= timeUntilRefresh || Number.isNaN(timeUntilRefresh)) {
      await login(userAgentApplication);
      token = localStorage.getItem("accessToken")!;
      const iframe = document.getElementById("compass-1-iframe") as HTMLIFrameElement;
      const localStorageData = JSON.stringify(localStorage);
      iframe.contentWindow?.postMessage(localStorageData, "*");
    }

    if (!request.url?.includes("/static/")) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    const isCompassOneUrl = request.url?.startsWith(URL);
    if (isCompassOneUrl) {
      const accessToken = localStorage.getItem("accessToken")!;
      request.headers.Authorization = `Bearer ${accessToken}`;
    }

    return request;
  });
};

export default authorizeInterceptor;
