import { styled, Skeleton } from "@mui/material";

interface Props {
  itemsCount: number;
}

const LoadingBlock = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  margin: "0.625rem 0",
  borderRadius: "0.625rem",
  "&::after": {
    animationDuration: "1s",
    background: `linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 0.28),
      transparent
    )`
  }
}));

const LeftPanelLoader = ({ itemsCount }: Props): JSX.Element => (
  <>
    {
      Array.from({ length: itemsCount }, (_, k) => k + 1).map(item => (
        <LoadingBlock data-testid="loading-block" variant="rounded" width="100" height="5rem" animation="wave" key={item} />
      ))
    }
  </>
);

export default LeftPanelLoader;
