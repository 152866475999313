import type React from "react";
import Button from "@mui/material/Button";
import { useMsal } from "@azure/msal-react";
import { login } from "@services/authentication-manager.service";
import Compass from "@assets/images/compass.webp";
import Logo from "@assets/images/aspirion-logo-color.webp";
import Team from "@assets/images/team.avif";
import getNumber from "@utils/number.util";
import LoginContainer from "./login-container/login-container.component";

const Login: React.FC = () => {
  const loginRedirectDelay = getNumber(import.meta.env.VITE_LOGIN_REDIRECT_DELAY, 2000); // Default to 2 seconds if not set.
  const { instance } = useMsal();
  const initializeSignIn = async (): Promise<void> => {
    try {
      await login(instance);
    } catch (exception) {
      localStorage.clear();
    } finally {
      // Send a message to the iframe with the authentication data.
      try {
        const iframe = document.getElementById("authentication-iframe") as HTMLIFrameElement;
        const localStorageData = JSON.stringify(localStorage);
        iframe.contentWindow?.postMessage(localStorageData, "*");
      } finally {
        setTimeout(() => {
          window.location.href = "/";
        }, loginRedirectDelay); // Allow some time for the iframe to receive the message.
      }
    }
  };
  const legacyCompassUrl = `${import.meta.env.VITE_LEGACY_COMPASS_URL}/login`;

  return (
    <LoginContainer className="login-page">
      <div className="bg-container">
        <img src={Compass} alt="Aspirion Compass" className="compass" />
      </div>
      <div className="login-container">
        <div className="login-wrapper">
          <img src={Team} alt="Aspirion Team" className="team" />
          <div className="logo-wrapper">
            <img src={Logo} alt="ASPIRION LOGO" className="image-center" />
          </div>
          <div className="form-wrapper">
            <div className="form-group">
              <div className="authorized-use">
                <strong>Authorized Use Only</strong>
                <p>
                  The information on the account on this computer is the property of this
                  organization and is protected by intellectual property rights.
                  You must be assigned an account on this computer to access information and
                  are only allowed to access information defined by the system administrators.
                  Your activities will be monitored.
                </p>
              </div>
              <Button
                id="login-button"
                onClick={initializeSignIn}
                className="btn-success btn-center btn-big"
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
      <iframe
        title="authentication-iframe"
        id="authentication-iframe"
        src={legacyCompassUrl}
        hidden
        allow="clipboard-read; clipboard-write; display-capture"
      />
    </LoginContainer>
  );
};

export default Login;
