import { useCallback } from "react";
import { useBeforeUnload, unstable_usePrompt as usePrompt } from "react-router-dom";

const useBeforeRedirect = (message: string, when: boolean): void => {
  useBeforeUnload(
    useCallback((e: BeforeUnloadEvent): void => {
      if (when) {
        e.preventDefault();
        e.returnValue = "";
      }
    }, [when])
  );

  usePrompt({ when, message });
};

export default useBeforeRedirect;
