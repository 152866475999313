import { type ZodError } from "zod";
import { produce } from "immer";
import dayjs, { isDayjs } from "dayjs";
import { type RuleType, type RuleGroupType } from "react-querybuilder";
import { type SubmitViewProps } from "@models/submit-view-props.model";
import { isQueryValid } from "@logic/query-builder.logic";
import { type View, ViewSchema } from "@models/view.model";
import { type FieldOption } from "@models/field-option.model";
import Fields from "@data/field-names-type.data";
import FilterValueTypes from "@enums/filter-value-type.enum";
import { getViewFilterFieldLabels } from "@services/field.service";

const updateFilterValue = (rule: RuleType, labels: Record<string, FieldOption[]>): void => {
  // Validate input data (trust no input data).
  if (!rule || !Array.isArray(rule.value) || rule.value?.length === 0) {
    return;
  }

  // Business logic.
  const fieldWithType = Fields[rule.field as keyof typeof Fields];
  if (fieldWithType.type === FilterValueTypes.String) {
    const [newValue] = rule.value;

    const possibleDate = dayjs(newValue as string, "DD/MM/YYYY", true);
    if (possibleDate.isValid()) {
      rule.value = possibleDate;
      return;
    }

    rule.value = newValue as string;
    return;
  }

  if (fieldWithType.type === FilterValueTypes.Bool) {
    const [newValue] = rule.value;
    rule.value = Boolean(newValue);
    return;
  }

  if (fieldWithType.type === FilterValueTypes.SingleSelect) {
    const fieldLabels = labels[fieldWithType.name];
    if (Array.isArray(fieldLabels) && fieldLabels.length > 0) {
      const [valueId] = rule.value;
      const newValue = fieldLabels?.find(x => x.value === valueId);
      if (newValue && newValue.value?.length > 0) {
        rule.value = newValue;
        return;
      }
    }
  }

  if (fieldWithType.type === FilterValueTypes.MultiSelect) {
    const fieldLabels = labels[fieldWithType.name];
    if (Array.isArray(fieldLabels) && fieldLabels.length > 0) {
      const newValue: FieldOption[] = [];
      rule.value.forEach(valueId => {
        const valueWithLabel = fieldLabels?.find(x => x.value === valueId);
        if (valueWithLabel && valueWithLabel.value?.length > 0) {
          newValue.push(valueWithLabel);
        }
      });
      rule.value = newValue;
    }
  }
};

const processFilterRecursive = (ruleOrGroup: RuleType | RuleGroupType, labels: Record<string, FieldOption[]>)
  : RuleType | RuleGroupType => {
  // Validate input data (trust no input data).
  if (!ruleOrGroup) {
    return ruleOrGroup;
  }

  // Business logic.
  const immutableRule = produce(ruleOrGroup, draft => {
    const { combinator, rules, ...newRule } = draft as RuleGroupType;

    const rule = newRule as RuleType;
    if (rule?.field?.length > 0) {
      // Populate labels / update value from array to its original format
      updateFilterValue(rule, labels);
      return rule;
    }

    // React-query-builder treats empty rules as groups => cleanup empty rules returned from BE
    // will identify groups by the combinator because the last nested group does not have rules
    if (combinator && Array.isArray(rules)) {
      (draft as RuleGroupType).rules = rules.map((nestedRuleOrGroup: RuleType | RuleGroupType)
        : RuleType | RuleGroupType => processFilterRecursive(nestedRuleOrGroup, labels));
    }

    return draft;
  });

  // Create success output.
  return immutableRule;
};

const getLabelsViewModelRecursive = (ruleOrGroup: RuleType | RuleGroupType, labelsViewModel: Record<string, string[]>): void => {
  // Validate input data (trust no input data).
  if (!ruleOrGroup) {
    return;
  }

  // Business logic.
  if (Array.isArray((ruleOrGroup as RuleGroupType)?.rules) && (ruleOrGroup as RuleGroupType)?.rules.length > 0) {
    // The rule is actually a group => process its nested rules
    (ruleOrGroup as RuleGroupType).rules
      .forEach((nestedRule: RuleType | RuleGroupType): void => getLabelsViewModelRecursive(nestedRule, labelsViewModel));
  } else {
    // Populate labelsViewModel
    const rule = ruleOrGroup as RuleType;

    if (!Array.isArray(rule.value) || rule.value?.length === 0) {
      return;
    }

    const fieldWithType = Fields[rule.field as keyof typeof Fields];
    if (fieldWithType.type === FilterValueTypes.SingleSelect || fieldWithType.type === FilterValueTypes.MultiSelect) {
      let existingRecord = labelsViewModel[fieldWithType.name]?.concat([]) ?? []; // Clone the array to solve the immutability issue
      if (existingRecord.length === 0) {
        existingRecord = rule.value as string[];
      } else {
        rule.value.forEach(value => {
          const stringValue = value as string;
          if (!existingRecord.includes(stringValue)) {
            existingRecord.push(stringValue);
          }
        });
      }

      labelsViewModel[fieldWithType.name] = existingRecord;
    }
  }
};

const preProcessFilterForEdit = async (filter: RuleGroupType): Promise<RuleGroupType> => {
  // Validate input data (trust no input data).
  if (!filter || !Array.isArray(filter.rules) || filter.rules.length === 0) {
    return filter;
  }

  // <Key, Value> type (Key = fieldName / Value = a list of Guids)
  const labelsViewModel: Record<string, string[]> = {};

  // <Key, <Label, Value>> type (Key = fieldName)
  let labels: Record<string, FieldOption[]> = {};

  // Business logic.
  // 1. Populate labelsViewModel
  filter.rules.forEach((rule: RuleType | RuleGroupType): void => getLabelsViewModelRecursive(rule, labelsViewModel));

  // 2. Request labels from compass 1.0 API (if necessary)
  if (Object.keys(labelsViewModel).length > 0) {
    labels = await getViewFilterFieldLabels({ labelsViewModel });
  }

  // 3. Produce immutableFilter
  //   - React-query-builder treats empty rules as groups => cleanup empty rules returned from BE
  //   - Populate labels
  const immutableFilter = produce(filter, draft => {
    draft.rules = draft.rules
      .map((ruleOrGroup: RuleType | RuleGroupType): RuleType | RuleGroupType => processFilterRecursive(ruleOrGroup, labels));
  });

  // Create success output.
  return immutableFilter;
};

const isViewFormValid = (name: string, viewData: SubmitViewProps): boolean => {
  // Validate input data (trust no input data).
  if (!name) {
    return false;
  }

  // Business logic.
  const { filter } = viewData;
  if (!isQueryValid(filter)) {
    return false;
  }

  // Create success output.
  return true;
};

const validateView = (view: View): ZodError | null => {
  const validation = ViewSchema.safeParse(view);
  if (validation.success) {
    return null;
  }

  return validation.error;
};

const mapRuleValue = (rule: RuleType): void => {
  // Validate input data (trust no input data).
  if (!rule?.value) {
    return;
  }

  // Business logic.
  if (isDayjs(rule?.value)) {
    // Convert date value type to formatted string to keep the value as it is
    // Leaving the value as Date format will result in one day difference
    rule.value = rule?.value?.format("DD/MM/YYYY");
  } else if (typeof rule?.value === "object" && rule?.value?.value) {
    // Convert object value type to array of ID(s)
    rule.value = [rule?.value?.value?.toString()];
  } else if (Array.isArray(rule?.value) && rule?.value?.length > 0) {
    // Convert array value type to array of ID(s)
    rule.value = (rule.value as FieldOption[]).map((item: FieldOption) => item?.value);
  }
};

const convertFilerValuesRecursive = (ruleOrGroup: RuleType | RuleGroupType): void => {
  // Validate input data (trust no input data).
  if (!ruleOrGroup) {
    return;
  }

  // Business logic.
  if (Array.isArray((ruleOrGroup as RuleGroupType)?.rules) && (ruleOrGroup as RuleGroupType)?.rules.length > 0) {
    // The rule is actually a group => process its nested rules/groups
    (ruleOrGroup as RuleGroupType).rules
      .forEach((nestedRuleOrGroup: RuleType | RuleGroupType): void => convertFilerValuesRecursive(nestedRuleOrGroup));
  } else {
    mapRuleValue(ruleOrGroup as RuleType);
  }
};

const convertFilterValues = (filter: RuleGroupType | null | undefined): RuleGroupType | null | undefined => {
  // Validate input data (trust no input data).
  if (!filter || !Array.isArray(filter?.rules) || filter?.rules?.length === 0) {
    return filter;
  }

  // Business logic.
  const filterViewModel = produce(filter, draft => {
    draft.rules.forEach((ruleOrGroup: RuleType | RuleGroupType): void => convertFilerValuesRecursive(ruleOrGroup));
  });

  // Create success output.
  return filterViewModel;
};

export { preProcessFilterForEdit, isViewFormValid, validateView, convertFilterValues };
