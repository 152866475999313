import { Box, Grid, styled, Skeleton } from "@mui/material";

const LoadingBlock = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  margin: "0.625rem 0",
  "&::after": {
    animationDuration: "1s",
    background: `linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 0.28),
      transparent
    )`
  }
}));

const LoadingRectangleBlock = styled(LoadingBlock)(() => ({
  display: "flex",
  margin: "auto"
}));

const ServiceLineCardLoader = (): JSX.Element => (
  <Grid container spacing={2}>
    <Grid item xs={7}>
      <LoadingRectangleBlock
        data-testid="loading-rectangle-block"
        variant="rectangular"
        width="18rem"
        height="18rem"
        animation="wave"
      />
    </Grid>
    <Grid item xs={5}>
      <Box sx={{ mb: "2rem" }}>
        <LoadingBlock data-testid="loading-block" variant="rectangular" width="6rem" height="1.25rem" animation="wave" />
        <LoadingBlock data-testid="loading-block" variant="rectangular" width="12rem" height="2rem" animation="wave" />
      </Box>
      <Box sx={{ mb: "0.5rem" }}>
        <LoadingBlock data-testid="loading-block" variant="rectangular" width="12rem" height="1.25rem" animation="wave" />
        <LoadingBlock data-testid="loading-block" variant="rectangular" width="12rem" height="2rem" animation="wave" />
      </Box>
      <Box sx={{ mb: "0.5rem", display: "flex" }}>
        <Box>
          <LoadingBlock data-testid="loading-block" variant="rectangular" width="6rem" height="1.25rem" animation="wave" />
          <LoadingBlock data-testid="loading-block" variant="rectangular" width="12rem" height="1.5rem" animation="wave" />
        </Box>
        <Box sx={{ ml: "2rem" }}>
          <LoadingBlock data-testid="loading-block" variant="rectangular" width="3.125rem" height="3.125rem" animation="wave" />
        </Box>
      </Box>
      <Box sx={{ mb: "0.5rem" }}>
        <LoadingBlock data-testid="loading-block" variant="rectangular" width="6rem" height="1.25rem" animation="wave" />
        <LoadingBlock data-testid="loading-block" variant="rectangular" width="12rem" height="1.5rem" animation="wave" />
      </Box>
    </Grid>
  </Grid>
);

export default ServiceLineCardLoader;
