enum MenuItemType {
  Page = 1,
  Folder = 2,
  Tab = 3,
  PowerBiInline = 4,
  PowerBiExt = 5,
  PowerAppInline = 6,
  PowerAppExt = 7,
  Chameleon = 8
}

export default MenuItemType;
