import { v4 as uuidv4 } from "uuid";
import { type RuleGroupType } from "react-querybuilder";
import CombinatorTypes from "@enums/combinator-type.enum";

const defaultFilter: RuleGroupType = {
  id: uuidv4(),
  combinator: CombinatorTypes.And,
  not: false,
  rules: [{
    id: uuidv4(),
    field: "~",
    value: "~",
    operator: "~"
  }]
};

export default defaultFilter;
