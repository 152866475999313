import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { BroadcastChannel, createLeaderElection } from "broadcast-channel";
import { useImmer } from "use-immer";
import { useInterval, useTimeout } from "usehooks-ts";
import ErrorBoundary from "@components/error-boundary/error-boundary.component";
import { useConfigCat } from "@hooks/use-configcat.hook";
import useSessionStore from "@store/session.store";
import getUser from "@utils/user.util";
import { type Patch } from "@models/patch.model";
import { type User } from "@models/user.model";
import Alert from "@components/alert/alert.component";
import getNumber from "@utils/number.util";
import VersionMessage from "@components/version-message/version-message.component";
import useWorkedAccountsStore from "@store/worked-accounts.store";
import useHubConnection from "@hooks/use-hub-connection.hook";
import Header from "../header/header.layout";
import Footer from "../footer/footer.layout";
import ErrorsAlert from "../errors-alert/errors-alert.layout";

const ACCOUNTS_HUB_URL = `${import.meta.env.VITE_WORK_QUEUE_API_URL}/notifications`;

const Authorized = (): JSX.Element => {
  const legacyCompassRefreshInterval = getNumber(import.meta.env.VITE_LEGACY_COMPASS_REFRESH_INTERVAL, 3300000);
  // Default to 55 minutes if not set.

  const [checkingTab, setCheckingTab] = useImmer(true);
  const [tabStatus, setTabStatus] = useImmer("");
  const [loading, setLoading] = useImmer(false);

  const { accounts, inProgress } = useMsal();
  const sessionStore = useSessionStore();
  const [{ compass2Enabled }, featureFlagsLoaded] = useConfigCat();

  const workedAccountsStore = useWorkedAccountsStore();

  const completedAccountsHub = useHubConnection(ACCOUNTS_HUB_URL);

  useEffect(() => {
    if (!accounts.length && inProgress === InteractionStatus.None) {
      window.location.href = "/login";
      return;
    }

    const { session } = sessionStore;
    let { user } = session;
    if (!user) {
      user = getUser();

      if (!user) {
        return;
      }

      const patch: Patch<User> = {
        property: "user",
        value: user
      };
      sessionStore.patch!(patch);
    }
  }, [accounts, inProgress, sessionStore]);

  const checkingLeader = async (): Promise<void> => {
    const channel = new BroadcastChannel("tab");
    const elector = createLeaderElection(channel, {
      fallbackInterval: 1000, // The optional configuration for how often will renegotiation for leader occur.
      responseTime: 500 // The optional configuration for how long will instances have to respond.
    });

    await elector.awaitLeadership();
    setTabStatus("leader");

    await elector.hasLeader();
    const { isLeader } = elector;
    setCheckingTab(isLeader);
  };

  useEffect(() => {
    void checkingLeader();
  }, []);

  useTimeout(() => setLoading(true), 300);

  useInterval(() => {
    const iframe = document.getElementById("compass-1-iframe") as HTMLIFrameElement;
    iframe.src = import.meta.env.VITE_LEGACY_COMPASS_URL;
  }, legacyCompassRefreshInterval);

  useInterval(() => {
    // Periodically purge old worked today accounts from the store.
    // These accounts are considered to have been already updated in the cognitive
    // search index and therefore we don't need to filter by them anymore.
    // The interval is decided to be the same as the one used in the backend
    // index sync process.
    workedAccountsStore.purgeOldAccountsWorkedToday();
  }, 20 * 60 * 1000);

  useEffect(() => {
    // Subscribe to the event when the component is mounted
    completedAccountsHub?.on("AccountWorkCompletedForTheDay", incidentId => {
      workedAccountsStore.addWorkedTodayAccount(incidentId);
    });

    // Return a cleanup function to unsubscribe from the event when the component is unmounted
    return () => {
      completedAccountsHub?.off("AccountWorkCompletedForTheDay");
    };
  }, [completedAccountsHub]);

  if (!accounts.length && inProgress === InteractionStatus.None) {
    return (<> </>);
  }

  if (accounts.length && featureFlagsLoaded && !compass2Enabled) {
    return (<>User is not authorized to use this application.</>);
  }

  if (tabStatus === "" && checkingTab && loading) {
    return (
      <Alert
        show
        title="Warning"
        message="This application is designed to run only in a single browser window/tab"
        buttons={[]}
      />
    );
  }

  return (
    <AuthenticatedTemplate>
      <ErrorBoundary>
        <iframe
          src={import.meta.env.VITE_LEGACY_COMPASS_URL}
          style={{ display: "none" }}
          title="compass-1-iframe"
          id="compass-1-iframe"
          allow="clipboard-read; clipboard-write; display-capture"
        />
        <Header />
        <VersionMessage />
        <span style={{ viewTransitionName: "app-content" }}>
          <Outlet />
        </span>
        <Footer />
        <ErrorsAlert />
      </ErrorBoundary>
    </AuthenticatedTemplate>
  );
};

export default Authorized;
