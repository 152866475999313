import type React from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { styled } from "@mui/material";
import { useDebounceValue } from "usehooks-ts";

// Styled Component used for the Toast Header
const HeadingContainer = styled("p")(() => ({
  marginBottom: 0
}));

// Styled Component used for the body Content
const ContentContainer = styled("p")(() => ({
  marginTop: 0
}));

const MessageTemplate: React.FC = () => {
  const time = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  }).format(Date.now());

  return (
    <>
      <HeadingContainer>No Internet</HeadingContainer>
      <ContentContainer>{time}</ContentContainer>
    </>
  );
};

const NetworkOutage: React.FC = () => {
  // Wait for 1 second before rendering the toast.
  const [toastMessage, showToastMessage] = useDebounceValue(false, 1000);

  // The below method is used to configure and display the toast component
  const showToastErrorDialog = (): void => {
    toast.error(<MessageTemplate />, {
      position: toast.POSITION.TOP_RIGHT,
      closeButton: false,
      draggable: false,
      autoClose: false,
      closeOnClick: false,
      className: "toast-network-outage",
      icon: <ErrorOutlineIcon />
    });
  };

  useEffect(() => {
    // Show toast message if the internet is offline
    window.addEventListener("offline", () => {
      showToastMessage(true);
    });

    // Hide toast message if the Internet is available
    window.addEventListener("online", () => {
      showToastMessage(false);
    });
  }, []);

  // Effect to monitor the debounce time of 1 second before showing or hiding the toast,
  useEffect(() => {
    if (toastMessage) {
      showToastErrorDialog();
    } else {
      toast.dismiss();
    }
  }, [toastMessage]);

  // Render Component
  return (
    <ToastContainer />
  );
};

export default NetworkOutage;
