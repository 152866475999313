import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { type Dayjs } from "dayjs";

interface Props {
  label: string;
  date?: string;
  value: Dayjs | null;
  variant?: "outlined" | "filled" | "standard";
  onChange: (value: Dayjs | null) => void;
}

const MuiDatePicker = ({ label, date, value, variant, onChange }: Props): JSX.Element => {
  const muiTheme = useTheme();

  return (
    <DatePicker
      views={date === "year" ? ["year", "month"] : ["year", "month", "day"]}
      label={label}
      value={value}
      onChange={onChange}
      slotProps={{
        textField: {
          variant,
          sx: {
            "& .MuiIconButton-root": {
              color: muiTheme.palette.info.main
            },
            "& .MuiInputBase-root:not(.Mui-selected)": {
              padding: 0
            }
          }
        },
        desktopPaper: {
          sx: {
            "& .MuiDayPicker-weekDayLabel": {
              color: muiTheme.palette.text.primary
            },
            "& .MuiIconButton-root": {
              color: muiTheme.palette.info.main
            }
          }
        }
      }}
    />
  );
};

MuiDatePicker.defaultProps = {
  date: null,
  variant: undefined
};

export default MuiDatePicker;
