import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import filterMenu from "@logic/menu.logic";
import { type MenuItem } from "@models/menu-item.model";
import { type UserRole } from "@custom-types/user-role.type";
import { getMenu } from "@services/menu.service";
import useMenuStore from "@store/menu.store";
import useSessionStore from "@store/session.store";

const useLoadMenuStore = (): void => {
  const store = useMenuStore();
  const { status, error, data } = useQuery({ queryKey: ["menu"], queryFn: getMenu });
  const storeRef = useRef(store);
  const { session } = useSessionStore();
  const { user } = session;
  const { roles } = user ?? { roles: [] as UserRole[] };
  const [featureFlags] = useState([]); // TODO: Load menu feature flags.

  useEffect(() => {
    storeRef.current.setIsLoading?.(status === "pending");
  }, [status]);

  useEffect(() => {
    storeRef.current.setError?.(error);
  }, [error]);

  useEffect(() => {
    const menu = data ?? [] as MenuItem[];
    const filteredMenu = filterMenu(menu, roles, featureFlags);
    storeRef.current.load!(filteredMenu);
  }, [data, roles, featureFlags]);
};

export default useLoadMenuStore;
