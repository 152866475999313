import { useEffect, useCallback, type ChangeEvent } from "react";
import { HubConnectionState } from "@microsoft/signalr";
import { Virtuoso, type ListItem } from "react-virtuoso";
import { Box, Paper, TextField, styled, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { motion, AnimatePresence, type AnimationProps } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { v4 as uuidv4 } from "uuid";
import { type RuleGroupType } from "react-querybuilder";
import delay from "delay";
import ViewContent from "@components/view-content/view-content.component";
import useStoreErrorsHook from "@hooks/store-errors.hook";
import Alert from "@components/alert/alert.component";
import useSearchStore from "@store/search.store";
import useViewStore from "@store/view.store";
import ViewSort from "@components/view-sort/view-sort.component";
import type SortType from "@enums/sort-type.enum";
import { type AlertButton } from "@models/alert-button.model";
import { type View } from "@models/view.model";
import { type Sort } from "@models/sort.model";
import { type ViewSearchResult } from "@models/view-search-result.model";
import useBeforeRedirect from "@hooks/use-prompt.hook";
import useAddStore from "@hooks/add-store.hook";
import useLoadSingleStore from "@hooks/load-single-store.hook";
import useLoadInfiniteStore from "@hooks/load-infinite-store.hook";
import { addView, getView, updateView } from "@services/view.service";
import { getSearchResultsById, getSearchResultsByModel } from "@services/search.service";
import useUpdateStore from "@hooks/update-store.hook";
import useSessionStore from "@store/session.store";
import useGlobalSearchStore from "@store/global-search.store";
import { getServiceLineIcon } from "@logic/global-search.logic";
import useHubConnection from "@hooks/use-hub-connection.hook";
import startViewTransition from "@utils/view-transition.util";
import ActionType from "@enums/action-type.enum";
import { isQueryValid } from "@logic/query-builder.logic";
import { preProcessFilterForEdit, isViewFormValid } from "@logic/view.logic";
import defaultSort from "@data/sort-default.data";
import defaultFilter from "@data/query-builder-default.data";
import defaultShare from "@data/share-default.data";
import { type Shared } from "@models/shared.model";
import { type ShareValueType } from "@models/share-value-type.model";
import { getCreateViewModel, getUpdateViewModel } from "@utils/view.util";
import LoadingLeftPlaceholder from "@pages/home-view/loading-left-placeholder/loading-left-placeholder.component";
import LoadingContentPlaceholder from "@pages/home-view/loading-content-placeholder/loading-content-placeholder.component";
import ConfirmationDialog from "@components/confirmation-dialog/confirm-dialog.component";
import CardContentContainer from "./card-content-container/card-content-container.component";
import LeftPanelCardDetail from "./left-panel-card-detail/left-panel-card-detail.component";
import ScrollSeekPlaceholder from "../home-view/scroll-seek-placeholder/scroll-seek-placeholder.component";
import ListHeader from "../home-view/list-header/list-header.component";

const ACCOUNTS_HUB_URL = `${import.meta.env.VITE_WORK_QUEUE_API_URL}/notifications`;

const BlueCreatePaper = styled(Paper)<AnimationProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  borderColor: theme.palette.secondary.light,
  borderStyle: "solid",
  borderWidth: "0",
  padding: "0.75rem 1.0rem",
  borderRadius: "0.5rem"
}));

const MuiViewNameTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light
}));

const AccountDetails = styled(BlueCreatePaper)<AnimationProps>(() => ({
  marginBottom: "0.5rem",
  borderRadius: "0.5rem 0 0 0.5rem"
}));

const ListBox = styled(Box)(() => ({
  height: "calc(100vh - 12.25rem)"
})) as typeof Box;

const variants = {
  open: {
    marginRight: "-1.125rem",
    borderRadius: "0.25rem 0 0 0.25rem"
  },
  closed: {
    marginRight: "1rem",
    borderRadius: "0.25rem"
  }
};

const ViewDetail = (): JSX.Element => {
  // Hooks
  const navigate = useNavigate();
  const workedAccountsHub = useHubConnection(ACCOUNTS_HUB_URL);
  const { session: { user } } = useSessionStore();
  const viewStore = useViewStore();
  const { workedAccounts, data, totalCount, totalBillCount, workedTodayAccounts, ...searchStore } = useSearchStore();
  const { errors, clearErrors } = useStoreErrorsHook();
  const { currentAccount, setCurrentAccount } = useGlobalSearchStore();

  // Constants
  const currentUserId = user?.id ?? "";
  const { id } = useParams<string>();
  const { views, isLoading } = viewStore;
  const currentView = views[id ?? ""];
  const isViewCreator = currentView?.created?.userId === currentUserId;

  // Search & Account cards related fields
  const [selected, setSelected] = useImmer<ViewSearchResult | null>(null);
  const [selectedIndex, setSelectedIndex] = useImmer<number>(0);
  const [showSelected, setShowSelected] = useImmer(false);
  const [scrollingUpwards, setScrollingUpwards] = useImmer(false);
  const [showDoubleWorkAlert, setShowDoubleWorkAlert] = useImmer(false);
  const [endReached, setEndReached] = useImmer(false);
  const [disableSaveButton, setDisableSaveButton] = useImmer(false);

  // Actions
  const [localIsLoading, setLocalIsLoading] = useImmer<boolean>(false);
  const [actionType, setActionType] = useImmer<ActionType | null>(null);
  const [touched, setTouched] = useImmer(false);

  // Views related fields
  const [viewName, setViewName] = useImmer<string>(currentView?.name ?? "");
  const [sort, setSort] = useImmer<Sort>(currentView?.sort ?? defaultSort);
  const [moreOptions, setMoreOptions] = useImmer(Boolean(currentView?.moreOptions ?? false));
  const [share, setShare] = useImmer<ShareValueType[]>(defaultShare);
  const [viewId, setViewId] = useImmer<string | null>(null);

  // If the filter is valid, it will also be used to dynamic load the accounts on create/edit actions
  const [filter, setFilter] = useImmer<RuleGroupType>(defaultFilter);

  // Errors related
  const [showErrors, setShowErrors] = useImmer(false);
  const [viewNameBEErrors, setViewNameBEErrors] = useImmer<string[]>([]);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useImmer(false);

  const resetErrors = (): void => {
    setShowErrors(false);
    setViewNameBEErrors([]);
    clearErrors();
  };

  const resetViewFieldsToDefaultValue = (): void => {
    resetErrors();
    setViewName("");
    setSort(defaultSort);
    setMoreOptions(false);
    setFilter(defaultFilter);
    setShare(defaultShare);
    setDisableSaveButton(false);
  };

  const handleClickBack = (): void => {
    navigate("/");

    if (!touched) {
      resetViewFieldsToDefaultValue();
    }
  };

  const handleViewAdded = async (): Promise<void> => {
    setTouched(false);
    await delay(100); // Allow some time for touched to be set to false before navigating. Otherwise, the prompt will show up.

    if (viewId) {
      navigate(`/view/${viewId}`);
    } else {
      navigate("/");
    }

    resetViewFieldsToDefaultValue();
  };

  const handleViewUpdated = (): void => {
    setActionType(ActionType.View);

    if (!touched) {
      resetViewFieldsToDefaultValue();
    }
  };

  // Store mutations
  const addViewFn = useAddStore(
    ["views"],
    async view => addView(view),
    viewStore,
    () => startViewTransition(async () => {
      await handleViewAdded();
    })
  );

  const updateViewFn = useUpdateStore(
    ["views"],
    async view => updateView(view),
    viewStore,
    () => startViewTransition(() => handleViewUpdated())
  );

  useBeforeRedirect("Discard unsaved changes?", touched);

  const handleSetTouched = useCallback((): void => {
    if (!touched) {
      setTouched(true);
    }
  }, [touched, setTouched]);

  const sendMessage = useCallback((messageName: string, messageBody: string) => {
    if (!workedAccountsHub || workedAccountsHub?.state !== HubConnectionState.Connected) {
      return;
    }

    void workedAccountsHub.send(messageName, messageBody);
  }, [workedAccountsHub]);

  const resetSelectedAccount = useCallback(() => {
    if (selected?.incidentId) {
      sendMessage("StoppedWorkingAccount", selected?.incidentId);
    }

    // Accounts Cards updates
    setSelected(null);
    setSelectedIndex(0);
    setShowSelected(false);
    setScrollingUpwards(false);
  }, [selected, sendMessage, setSelected, setSelectedIndex, setShowSelected, setScrollingUpwards]);

  const handleChangeViewName = (e: ChangeEvent<HTMLInputElement>): void => {
    setViewName(e.target.value);
    handleSetTouched();
  };

  const handleChangeSort = (sortColumn: SortType, isDescendent: boolean): void => {
    searchStore.reset!();
    resetSelectedAccount();
    setSort({ sortColumn, isDescendent });

    if (actionType === ActionType.Edit) {
      handleSetTouched();
    }
  };

  const handleChangeFilter = (newFilter: RuleGroupType): void => {
    setFilter(newFilter);
    handleSetTouched();

    // Edge case for create/edit filter
    // Clear the loaded accounts for all the filter changes
    // If the filter is valid, the accounts will be loaded
    // This will prevent confusion for loaded results without a valid filter
    searchStore.reset!();
  };

  const handleSubmitView = (isCopy: boolean): void => {
    const name = viewName.trim();
    const shared = share
      .filter(s => s?.value?.length > 0)
      .map(s => ({ userId: s.value, userName: s.label })) as Shared[];
    const viewData = { shared, filter, isCopy, moreOptions };
    if (isViewFormValid(name, viewData)) {
      setTouched(false);
      setDisableSaveButton(true);
      resetErrors();

      if (actionType === ActionType.Create || isCopy || !isViewCreator) {
        const view = getCreateViewModel(name, viewData, sort);
        setViewId(view.id);
        // Commented in PR !4278 - incomplete logic in both validateView and error handling
        // const error = validateView(view);
        // if (error) {
        //   // TODO: Handle error
        //   return;
        // }

        addViewFn(view);
      } else {
        const view = getUpdateViewModel(name, currentView, viewData, sort);
        // Commented in PR !4278 - incomplete logic in both validateView and error handling
        // const error = validateView(view);
        // if (error) {
        //   // TODO: Handle error
        //   return;
        // }

        updateViewFn(view);
      }
    } else {
      setTouched(true);
      setShowErrors(true);
      setDisableSaveButton(false);
    }
  };

  // View => Edit
  const handleEnableEditMode = async (): Promise<void> => {
    setLocalIsLoading(true);
    setActionType(ActionType.Edit);
    resetSelectedAccount();
    resetErrors();

    // Views related fields
    setMoreOptions(Boolean(currentView?.moreOptions ?? false));

    // Sort can be edited while in "View" mode => reset any possible changes
    if (currentView?.sort) {
      setSort({ ...currentView.sort });
    }

    // On edit, the existing shared users will be marked as readonly
    if (currentView?.shared && currentView.shared.length > 0) {
      const viewShared = currentView.shared.map((s): ShareValueType => (
        { key: uuidv4(), value: s.userId, label: s.userName, readonly: true }));
      setShare(viewShared);
    }

    // The filter needs some processing to match the react-query-builder library format requirements
    if (currentView?.filter) {
      const processedFilterForEdit = await preProcessFilterForEdit(currentView?.filter);
      setFilter(processedFilterForEdit);
    }

    setViewName(currentView?.name ?? "");
    setTouched(false);
    setLocalIsLoading(false);
  };

  const cancelEdit = (): void => {
    setActionType(ActionType.View);

    // Reset any possible changes made on visible view fields (Edit => View)
    resetErrors();
    setViewName(currentView?.name ?? "");

    // Sort is also used in "View" mode => reset any possible changes
    const newSort = currentView?.sort ?? defaultSort;
    handleChangeSort(newSort.sortColumn, newSort.isDescendent);
    setTouched(false);
  };

  // Edit => View / Create => Home page navigation
  const handleCancelEditOrCreateView = (): void => {
    if (actionType === ActionType.Create) {
      handleClickBack();
    } else {
      if (actionType === ActionType.Edit && touched) {
        setShowUnsavedChangesModal(true);
        return;
      }

      cancelEdit();
    }
  };

  const handleConfirm = (): void => {
    setShowUnsavedChangesModal(false);
    cancelEdit();
  };
  // Left Cards + Search Logic
  const getSingleSearchResults = useCallback(async ({ pageParam }: { pageParam: unknown }): Promise<ViewSearchResult[]> => {
    const take = 10;
    let skip = 0;
    if (pageParam && !Number.isNaN(pageParam)) {
      skip = pageParam as number * 10;
    }

    if (actionType === ActionType.View) {
      return getSearchResultsById(id ?? "", take, skip, sort);
    }

    if ((actionType === ActionType.Create || actionType === ActionType.Edit)
      && filter !== null && filter !== undefined && isQueryValid(filter)) {
      searchStore.reset!();
      return getSearchResultsByModel(filter, take, skip, sort);
    }

    return Promise.resolve([]);
  }, [id, sort, filter, searchStore, actionType]);

  const handleClose = (): void => setShowDoubleWorkAlert(false);

  const buttons: AlertButton[] = [{
    text: "OK",
    onClick: () => handleClose()
  }];

  const addWorkedAccount = (incidentId: string): void => {
    searchStore.addWorkedAccount(incidentId);
  };

  const addWorkedAccounts = (incidentIds: string[]): void => {
    if (selected?.incidentId && incidentIds.includes(selected.incidentId)) {
      setShowDoubleWorkAlert(true);
    }

    searchStore.addWorkedAccounts(incidentIds);
  };

  workedAccountsHub?.off("AnalystStartedWorkingAccount");
  workedAccountsHub?.on("AnalystStartedWorkingAccount", (incidentId: string) => {
    addWorkedAccount(incidentId);
  });

  workedAccountsHub?.off("IsAnalystWorkingAccount");
  workedAccountsHub?.on("IsAnalystWorkingAccount", (incidentId: string) => {
    if (selected?.incidentId === incidentId) {
      void workedAccountsHub?.send("StartedWorkingAccount", selected?.incidentId);
    }
  });

  workedAccountsHub?.off("AnalystStoppedWorkingAccount");
  workedAccountsHub?.on("AnalystStoppedWorkingAccount", (incidentId: string) => {
    searchStore.removeWorkedAccount(incidentId);
  });

  workedAccountsHub?.off("AccountWorkedToday");
  workedAccountsHub?.on("AccountWorkedToday", (incidentId: string) => {
    searchStore.setAccountWorkedToday(incidentId);
  });

  workedAccountsHub?.off("AnalystsWorkingAccounts");
  workedAccountsHub?.on("AnalystsWorkingAccounts", (accounts: string) => {
    addWorkedAccounts(JSON.parse(accounts) as string[]);
  });

  workedAccountsHub?.off("IsRequestingAnalystsWorkingAccounts");
  workedAccountsHub?.on("IsRequestingAnalystsWorkingAccounts", (requesterConnectionId: string) => {
    if ((workedAccounts.length > 0 || selected?.incidentId) && requesterConnectionId !== workedAccountsHub.connectionId) {
      const accounts = selected?.incidentId ? [...workedAccounts, selected.incidentId] : workedAccounts;
      void workedAccountsHub?.send("SendAnalystsWorkingAccounts", JSON.stringify(accounts), requesterConnectionId);
    }
  });

  const fetchNextPage = useLoadInfiniteStore(["search", id, sort, filter, actionType], getSingleSearchResults, searchStore);

  const onEndReached = (): void => {
    const workedTodayAccountsLength = workedTodayAccounts.length;
    const numberOfAccounts = data.length + workedTodayAccountsLength;
    if (numberOfAccounts < totalCount) {
      void fetchNextPage();
    } else if (workedTodayAccountsLength > 0) {
      setEndReached(true);
    }
  };

  const onHandleSelect = (index: number, account: ViewSearchResult): void => {
    if (actionType !== ActionType.View) {
      return;
    }

    if (selected?.incidentId === account.incidentId) {
      return;
    }

    if (selected?.incidentId) {
      sendMessage("StoppedWorkingAccount", selected?.incidentId);
    }

    sendMessage("StartedWorkingAccount", account.incidentId);
    setShowDoubleWorkAlert(workedAccounts.includes(account.incidentId));
    setSelected(account);
    setShowSelected(false);
    setSelectedIndex(index);
    setCurrentAccount(null);
  };

  const onItemsRendered = (items: Array<ListItem<ViewSearchResult>>): void => {
    if (!selected?.incidentId) {
      return;
    }

    const showCurrentAccount = items
      .filter(x => !!x?.data?.incidentId)
      .map(x => x.data?.incidentId)
      .includes(selected?.incidentId);
    const firstItemIndex = items
      .filter(x => !!x?.index)
      .map(x => x.index)[0] ?? 0;

    setScrollingUpwards(selectedIndex < firstItemIndex);
    setShowSelected(!showCurrentAccount);
  };

  const renderFooter = (): JSX.Element | null => {
    if ((data.length + workedTodayAccounts.length) < totalCount) {
      return <LoadingLeftPlaceholder />;
    }

    return null;
  };

  const computeKey = (_: unknown, item: ViewSearchResult): string => item?.incidentId;

  const displayViewSearchResultDetails = (index: number, item: ViewSearchResult): JSX.Element => (
    <LeftPanelCardDetail
      index={index}
      key={item?.incidentId}
      workedOn={workedAccounts.includes(item.incidentId)}
      item={item}
      selected={selected?.incidentId ?? ""}
      id={item?.incidentId}
      onHandleSelect={() => onHandleSelect(index, item)}
      component={motion.div}
      initial={{ opacity: 0, translateX: -50 }}
      animate={{ opacity: 1, translateX: 0 }}
      transition={{ ease: "easeInOut", duration: 1 }}
    />
  );

  // This will never be null but there is a bug in the react router types.
  const getSingleView = async (): Promise<View> => getView(id ?? "");
  useLoadSingleStore(["views", id], getSingleView, viewStore);

  // Clear store before loading new data.
  useEffect(() => searchStore.reset!(), []);

  useEffect(() => {
    if (!id) {
      setActionType(ActionType.Create);
    } else {
      setActionType(ActionType.View);
    }
  }, [id, setActionType]);

  useEffect(() => {
    if (actionType === ActionType.View) {
      return;
    }

    if (viewNameBEErrors.length === 0) {
      const validationErrors = errors
        .filter(x => x.type === "validation")
        .map(x => x?.problemDetails?.errors);

      if (validationErrors.length > 0) {
        const nameValidationErrors = validationErrors
          .filter(x => Object.keys(x ?? {}).some(y => y === "Name"))
          .map(x => x?.Name);

        if (nameValidationErrors.length > 0) {
          setViewNameBEErrors(nameValidationErrors[0] ?? []);
          handleSetTouched();
          setShowErrors(true);
          setDisableSaveButton(false);
        }
      }
    }
  }, [errors, viewNameBEErrors, setShowErrors, setViewNameBEErrors, handleSetTouched, actionType, setDisableSaveButton]);

  // Reset current account on page unload.
  useEffect(() => () => setCurrentAccount(null), [setCurrentAccount]);

  useEffect(() => {
    if (currentAccount?.data?.incidentId) {
      setSelected(null);
      setSelectedIndex(0);
      setShowSelected(false);
      setScrollingUpwards(false);
    }
  }, [currentAccount, setScrollingUpwards, setSelected, setSelectedIndex, setShowSelected]);

  useEffect(() => {
    if (!selected && !currentAccount?.data && actionType === ActionType.View) {
      setSelected(data[0] ?? null);
    }
  }, [actionType, data, selected, currentAccount, setSelected]);

  useEffect(() => (() => {
    void workedAccountsHub?.stop();
  }), [workedAccountsHub]);

  return (
    <>
      <AnimatePresence>
        <Box
          sx={{
            display: "flex",
            margin: actionType !== ActionType.View ? "4rem 3.125rem 0 2rem" : "4rem 2rem 0 2rem",
            alignItems: "flex-start"
          }}
        >
          <Alert
            show={showDoubleWorkAlert}
            title="Someone else is working this account"
            message="To prevent double touching this account please come back later"
            buttons={buttons}
            handleClose={handleClose}
          />
          <Box sx={{ margin: "1rem 0 0 0" }}>
            {currentAccount && (
              <AccountDetails
                component={motion.div}
                initial={{ y: "-100%", opacity: 0, scale: 1 }}
                exit={{ y: "-100%" }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                <Box alignItems="center" display="flex">
                  <img
                    src={getServiceLineIcon(currentAccount?.data?.serviceLine ?? "")}
                    alt={currentAccount?.data?.serviceLine}
                    style={{ width: "2rem", height: "2rem", filter: "invert(1)" }}
                  />
                  <Box marginLeft={1}>
                    <Typography variant="body2" className="pendo-ignore" data-dd-privacy="mask">
                      {currentAccount?.data?.dbnum}
                    </Typography>
                    <Typography variant="caption" className="pendo-ignore" data-dd-privacy="mask">
                      {currentAccount?.data?.patientName}
                    </Typography>
                  </Box>
                </Box>
              </AccountDetails>
            )}
            <BlueCreatePaper
              component={motion.div}
              variants={variants}
              animate={actionType !== ActionType.View ? "open" : "closed"}
              transition={{ duration: 0.4 }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <IconButton size="large" aria-label="go back" color="inherit" onClick={handleClickBack}>
                  <ArrowBackIcon />
                </IconButton>

                <Box sx={{ width: "13rem", viewTransitionName: "view-detail" }}>
                  {actionType === ActionType.View
                    ? (
                      <Typography variant="body2" color="textSecondary">
                        {currentView?.name}
                      </Typography>
                    )
                    : (
                      <>
                        <MuiViewNameTextField
                          id="view-name"
                          type="text"
                          value={viewName}
                          onChange={handleChangeViewName}
                          size="small"
                          inputProps={{ maxLength: 30 }}
                          className={(touched && !viewName) || viewNameBEErrors?.length > 0 ? "has-error" : ""}
                          placeholder="Enter Name..."
                        />
                        {
                          viewNameBEErrors?.length > 0 && viewNameBEErrors.map(viewNameBEError => (
                            <Typography key={viewNameBEError} variant="body2" className="form-error">
                              {viewNameBEError}
                            </Typography>
                          ))
                        }
                      </>
                    )}
                </Box>

                <ViewSort sortValue={sort} handleChangeSort={handleChangeSort} />

                {
                  actionType === ActionType.View && (
                    <IconButton size="large" aria-label="edit" color="inherit" onClick={handleEnableEditMode}>
                      <EditIcon />
                    </IconButton>
                  )
                }
              </Box>

              <Typography variant="caption" color="textSecondary" sx={{ marginLeft: "3rem" }}>
                {totalCount.toLocaleString()} Accounts / {totalBillCount.toLocaleString()} Bills
              </Typography>
            </BlueCreatePaper>

            <ListBox>
              <ListHeader
                selectedItem={selected}
                show={showSelected && scrollingUpwards}
              />
              <Virtuoso
                className="react-virtuoso"
                data={endReached ? [...data ?? [], ...workedTodayAccounts ?? []] : data}
                totalCount={totalCount}
                computeItemKey={computeKey}
                itemContent={displayViewSearchResultDetails}
                endReached={onEndReached}
                style={{ height: showSelected || currentAccount ? "87%" : "98%", marginTop: "1rem", direction: "rtl" }}
                components={{
                  Footer: renderFooter,
                  ScrollSeekPlaceholder
                }}
                itemsRendered={onItemsRendered}
                context={{
                  hasMoreItems: (data.length + workedTodayAccounts.length) < totalCount || data.length === 0,
                  selectedItem: selected
                }}
                scrollSeekConfiguration={{
                  enter: velocity => Math.abs(velocity) > 700,
                  exit: velocity => Math.abs(velocity) < 10
                }}
              />
              <ListHeader
                selectedItem={selected}
                show={showSelected && !scrollingUpwards}
              />
            </ListBox>
          </Box>

          {actionType === ActionType.View
            ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {(isLoading ?? localIsLoading) ? <LoadingContentPlaceholder /> : (
                  <CardContentContainer
                    incidentId={selected?.incidentId ?? currentAccount?.data?.incidentId ?? ""}
                    workedOn={workedAccounts.includes(selected?.incidentId ?? "")}
                  />
                )}
              </>
            )
            : (
              <ViewContent
                onSubmit={handleSubmitView}
                onCancel={handleCancelEditOrCreateView}
                moreOptions={moreOptions}
                setMoreOptions={setMoreOptions}
                filter={filter}
                handleChangeFilter={handleChangeFilter}
                share={share}
                setShare={setShare}
                handleSetTouched={handleSetTouched}
                isEdit={actionType === ActionType.Edit}
                isViewCreator={actionType === ActionType.Edit ? isViewCreator : true}
                showErrors={showErrors}
                disableSaveButton={disableSaveButton}
              />
            )}
        </Box>
      </AnimatePresence>
      <ConfirmationDialog
        title="Discard unsaved changes?"
        message="If you continue, any unsaved changes will be lost."
        open={showUnsavedChangesModal}
        cancel={() => setShowUnsavedChangesModal(false)}
        confirm={handleConfirm}
      />
    </>
  );
};

export default ViewDetail;
