import { produce } from "immer";
import { type BaseEntity } from "@models/base-entity.model";

const updateListItemProperty = <T, TValue>(list: BaseEntity[], id: string, property: string, value: TValue): T[] => {
  const updatedList = produce(list, draft => {
    const index = draft.findIndex(x => x.id === id);

    if (index !== -1) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (draft[index] as any)[property] = value;
    }
  });
  return updatedList as T[];
};

const updateListItem = <T, TValue>(list: BaseEntity[], id: string, value: TValue): T[] => {
  const updatedList = produce(list, draft => {
    const index = draft.findIndex(x => x.id === id);

    if (index !== -1) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (draft[index] as any) = value;
    }
  });
  return updatedList as T[];
};

const addItemToList = <T>(list: T[], item: T): T[] => {
  const newList = item ? [...list, item] : list;
  return newList;
};

const removeItemFromList = <T>(list: BaseEntity[], id: string): T[] => {
  const updatedList = list.filter(x => x.id !== id) as T[];
  return updatedList;
};

export { updateListItemProperty, updateListItem, addItemToList, removeItemFromList };
