import { useState, useRef, type ReactNode, type SyntheticEvent } from "react";
import Button, { type ButtonProps } from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";

const options = ["Save as a Copy"];

interface SaveButtonProps extends ButtonProps {
  children: ReactNode;
  onClick: (event: SyntheticEvent | boolean) => void;
  isEdit: boolean;
  isViewCreator: boolean;
  disabled?: boolean;
}

const SaveButton = ({ children, onClick, isEdit, isViewCreator, disabled, ...props }: SaveButtonProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event): void => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup ref={anchorRef} aria-label="split button">
        <Button
          {...props}
          sx={{
            padding: `0.5rem ${isEdit && isViewCreator ? "0.75rem" : "2rem"} 0.5rem 2rem`,
            marginRight: "1px"
          }}
          onClick={() => onClick(!isViewCreator)}
          disabled={disabled}
        >
          {children}
        </Button>
        {
          isEdit && isViewCreator && (
            <Button
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              {...props}
              onClick={handleToggle}
              sx={{
                padding: "0.5rem 0.5rem 0.5rem 0",
                boxShadow: "none"
              }}
              disabled={disabled}
            >
              <ArrowDropDownIcon />
            </Button>
          )
        }
      </ButtonGroup>

      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <div>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, idx) => (
                    <Button key={idx} {...props} onClick={() => onClick(true)} disabled={disabled}>{option}</Button>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SaveButton;

SaveButton.defaultProps = {
  disabled: false
};
