import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const FooterWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "fixed",
  bottom: 0,
  borderTop: `0.0625rem solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.primary.dark,
  padding: "0.5rem"
}));

const Footer = (): JSX.Element => (
  <FooterWrapper>
    <Typography variant="body2" align="right" sx={{ color: "white" }}>
      Last Update - {new Date().toLocaleDateString()}
    </Typography>
  </FooterWrapper>
);

export default Footer;
