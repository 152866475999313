import { type ChangeEvent } from "react";
import {
  Radio, RadioGroup, Typography, FormControlLabel,
  FormControl, FormLabel, Divider, styled
} from "@mui/material";
import type SortType from "@enums/sort-type.enum";

const MuiFormControl = styled(FormControl)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  width: "14rem",
  padding: "0 1rem"
}));

const MuiFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: "medium",
  "&.Mui-focused": {
    color: theme.palette.primary.light
  }
}));

const MuiDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.light,
  border: "0.1rem solid",
  width: "100%",
  margin: "0.2rem 0"
}));

const MuiRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.primary.light,
  "&.Mui-checked": {
    color: theme.palette.primary.light
  }
}));

export interface SortRadioFormProps {
  label: string;
  name: string;
  ascOption: string;
  descOption: string;
  value: string | null;
  handleChangeSort: (sortColumn: SortType, isDescendent: boolean) => void;
}

const SortRadioForm = ({
  label, name, ascOption, descOption, value, handleChangeSort
}: SortRadioFormProps): JSX.Element => {
  const onChangeSortValue = (event: ChangeEvent<HTMLInputElement>): void => {
    const sortColumn = (event.target as HTMLInputElement).name;
    const newValue = (event.target as HTMLInputElement).value;

    handleChangeSort(sortColumn as SortType, newValue === descOption);
  };

  return (
    <MuiFormControl>
      <MuiFormLabel>
        <MuiDivider />
        {label}
      </MuiFormLabel>

      <RadioGroup
        row
        name={name}
        value={value}
        onChange={onChangeSortValue}
      >
        <FormControlLabel
          sx={{ width: "50%" }}
          value={ascOption}
          control={(<MuiRadio />)}
          label={<Typography sx={{ fontSize: "small" }}>{ascOption}</Typography>}
        />
        <FormControlLabel
          value={descOption}
          control={(<MuiRadio />)}
          label={<Typography sx={{ fontSize: "small" }}>{descOption}</Typography>}
        />
      </RadioGroup>
    </MuiFormControl>
  );
};

export default SortRadioForm;
