import type * as React from "react";
import { type DraftFunction, useImmer } from "use-immer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import Fade from "@mui/material/Fade";
import GlobalSearchDropdown from "@components/global-search/global-search.component";
import {
  StyledMenu,
  TopBar
} from "@components/generic/generic.component";
import MenuItemTag from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { type MenuItem } from "@models/menu-item.model";
import useLoadMenuStore from "@hooks/load-menu-store.hook";
import useMenuStore from "@store/menu.store";
import DynamicNavigationMenu from "@components/dynamic-navigation-menu/dynamic-navigation-menu.component";
import AccountInfo from "./account-info/account-info.component";

const Header = (): JSX.Element => {
  useLoadMenuStore();

  const menuStore = useMenuStore();
  const { menu } = menuStore;
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useImmer<null | HTMLElement>(null);

  const [selectedItem, setSelectedItem] = useImmer<null | MenuItem>(null);

  const [isMenuOpen, setIsMenuOpen] = useImmer<null | number>(null);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, max-len
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: MenuItem, i: number | DraftFunction<number | null> | null) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);

    if (isMenuOpen === i) {
      setIsMenuOpen(null);
    } else {
      setIsMenuOpen(i);
    }
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setIsMenuOpen(null);
    setSelectedItem(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <TopBar>
        <Toolbar sx={{ gap: "1rem" }} variant="dense" className="menu">
          <Box>
            <MenuItemTag onClick={() => navigate("")}>
              <Typography
                variant="body2"
                component="span"
                sx={{ flexGrow: 1, fontWeight: 200 }}
              >
                Aspirion {" "}
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontWeight: 400 }}
                >
                  Compass
                </Typography>
              </Typography>
            </MenuItemTag>
          </Box>
          <GlobalSearchDropdown />
          {menu.map((el, index) => (
            <Button
              key={index}
              color="inherit"
              size="small"
              endIcon={isMenuOpen === index ? <ExpandLess /> : <ExpandMore />}
              onClick={e => handleClick(e, el, index)}
            >
              {el.title?.toUpperCase()}
            </Button>
          ))}
          <StyledMenu
            onClose={handleClose}
            anchorEl={anchorEl}
            TransitionComponent={Fade}
            open={Boolean(anchorEl)}
          >
            <DynamicNavigationMenu menuItems={selectedItem?.items ?? []} onClose={() => handleClose()} />
          </StyledMenu>
          <AccountInfo />
        </Toolbar>
      </TopBar>
    </Box>
  );
};

export default Header;
