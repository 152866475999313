import { get } from "@utils/api.util";

const getBuildVersion = async (): Promise<string> => {
  const url = "/static/version.txt";
  const headers = {
    pragma: "no-cache",
    "cache-control": "no-cache"
  };
  const buildVersion = (await get<string>(url, headers)).trim();
  return buildVersion;
};

export default getBuildVersion;
