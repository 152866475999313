import type React from "react";
import { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, IconButton, Typography, Button } from "@mui/material";
import { AsyncPaginate } from "react-select-async-paginate";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ArrowDropUp } from "@mui/icons-material";
import type { MultiValue } from "react-select";
import { v4 as uuidv4 } from "uuid";
import { type ViewContentShareProps } from "@models/view-content-share-props.model";
import { BoxContainer, BoxHeader } from "@components/generic/generic.component";
import { getServiceLinesForShare, getUsersForShare } from "@services/field.service";
import AsyncPaginatedSelectStyles from "@utils/async-paginated-select-styles.util";
import { type ShareValueType } from "@models/share-value-type.model";
import { type FieldOption } from "@models/field-option.model";
import { type RenderDropdown } from "@models/render-dropdown.model";

const renderDropdown: React.FC<RenderDropdown> = ({ isFocused }) => (isFocused ? <ArrowDropUp /> : <ArrowDropDownIcon />);

const ViewContentShare = ({ share, handleSelectShare, handleSelectServiceLine,
  handleShareMore, handleRemoveShare }: ViewContentShareProps): JSX.Element => {
  const [resettableCache, setResettableCache] = useState<string>(uuidv4());
  const customStyles = AsyncPaginatedSelectStyles();
  const customStylesForDisabled = AsyncPaginatedSelectStyles(true);

  const handleLocalSelectServiceLine = (item: ShareValueType, newValue: FieldOption): void => {
    setResettableCache(uuidv4());
    handleSelectServiceLine(item?.key, newValue);
  };

  const handleLocalSelectShare = (item: ShareValueType, newValue: ShareValueType): void => {
    setResettableCache(uuidv4());
    handleSelectShare(item, newValue);
  };

  const handleLocalRemoveShare = (item: ShareValueType): void => {
    setResettableCache(uuidv4());
    handleRemoveShare(item);
  };

  return (
    <>
      <BoxHeader alignItems="center" display="flex" justifyContent="space-between">
        <Typography variant="body2">
          Share
        </Typography>
      </BoxHeader>
      {
        share.map((item, idx) => (
          <BoxContainer key={item.key}>
            {!item?.readonly
              && (
                <Box
                  sx={{
                    margin: 1
                  }}
                >
                  <AsyncPaginate
                    additional={{ page: 0 }}
                    debounceTimeout={300}
                    placeholder="Select Service"
                    isClearable={idx === 0 && share?.length === 1}
                    onChange={(newValue: MultiValue<FieldOption>) => {
                      handleLocalSelectServiceLine(item, (Array.isArray(newValue) ? newValue[0] : newValue) as FieldOption);
                    }}
                    value={item?.serviceLine ? { value: item.serviceLine?.value, label: item.serviceLine?.label } : null}
                    loadOptions={
                      async (search: string) => (
                        getServiceLinesForShare({ url: "serviceLine/share", search })
                      )
                    }
                    styles={customStyles}
                    components={{
                      DropdownIndicator: renderDropdown,
                      IndicatorSeparator: null
                    }}
                  />
                </Box>
              )}
            <Box
              sx={{
                margin: 1
              }}
            >
              <AsyncPaginate
                cacheUniqs={[resettableCache]}
                additional={{ page: 0 }}
                debounceTimeout={300}
                placeholder={item?.serviceLine ? "Select User" : "Select Service First"}
                isDisabled={item?.readonly === true || !item?.serviceLine}
                isClearable={!item?.readonly && idx === 0 && share?.length === 1}
                onChange={(newValue: MultiValue<FieldOption>) => {
                  handleLocalSelectShare(item, (Array.isArray(newValue) ? newValue[0] : newValue) as ShareValueType);
                }}
                value={item.value ? { value: item.value, label: item.label } : null}
                loadOptions={
                  async (search: string, _prevOptions, additional?: { page: number; }) => (
                    getUsersForShare(
                      {
                        url: "user/share",
                        share,
                        search,
                        page: additional?.page ?? 1,
                        serviceLineId: item?.serviceLine?.value ?? ""
                      }
                    )
                  )
                }
                styles={item?.readonly === true || !item?.serviceLine ? customStylesForDisabled : customStyles}
                components={{
                  DropdownIndicator: renderDropdown,
                  IndicatorSeparator: null
                }}
              />
            </Box>

            {
              (idx > 0 && !item?.readonly) && (
                <IconButton
                  sx={{ marginLeft: "auto" }}
                  size="large"
                  aria-label="clear"
                  color="inherit"
                  onClick={() => handleLocalRemoveShare(item)}
                >
                  <HighlightOffIcon />
                </IconButton>
              )
            }
          </BoxContainer>
        ))
      }
      <Box width="15rem" mt={2}>
        <Button variant="contained" onClick={handleShareMore}>
          Share More
        </Button>
      </Box>
    </>
  );
};

export default ViewContentShare;
