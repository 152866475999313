import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { type BaseStore } from "@models/base-store.model";
import { type View } from "@models/view.model";
import { getError } from "@utils/error.util";

type ViewsContainer = Record<string, View>;

interface Store extends BaseStore<View> {
  views: ViewsContainer;
}

const useViewStore = create<Store>()(
  devtools(
    (set): Store => ({
      views: {},

      setIsLoading: isLoading => {
        set(state => ({ ...state, isLoading }), false, "setIsLoading");
      },

      setError: err => {
        const error = getError(err);
        set(state => ({ ...state, error }), false, "setError");
      },

      loadSingle: view => {
        set(state => ({ ...state, views: { ...state.views, [view.id]: view } }), false, "loadSingle");
      },

      add: view => {
        set(state => ({ ...state, views: { ...state.views, [view.id]: view } }), false, "add");
      },

      update: view => {
        set(state => ({ ...state, views: { ...state.views, [view.id]: view } }), false, "update");
      }
    }),
    { name: "View" }
  )
);

export default useViewStore;
