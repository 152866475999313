import type React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItemTag from "@mui/material/MenuItem";
import { StyledUl } from "@components/generic/generic.component";
import { type MenuItem } from "@models/menu-item.model";

const MenuListItem: React.FC<MenuItem> = props => {
  const handleClick = (url: string | undefined): void => {
    if (url !== undefined) {
      window.open(url);

      props?.onClose?.();
    }
  };

  if (!props?.title) {
    return null;
  }

  return (
    <MenuItemTag onClick={() => handleClick(props?.url)}>
      {props?.items && props?.items.length > 0 ? (
        <Box>
          <Typography
            variant="body2"
            color={theme => theme.palette.grey[100]}
            sx={{ fontWeight: 700 }}
          >
            <b>{props?.title}</b>
          </Typography>
          {props?.items.map((item, i) => (
            <StyledUl key={i}>
              <MenuItemTag onClick={() => handleClick(item?.url)}>
                <Typography
                  variant="body2"
                >
                  {item?.title}
                </Typography>
              </MenuItemTag>
            </StyledUl>
          ))}
        </Box>
      ) : (
        <Typography
          variant="body2"
          color={theme => theme.palette.grey[100]}
        >
          {props?.title}
        </Typography>
      )}
    </MenuItemTag>
  );
};

export default MenuListItem;
