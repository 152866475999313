import { Box, Button, styled, Typography } from "@mui/material";
import usePatchStore from "@hooks/patch-store.hook";
import { type PatchVariables } from "@models/patch-variables.model";
import { type BasicView } from "@models/basic-view.model";
import { acceptView, getViews, removeView, undoRemoveView } from "@services/view.service";
import useBasicViewStore from "@store/basic-view.store";
import { type ViewAction } from "@custom-types/view-action.type";
import { type ViewStatus } from "@custom-types/view-status.type";
import { type BaseEntity } from "@models/base-entity.model";
import useLoadStore from "@hooks/load-store.hook";
import useSessionStore from "@store/session.store";
import LeftPanelCard from "./left-panel-card/left-panel-card.component";
import LeftPanelLoader from "./left-panel-loader/left-panel-loader.component";

interface Params extends BaseEntity {
  userId: string;
  preventRefresh: boolean;
}

const LeftPanelContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  borderColor: theme.palette.secondary.light,
  borderStyle: "solid",
  borderWidth: "0.0625rem",
  justifyContent: "center",
  width: "24rem",
  margin: "1rem 1rem 0.675rem 0",
  padding: "1.5rem 1.25rem",
  borderRadius: "0.625rem"
}));

const LeftPanel = (): JSX.Element => {
  const store = useBasicViewStore();

  const { session: { user } } = useSessionStore();
  const userId = user?.id ?? "";

  useLoadStore(["views"], getViews, store);
  const { views, isLoading } = store;

  const acceptViewFn = usePatchStore<BasicView, Params, ViewStatus>(
    ["views"],
    async patchVariables => acceptView(patchVariables.params.id),
    store
  );
  const removeViewFn = usePatchStore<BasicView, Params, ViewStatus>(
    ["views"],
    async patchVariables => removeView(patchVariables.params.id),
    store
  );
  const undoRemoveViewFn = usePatchStore<BasicView, Params, ViewStatus>(
    ["views"],
    async patchVariables => undoRemoveView(patchVariables.params.id),
    store
  );

  const onChangeStatus = (id: string, action: ViewAction): void => {
    const patchVariables: PatchVariables<Params, ViewStatus> = {
      params: {
        id,
        userId,
        preventRefresh: false
      },
      patch: {
        id,
        property: "status",
        value: action === "remove" ? "removed" : "approved"
      },
      doNotInvalidateQuery: action === "remove"
    };

    switch (action) {
      case "accept":
        acceptViewFn(patchVariables);
        break;
      case "remove":
        removeViewFn(patchVariables);
        break;
      case "undo-remove":
        undoRemoveViewFn(patchVariables);
        break;
    }
  };

  return (
    <LeftPanelContainer>
      <Box alignItems="center" display="flex" justifyContent="space-between" mt="0.625rem" mb="0.625rem">
        <Typography variant="body2" color="textSecondary">
          My Views
        </Typography>
        <Button variant="outlined">
          <Typography variant="caption">
            ADD
          </Typography>
        </Button>
      </Box>
      {isLoading && views.length === 0
        ? <LeftPanelLoader itemsCount={views.length} />
        : (
          <>
            {
              views.length > 0 && (
                <Box sx={{ maxHeight: "calc(100% - 4.4rem)", overflow: "auto" }}>
                  {
                    views.map((view: BasicView) => (
                      <LeftPanelCard key={view.id} view={view} onChangeStatus={onChangeStatus} />
                    ))
                  }
                </Box>
              )
            }
            {
              views.length === 0 && (
                <Box>
                  <Typography variant="body1" color="textSecondary" sx={{ width: "80%" }}>
                    Your home page has no views. Click the ADD button above to create a view
                  </Typography>
                </Box>
              )
            }
          </>
        )}
    </LeftPanelContainer>
  );
};

export default LeftPanel;
