import { type NavigateFunction } from "react-router-dom";
import MenuItemType from "@enums/menu-item-type.enum";
import { type MenuItem } from "@models/menu-item.model";
import { type MenuLocation } from "@custom-types/menu-location.type";
import applyIntervalLogic from "./interval.util";

let mouseTimer: ReturnType<typeof setInterval>;

const sortMenuItems = (menuItems: MenuItem[]): MenuItem[] => {
  const rankSorted = [...menuItems].sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0));
  const titleSorted = [...rankSorted].sort((a, b) => {
    if (a.rank !== b.rank) {
      return 0;
    }

    const aTitle = a.title ?? "";
    const bTitle = b.title ?? "";

    if (aTitle < bTitle) {
      return -1;
    }

    if (aTitle > bTitle) {
      return 1;
    }

    return 0;
  });
  return titleSorted;
};

const getSubmenu = (index: number, filter?: string): HTMLElement => {
  const el = document.querySelectorAll(`[role=presentation] .MuiPopover-paper${filter ?? ""}`)[index] as HTMLElement;
  return el;
};

const adjustNestedSubmenuPosition = (el: HTMLElement): void => {
  applyIntervalLogic(() => {
    const parent = getSubmenu(0);
    if (!parent) {
      return false;
    }

    const { left } = parent.getBoundingClientRect();
    const right = window.innerWidth - left;

    el.style.right = `${right}px`;
    el.style.removeProperty("left");
    el.style.display = "block";
    return false; // We want to execute this through all attempts cause the left attribute seems to get set several times by MUI.
  }, 200, 5);
};

const moveSubmenu = (): void => {
  // The data attribute will allow us to keep track if the submenu has already been moved or not.
  const dataKey = "data-moved";

  applyIntervalLogic(() => {
    const el = getSubmenu(1, `:not([${dataKey}])`);
    if (!el) {
      return false;
    }

    el.style.display = "none";

    adjustNestedSubmenuPosition(el);

    el.setAttribute(dataKey, "");
    return true;
  }, 10, 100);

  // Failsafe: Sometimes the left attribute remains, so this should take care of those incidents.
  mouseTimer = setInterval(() => {
    const el = getSubmenu(1);
    el?.style.removeProperty("left");
  }, 200);
};

const handleSubmenuMouseLeave = (): void => {
  clearInterval(mouseTimer);
};

const styleMenuItem = (li: HTMLLIElement): void => {
  li.style.justifyContent = "flex-start";

  const arrow = li.querySelector("svg");
  if (arrow) {
    // Flip arrow and move it to the left.
    arrow.style.transform = "rotate(180deg)";
    li.insertBefore(arrow, li.firstChild);
  } else {
    // Align with the items that have a submenu.
    li.style.marginLeft = "1.5rem";
  }
};

const adjustSubmenuPosition = (initialWidth: number): void => {
  const el = getSubmenu(0);
  const { width } = el.getBoundingClientRect();

  // After flipping the arrows and giving a margin left to the remaining menu items the width may change.
  // The following will calculate that difference and move the submenu to the left accordingly.
  const margin = width - initialWidth;
  el.style.marginLeft = `-${margin}px`;

  // Move the submenu upon hover (needed when it hovers cause it doesn't render before that).
  el.querySelectorAll("li").forEach(li => {
    const hasSubmenu = li.querySelector("svg");
    if (!hasSubmenu) {
      return;
    }

    li.addEventListener("mouseenter", () => moveSubmenu());
    li.addEventListener("mouseleave", () => handleSubmenuMouseLeave());
  });
};

const styleMenuItems = (): void => {
  applyIntervalLogic(() => {
    const el = getSubmenu(0);
    if (!el) {
      return false;
    }

    const hasSubmenus = el.querySelectorAll("svg")?.length;
    if (!hasSubmenus) {
      return true;
    }

    // Get initial width (before styling menu items).
    const { width } = el.getBoundingClientRect();

    el.querySelectorAll("li").forEach(li => styleMenuItem(li));

    adjustSubmenuPosition(width);

    return true;
  }, 10, 100);
};

const handleRightMenu = (): void => {
  // Let's first hide the submenu so it doesn't flicker while we style the menu items.
  const interval = 10;
  const attempts = 20;
  applyIntervalLogic(() => {
    const el = getSubmenu(0);
    if (!el) {
      return false;
    }

    el.style.visibility = "hidden";
    styleMenuItems();
    return true;
  }, interval, attempts);

  // Now, let's show it.
  const delay = interval * attempts;
  setTimeout(() => {
    const el = getSubmenu(0);
    el.style.visibility = "visible";
  }, delay);
};

const menuCallback = (menuItem: MenuItem, navigate: NavigateFunction, location: MenuLocation): void => {
  if (!menuItem.url) {
    if (location === "right") {
      // The menu library only renders the submenu to the right. The following logic will re-arrange it to show on the left.
      handleRightMenu();
    }

    return;
  }

  if (menuItem.type === MenuItemType.Page) {
    navigate(menuItem.url);
    return;
  }

  if (menuItem.type === MenuItemType.Tab) {
    window.open(menuItem.url);
    // return; TODO: Put back once the TODO below gets addressed.
  }

  // TODO: Handle other menu types.
};

export { sortMenuItems, menuCallback };
