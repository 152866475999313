const startViewTransition = (fn: VoidFunction): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const doc = document as any;
  if (!doc.startViewTransition) {
    fn();
    return;
  }

  doc.startViewTransition(() => fn());
};

export default startViewTransition;
