import type React from "react";
import { useEffect, useState, useCallback } from "react";
import {
  Box, Button, Menu, MenuItem, Typography,
  Skeleton, Link, styled, Badge, type BadgeProps
} from "@mui/material";
import Table from "@components/table/table";
import sortOptions from "@components/view-sort/sort-options";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useBasicViewStore from "@store/basic-view.store";
import useSessionStore from "@store/session.store";
import { acceptView, getView, getViews, getViewsByIds, removeView, undoRemoveView } from "@services/view.service";
import useLoadStore from "@hooks/load-store.hook";
import { type BasicView } from "@models/basic-view.model";
import defaultSort from "@data/sort-default.data";
import useWorkedAccountsStore from "@store/worked-accounts.store";
import { type ViewAction } from "@custom-types/view-action.type";
import { type ViewStatus } from "@custom-types/view-status.type";
import { type BaseEntity } from "@models/base-entity.model";
import { type Sort } from "@models/sort.model";
import { type SortOptionsType } from "@models/sort-options-type.model";
import { getFullSearchResultsById } from "@services/search.service";
import { type ViewSearchResult } from "@models/view-search-result.model";
import ViewDrawer from "@components/view-drawer/view-drawer.component";
import usePatchStore from "@hooks/patch-store.hook";
import { type PatchVariables } from "@models/patch-variables.model";
import { updateLastBucketId, getLastSelectedBucketId } from "@services/user.service";
import { useNavigate } from "react-router-dom";
import BucketDrawerForm from "@components/bucket-drawer-form/bucket-drawer-form.component";
import useHubConnection from "@hooks/use-hub-connection.hook";
import useViewStore from "@store/view.store";
import { type View } from "@models/view.model";
import useLoadSingleStore from "@hooks/load-single-store.hook";

interface Params extends BaseEntity {
  userId: string;
  preventRefresh: boolean;
}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    backgroundColor: "black"
  }
}));

const pageSize = 25;

type Data = Record<string, string>;

const ACCOUNTS_HUB_URL = `${import.meta.env.VITE_WORK_QUEUE_API_URL}/notifications`;

const getSortBy = (sort: Sort): string => {
  if (!sort) {
    return "";
  }

  const key = Object.keys(sortOptions).find(k => k.toLocaleLowerCase() === sort.sortColumn.toLocaleLowerCase());

  if (!key) {
    return "";
  }

  const obj = sortOptions[key as keyof SortOptionsType];

  return `${obj.label} ${sort.isDescendent ? obj.descOption : obj.ascOption}`;
};

const BucketsHome = (): JSX.Element => {
  const basicStore = useBasicViewStore();
  const navigate = useNavigate();

  useLoadStore(["views"], getViews, basicStore);
  const { views: buckets, isLoading: bucketsLoading } = basicStore;
  const activeBuckets = [...new Set(buckets)].filter(bucket => bucket?.status !== "removed");
  const newlySyncedSharedBuckets = activeBuckets
    .filter(bucket => bucket.fromSync === Boolean(true) || bucket.accepted === Boolean(false));
  const { session: { user } } = useSessionStore();
  const userId = user?.id ?? "";

  const [selectedView, setSelectedView] = useState<BasicView>();
  const [deletedSelectedView, setDeletedSelectedView] = useState<BasicView>();
  const { workedTodayAccounts } = useWorkedAccountsStore();
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<ViewSearchResult[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const [bucketState, setBucketState] = useState({
    accounts: -1,
    bills: -1,
    totalValue: -1,
    lastUpdated: "",
    totalCount: 0,
    sort: getSortBy(defaultSort)
  });

  const [drawerFlag, setDrawerFlag] = useState<boolean>(false);
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [previewFlag, setPreviewFlag] = useState<boolean>(false);
  const [viewName, setViewName] = useState<string>("");
  const [displayUndelete, setDisplayUndelete] = useState<boolean>(false);
  const [viewsToRetrieve, setViewsToRetrieve] = useState<string[]>([]);
  const isLoading = previewLoading || bucketsLoading;
  const workedAccountsHub = useHubConnection(ACCOUNTS_HUB_URL);
  const viewStore = useViewStore();
  const { views } = viewStore;
  const [currentView, setCurrentView] = useState<View | undefined>(undefined);

  useEffect(() => {
    if (isEdit) {
      return;
    }

    setCurrentView(views[selectedView?.id ?? ""]);
  }, [selectedView?.id, views, isEdit]);

  useEffect(() => {
    if (sessionStorage.getItem("previewView")) {
      const previewView: any = JSON.parse(sessionStorage.getItem("previewView") ?? "");
      setIsEdit(previewView?.isEdit ?? false);
      handleClose();
      setDrawerFlag(true);
      setPreviewFlag(true);
      setCurrentView(previewView?.currentView);

      const openOnce = sessionStorage.getItem("openDrawerOnce");
      if (openOnce === "true") {
        setDrawerFlag(true);
      }
    }
  }, []);

  const isViewCreator = currentView?.created?.userId === userId;

  // This will never be null but there is a bug in the react router types.
  const getSingleView = async (): Promise<View> => getView(selectedView?.id ?? "");
  useLoadSingleStore(["views", selectedView?.id], getSingleView, viewStore);

  const fetchLastBucketId = useCallback(async (): Promise<string> => {
    setSearchLoading(true);
    const lastBucketId = await getLastSelectedBucketId();
    if (lastBucketId && buckets?.length > 0) {
      localStorage.setItem("lastBucketId", lastBucketId);
    }

    setSearchLoading(false);
    return lastBucketId;
  }, [buckets?.length]);

  const fetchAndSetDetailView = async (bucketId: string): Promise<void> => {
    if (previewFlag && !bucketId) {
      return;
    }

    let view = currentView;
    // edge case when the current view is currently being loaded
    if (bucketId) {
      view = await getView(bucketId);
    }

    try {
      setSearchLoading(true);
      const searchResult = await getFullSearchResultsById(bucketId, pageSize, 0, view?.sort ?? defaultSort, true);
      setSearchResults(searchResult.data ?? []);
      setBucketState({
        accounts: searchResult.totalCount ?? 0,
        bills: searchResult?.totalBillCount ?? 0,
        totalCount: searchResult.totalCount ?? 0,
        sort: getSortBy(view?.sort ?? defaultSort),
        totalValue: 0,
        lastUpdated: "yyyy-mm-dd"
      });
    } catch (error) {
      console.error("Failed to fetch detail view", error);
    }

    setSearchLoading(false);
  };

  useEffect(() => {
    const handleBucketSelection = async (): Promise<void> => {
      let lastBucketId = localStorage.getItem("lastBucketId") ?? selectedView?.id;
      if (!lastBucketId) {
        lastBucketId = await fetchLastBucketId();
      }

      if (selectedView?.id !== undefined && deletedSelectedView?.id !== undefined) {
        setDisplayUndelete(selectedView?.id === deletedSelectedView?.id);
      }

      // select the last selected bucket or the first in the list
      if (!bucketsLoading && buckets?.length > 0) {
        const acceptedBuckets = buckets.filter(bucket => bucket.accepted);
        const viewToSelect = buckets.find(x => x.id === lastBucketId) ?? acceptedBuckets[0];
        if (viewToSelect) {
          setSelectedView(viewToSelect);
          await fetchAndSetDetailView(viewToSelect.id);
          await updateLastSelectedBucket(viewToSelect.id);
        }
      }
    };
    void handleBucketSelection();
  }, [bucketsLoading, buckets, selectedView?.id, fetchLastBucketId, deletedSelectedView?.id]);

  const navigateToBucketAccountPage = (accountId: string | undefined): void => {
    if (!(accountId)) {
      return;
    }

    const index = searchResults.findIndex(temp => temp.incidentId === accountId);
    navigate(`/account/${accountId}`, {
      state: {
        index,
        bucketId: selectedView?.id ? selectedView?.id : "",
        disableNextButton: index + 1 === bucketState.totalCount
      }
    });
  };

  const columns = [
    {
      key: "incidentId",
      label: "Compass ID",
      renderer: (value: Data): JSX.Element => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => navigateToBucketAccountPage(value.incidentId)}
        >
          {value.dbnum}
        </Link>
      )
    },
    {
      key: "followUp",
      label: "Follow-Up date"
    },
    {
      key: "patientName",
      label: "Name",
      renderer: (value: Data): JSX.Element => (
        <Typography>
          {`${value.patientLastName ?? ""}${value.patientLastName ? ", " : ""}
          ${value.patientFirstName ?? ""}`}
        </Typography>
      )
    },
    {
      key: "facility",
      label: "Facility",
      width: 30
    },
    {
      key: "contract",
      label: "Contract"
    },
    {
      key: "serviceLine",
      label: "Service line"
    }
  ];

  const filterWorkedAccountsFromResults = useCallback((results: ViewSearchResult[])
    : { filteredResults: ViewSearchResult[], accountsRemovedCount: number, filteredOutBillCount: number } => {
    const filteredResults: ViewSearchResult[] = [];
    let filteredOutBillCount = 0;

    results.forEach(account => {
      if (workedTodayAccounts.some(workedAccount => workedAccount.incidentId === account.incidentId)) {
        filteredOutBillCount += account.bills?.length ?? 0;
        return;
      }

      filteredResults.push(account);
    });

    return {
      filteredResults,
      accountsRemovedCount: results.length - filteredResults.length,
      filteredOutBillCount
    };
  }, [workedTodayAccounts]);

  const updateResultsAndCount = useCallback((newResults: ViewSearchResult[], oldResults?: ViewSearchResult[]): void => {
    const { filteredResults, accountsRemovedCount, filteredOutBillCount } = filterWorkedAccountsFromResults(newResults);
    const mergedFilteredResults = oldResults
      ? [...oldResults, ...filteredResults]
      : filteredResults;

    setSearchResults(mergedFilteredResults);
    setBucketState({
      ...bucketState,
      accounts: bucketState.accounts - accountsRemovedCount,
      bills: bucketState.bills - filteredOutBillCount
    });
  }, [bucketState, filterWorkedAccountsFromResults]);

  const fetchNextPage = async (index: number): Promise<void> => {
    if (!selectedView?.id) {
      return;
    }

    // setSearchLoading(true);
    // updateResultsAndCount([...current, loading(pageSize)], searchResults);
    // instead of using pageSize * page, we use the current length of the search results
    // because we constantly remove workedAccounts from the results so we end up with numbers
    // that aren't multiples of the pagesize
    const newData = await getFullSearchResultsById(selectedView.id, pageSize, index, currentView?.sort ?? defaultSort, true);
    updateResultsAndCount(newData.data, searchResults);
    // setSearchLoading(false);
  };

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      updateResultsAndCount(searchResults);
    }
  }, [workedTodayAccounts]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);

    if (!newOpen && basicStore) {
      const viewsWithDisabledAnimations = buckets.map(bucket => ({ ...bucket, fromSync: false }));
      basicStore.load?.(viewsWithDisabledAnimations);
    }
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleCreateNewBucket = (): void => {
    setIsEdit(false);
    handleClose();
    setDrawerFlag(true);
  };

  const handleEditBucket = (): void => {
    if (!selectedView?.id) {
      return;
    }

    setIsEdit(true);
    handleClose();
    setDrawerFlag(true);
  };

  const handleSubmit = useCallback(async (): Promise<void> => {
    setDrawerFlag(false);
    setIsEdit(false);
    await handleResetPreview(true);
  }, [setDrawerFlag, setIsEdit, setPreviewFlag]);

  const handleResetPreview = async (prvFlag: boolean): Promise<void> => {
    if (prvFlag) {
      setPreviewFlag(false);
      setBucketState({
        accounts: -1,
        bills: -1,
        totalValue: -1,
        lastUpdated: "",
        totalCount: 0,
        sort: getSortBy(defaultSort)
      });
    }

    const previousBucketId = localStorage.getItem("lastBucketId");
    if (selectedView?.id) {
      await fetchAndSetDetailView(selectedView?.id);
    } else if (previousBucketId) {
      await fetchAndSetDetailView(previousBucketId);
      await updateLastSelectedBucket(previousBucketId);
    }

    sessionStorage.removeItem("previewView");
  };

  const handleCloseDrawer = useCallback(async (): Promise<void> => {
    setDrawerFlag(false);
    setIsEdit(false);
    await handleResetPreview(true);
  }, [setDrawerFlag, setIsEdit, setPreviewFlag]);

  const handlePreview = (data: ViewSearchResult[], totalCount: number, totalBillCount: number, name: string): void => {
    setPreviewFlag(true);
    setPreviewLoading(true);

    setTimeout(() => {
      setPreviewLoading(false);
      setSearchLoading(false);
    }, 500);
    setSearchResults(data);
    setViewName(name);
    setBucketState({
      accounts: totalCount,
      bills: totalBillCount,
      totalValue: 0,
      lastUpdated: "yyyy-mm-dd",
      totalCount: 0,
      sort: getSortBy(defaultSort)
    });
  };

  const acceptViewFn = usePatchStore<BasicView, Params, ViewStatus>(
    ["views"],
    async patchVariables => acceptView(patchVariables.params.id),
    basicStore
  );

  const removeViewFn = usePatchStore<BasicView, Params, ViewStatus>(
    ["views"],
    async patchVariables => removeView(patchVariables.params.id),
    basicStore
  );
  const undoRemoveViewFn = usePatchStore<BasicView, Params, ViewStatus>(
    ["views"],
    async patchVariables => undoRemoveView(patchVariables.params.id),
    basicStore
  );

  const onChangeStatus = (id: string, action: ViewAction): void => {
    const patchVariables: PatchVariables<Params, ViewStatus> = {
      params: {
        id,
        userId,
        preventRefresh: false
      },
      patch: {
        id,
        property: "status",
        value: action === "remove" ? "removed" : "approved"
      },
      doNotInvalidateQuery: action === "remove"
    };

    switch (action) {
      case "accept":
        acceptViewFn(patchVariables);
        break;
      case "remove":
        removeViewFn(patchVariables);
        break;
      case "undo-remove":
        undoRemoveViewFn(patchVariables);
        break;
    }
  };

  const updateLastSelectedBucket = async (bucketId: string): Promise<void> => {
    await updateLastBucketId(bucketId);
    localStorage.setItem("lastBucketId", bucketId);
  };

  const handleDrawerItemClick = async (basicView: BasicView): Promise<void> => {
    await updateLastSelectedBucket(basicView.id);
    setSelectedView(basicView);
    setDrawerOpen(false);

    if (basicView.accepted === Boolean(false)) {
      // accept shared view
      onChangeStatus(basicView.id, "accept");
    }
  };

  const handleDelete = async (): Promise<void> => {
    setAnchorEl(null);

    if (!selectedView?.id) {
      return;
    }

    const removeBucket = async (): Promise<void> => {
      setPreviewLoading(true);
      setSearchLoading(true);
      onChangeStatus(selectedView.id, "remove");

      setSearchResults([]);
      setDisplayUndelete(true);
      setPreviewLoading(false);
      setSearchLoading(false);
      setDeletedSelectedView(selectedView);
      setSelectedView(undefined);
    };

    await removeBucket();
  };

  const handleUndelete = async (): Promise<void> => {
    setAnchorEl(null);

    if (!deletedSelectedView?.id) {
      return;
    }

    const undoRemoveBucket = async (): Promise<void> => {
      setPreviewLoading(true);
      onChangeStatus(deletedSelectedView?.id, "undo-remove");

      setDisplayUndelete(false);
      setPreviewLoading(false);
      setSelectedView(deletedSelectedView);
      setDeletedSelectedView(undefined);
    };

    await undoRemoveBucket();
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const resultBuckets = await getViewsByIds(viewsToRetrieve);
      const mappedBucketArray = resultBuckets.map(bucket => ({ ...bucket, fromSync: true }));
      basicStore.addViews(mappedBucketArray);
    };

    if (viewsToRetrieve.length > 0) {
      void fetchData().then(() => setViewsToRetrieve([]));
    }
  }, [viewsToRetrieve, basicStore]);

  workedAccountsHub?.off("AnalystSharedBucket");
  workedAccountsHub?.on("AnalystSharedBucket", (bucketId: string, userIds: string) => {
    if (userIds.includes(userId) && !buckets.some(bucket => bucket.id === bucketId)) {
      setViewsToRetrieve([...viewsToRetrieve, bucketId]);
    }
  });

  const disableEditBucket = displayUndelete || selectedView?.id === undefined || previewFlag;
  const disableDeletedBucket = displayUndelete
    || selectedView?.id === undefined || selectedView?.type === "ServiceLine" || previewFlag;

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      maxHeight: "89vh",
      color: "rgba(0, 0, 0, 0.87)"
    }}
    >
      {/* Top Section (Welcome/Bucket Name, Bucket Stats, Other Actions, Select Bucket Button) */}
      <Box sx={{ display: "flex", justifyContent: "end", mb: 0, backgroundColor: "white", padding: "1rem 1rem 0rem 1rem" }}>
        {/* Welcome/Bucket Name & Bucket Stats */}
        <Box>
          {/* Welcome/Bucket Name section */}
          <Typography
            variant="h2"
            sx={{ mb: (!bucketsLoading && !selectedView?.name) ? 5 : 1, color: "inherit", fontWeight: "bold" }}
          >
            {
              isLoading
                ? <Skeleton sx={{ width: "20rem", backgroundColor: "rgba(0, 0, 0, 0.25)" }} />
                : <>{previewFlag ? viewName : selectedView?.name ?? "Welcome"} </>
            }
          </Typography>

          {/* Bucket Stats section (x accounts, y bills)) */}
          <Typography variant="subtitle1" sx={{ mb: 2, color: "inherit" }}>
            {
              isLoading
                ? <Skeleton sx={{ width: "30rem", backgroundColor: "rgba(0, 0, 0, 0.25)" }} />
                : (
                  <div>
                    {
                      bucketState.accounts > -1
                      && bucketState.bills > -1
                      && `${bucketState.accounts} accounts, ${bucketState.bills} bills. Sorted by ${bucketState.sort}.`
                    }
                  </div>
                )
            }
          </Typography>
        </Box>

        {/* This will push the buttons to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Other Actions & Select Bucket Button */}
        <Box sx={{ paddingTop: "10px" }}>
          <Button
            endIcon={<ArrowDropDownIcon />}
            aria-controls="other-actions-menu"
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            sx={{ borderRadius: "0", color: "white", backgroundColor: "#4A5578" }}
          >
            OTHER ACTIONS
          </Button>

          <Menu
            id="other-actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            sx={{ "& .MuiMenu-paper": { backgroundColor: "white", width: "13.45rem" } }}
          >
            <MenuItem onClick={handleCreateNewBucket} sx={{ color: "black", backgroundColor: "white" }}>Create new bucket</MenuItem>
            <MenuItem
              onClick={handleEditBucket}
              disabled={disableEditBucket}
              sx={{ color: "black", backgroundColor: "white" }}
            >
              Edit this bucket
            </MenuItem>
            <MenuItem onClick={handleDelete} disabled={disableDeletedBucket} sx={{ color: "red" }}>Delete this bucket</MenuItem>
          </Menu>

          <StyledBadge badgeContent={newlySyncedSharedBuckets.length} color="primary">
            <Button
              variant="contained"
              onClick={toggleDrawer(true)}
              sx={{ borderRadius: "0", color: "white" }}
            >
              SELECT BUCKET
            </Button>
          </StyledBadge>

          {/* Select Bucket Drawer */}
          <ViewDrawer
            selectedViewId={selectedView?.id}
            isLoading={Boolean(bucketsLoading)}
            basicViews={activeBuckets}
            open={drawerOpen}
            toggleDrawer={toggleDrawer}
            onItemClick={handleDrawerItemClick}
          />
        </Box>
      </Box>

      {/* No Bucket Selected Message */}
      {
        !displayUndelete && !bucketsLoading && !selectedView?.name && !previewFlag && (
          <Box sx={{ mb: 0, paddingTop: 0, marginTop: 0, backgroundColor: "white", padding: "1rem 1rem 0rem 1rem" }}>
            <Typography variant="subtitle1" sx={{ mb: 2, color: "inherit" }}>
              We don’t have a previous bucket to display.
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 0, color: "inherit" }}>
              Select a bucket or create a new one.
            </Typography>
            <Button
              variant="text"
              onClick={handleCreateNewBucket}
              sx={{
                paddingLeft: 1.5,
                paddingRight: 0,
                color: "#1570ef",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                },
                "&.MuiButtonBase-root:click": {
                  bgcolor: "transparent"
                }
              }}
            >
              Create bucket
            </Button>
            <Button
              variant="text"
              onClick={toggleDrawer(true)}
              sx={{
                paddingLeft: 1.5,
                paddingRight: 0,
                color: "#1570ef",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                },
                "&.MuiButtonBase-root:click": {
                  bgcolor: "transparent"
                }
              }}
            >
              Select bucket
            </Button>
          </Box>
        )
      }

      {/* Undelete Bucket Message */}
      {
        displayUndelete && deletedSelectedView?.id === selectedView?.id && (
          <Box sx={{ mb: 0, paddingTop: 0, marginTop: 0, backgroundColor: "white", padding: "1rem 1rem 0rem 1rem" }}>
            <Typography variant="subtitle1" sx={{ mb: 2, color: "inherit" }}>
              This bucket was deleted. Any shared users retain their copies.
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 0, color: "inherit" }}>
              Select another bucket, create a new one, or undelete it.
            </Typography>
            <Button
              variant="text"
              color="error"
              onClick={handleUndelete}
              sx={{
                ml: "2rem",
                mt: "1rem",
                borderRadius: 3,
                color: "red",
                backgroundColor: "",
                ":hover": { backgroundColor: "#eddede" }
              }}
            >
              UNDELETE BUCKET
            </Button>
          </Box>
        )
      }

      {/* Table (each row represents an account) */}
      {
        !displayUndelete && selectedView?.id && !previewFlag && ((searchLoading) || bucketState.totalCount > 0) ? (
          <Table
            isLoading={(searchLoading || previewLoading)}
            data={previewLoading || searchLoading ? [] : searchResults}
            columns={columns}
            fetchNextPage={fetchNextPage}
            totalCount={bucketState.totalCount}
          />
        ) : !displayUndelete && selectedView?.id && !previewFlag && (

          <Box sx={{ mb: 0, paddingTop: 0, marginTop: 0, backgroundColor: "white", padding: "1rem 1rem 0rem 1rem" }}>
            <Typography variant="subtitle1" sx={{ mb: 2, color: "inherit", fontWeight: "bold" }}>
              No accounts found.
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 0, color: "inherit", paddingBottom: "20px" }}>
              Please select another bucket or create a new one.
            </Typography>
          </Box>
        )
      }
      {previewFlag
        && (
          <Table
            isLoading={(searchLoading || previewLoading)}
            data={previewLoading || searchLoading ? [] : searchResults}
            columns={columns}
            fetchNextPage={fetchNextPage}
            totalCount={bucketState.totalCount}
          />
        )}

      {/* Create and Edit Bucket Drawer */}
      <BucketDrawerForm
        open={drawerFlag}
        onPreview={handlePreview}
        onClose={handleCloseDrawer}
        title={isEdit ? "Edit bucket" : "Create bucket"}
        handleSubmit={handleSubmit}
        signalRHub={workedAccountsHub}
        isViewCreator={isViewCreator}
        currentView={currentView}
        views={buckets}
        isEdit={isEdit}
        fetchAndSetDetailView={fetchAndSetDetailView}
      />
    </Box>
  );
};

export default BucketsHome;
