import { z } from "zod";
import { type RuleGroupType } from "react-querybuilder";
import { ViewStatusSchema } from "@custom-types/view-status.type";
import { BaseEntitySchema } from "./base-entity.model";
import { SharedSchema } from "./shared.model";
import { SortSchema } from "./sort.model";

const ValueSchema = z.object({
  id: z.string()
});

const LabelValueSchema = z.object({
  label: z.string(),
  value: z.string()
});

const BaseRuleSchema = z.object({
  combinator: z.string().optional(),
  field: z.string().optional(),
  operator: z.string().optional(),
  // value: z.union([z.string(), ValueSchema, z.array(ValueSchema).nonempty()]).optional(), // One way to specify multiple types
  value: z.string()
    .or(z.boolean())
    .or(ValueSchema)
    .or(LabelValueSchema)
    .or(z.array(ValueSchema).nonempty())
    .or(z.array(LabelValueSchema).nonempty())
    .optional(), // Another way to specify multiple types
  not: z.boolean().default(false)
});

const RuleSchema2 = BaseRuleSchema.extend({
  rules: z.array(BaseRuleSchema).optional()
});

const RuleSchema1 = BaseRuleSchema.extend({
  rules: z.array(RuleSchema2).optional()
});

export const ViewSchema = BaseEntitySchema.extend({
  name: z.string().nonempty(),
  moreOptions: z.boolean().optional(),
  filter: z.object({
    combinator: z.string().nonempty(),
    not: z.boolean().default(false),
    rules: z.array(RuleSchema1).nonempty()
  }),
  sort: SortSchema.optional(),
  created: z.object({
    userId: z.string().optional(),
    createdOn: z.string().optional(),
    deletedOn: z.string().optional().nullable(),
    editedOn: z.string().optional().nullable(),
    userName: z.string().optional().nullable()
  }).optional(),
  status: ViewStatusSchema.optional(),
  shared: z.array(SharedSchema).optional(),
  serviceLines: z.array(z.object({
    id: z.string(),
    name: z.string().nonempty()
  })).optional()
}).strict();

type InferredView = z.infer<typeof ViewSchema>;

export type View = Omit<InferredView, "filter"> & { filter: RuleGroupType }
