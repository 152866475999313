import { useTheme } from "@mui/material";
import { type CSSObjectWithLabel, type StylesConfig, type GroupBase } from "react-select";
import { type FieldOption } from "@models/field-option.model";

const AsyncPaginatedSelectStyles = (isDisabled = false): StylesConfig<FieldOption, true, GroupBase<FieldOption>> => {
  const theme = useTheme();

  return {
    menuList: (base: CSSObjectWithLabel) => ({
      ...base
    }),
    placeholder: (base: CSSObjectWithLabel) => ({
      ...base,
      fontSize: "1rem",
      color: isDisabled
        ? theme.palette.text.disabled
        : theme.palette.text.primary
    }),
    input: (base: CSSObjectWithLabel) => ({
      ...base,
      color: theme.palette.text.primary,
      fontSize: "1rem"
    }),
    option: (base: CSSObjectWithLabel) => ({
      ...base,
      fontSize: "1rem",
      color: theme.palette.text.primary,
      fontWeight: 400,
      cursor: "pointer"
    }),
    loadingMessage: (base: CSSObjectWithLabel) => ({
      ...base,
      fontSize: "1rem",
      color: theme.palette.text.primary
    }),
    valueContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      padding: "0",
      color: theme.palette.secondary.light
    }),
    singleValue: (base: CSSObjectWithLabel) => ({
      ...base,
      fontSize: "1rem",
      color: theme.palette.text.primary
    }),
    multiValue: (base: CSSObjectWithLabel) => ({
      ...base,
      borderRadius: "1rem"
    }),
    multiValueLabel: (base: CSSObjectWithLabel) => ({
      ...base,
      color: theme.palette.text.primary
    }),
    multiValueRemove: (base: CSSObjectWithLabel) => ({
      ...base,
      color: theme.palette.secondary.light,
      "&:hover": {
        background: "rgba(0, 0, 0, 0.04)",
        color: theme.palette.secondary.light
      }
    }),
    control: () => ({
      display: "flex",
      borderBottom: `1px solid ${theme.palette.grey[600]}`,
      padding: "0",
      fontSize: "1rem",
      minWidth: "10rem",
      maxWidth: "22rem",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.text.primary
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.text.secondary
      }
    }),
    indicatorsContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      display: isDisabled ? "none" : "flex"
    })
  };
};

export default AsyncPaginatedSelectStyles;
