import { type HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

const getHubConnection = (url: string): HubConnection => {
  const conn = new HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => localStorage.getItem("accessToken")!,
      withCredentials: false
    })
    .withAutomaticReconnect()
    .build();

  return conn;
};

export default getHubConnection;
