import { useTheme } from "@mui/material/styles";
import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { type SelectChangeEvent } from "@mui/material/Select";
import dayjs, { type Dayjs } from "dayjs";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { type CSSProperties } from "react";
import { useImmer } from "use-immer";
import { useTimeout } from "usehooks-ts";
import { v4 as uuidv4 } from "uuid";
import MuiDatePicker from "@components/date-picker/date-picker.component";
import { BluePaper } from "@components/generic/generic.component";
import GraphLoader from "@components/graph-loader/graph-loader.component";
import ServiceLineCard from "@components/service-line-card/service-line-card.component";
import LeftPanel from "@layout/left-panel/left-panel.layout";
import dailyDataMock from "@mocks/daily-data.mock.json";
import monthlyDataMock from "@mocks/monthly-data.mock.json";
import teamsMock from "@mocks/teams.mock.json";
import yearlyDataMock from "@mocks/yearly-data.mock.json";
import { type Message } from "@models/message.model";
import { type Theme } from "@models/theme.model";
import { type Team } from "@models/team.model";
import useMessageStore from "@store/message.store";
import useSessionStore from "@store/session.store";
import { execute, executeAsync } from "@utils/handler.util";

const teamsList = teamsMock as unknown as Team[];
const dailyData = dailyDataMock;

const Home = (): JSX.Element => {
  const { graphStyles } = useTheme<Theme>();
  const [loading, setLoading] = useImmer<boolean>(true);
  const [date, setDate] = useImmer<string>("day");
  const [team, setTeam] = useImmer<string>("my_team");
  const [value, setValue] = useImmer<Dayjs | null>(dayjs("2014-08-18T21:11:54"));

  const handleChange = (event: SelectChangeEvent): void => {
    setDate(event.target.value);
  };

  const handleChangeTeam = (event: SelectChangeEvent): void => {
    setTeam(event.target.value);
  };

  const handleChangeDate = (newValue: Dayjs | null): void => {
    setValue(newValue);
  };

  useTimeout(() => setLoading(false), 2000);

  // Test stuff
  const messageStore = useMessageStore();
  const sessionStore = useSessionStore();
  const testToast = (): void => {
    const r = Math.random();
    const message: Message = {
      id: uuidv4(),
      description: `Test ${r}.`
    };
    messageStore.add?.(message);
  };
  const testHandlerError = (): void => {
    execute(() => {
      throw new Error("Event handler error.");
    }, sessionStore);
  };
  const testHandlerErrorAsync = async (): Promise<void> => {
    await executeAsync(async () => {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise(resolve => setTimeout(resolve, 1000));
      throw new Error("Async event handler error.");
    }, sessionStore);
  };

  return (
    <Box
      sx={{
        display: "flex",
        margin: "4rem 2rem 0 2rem",
        overflow: "auto",
        maxHeight: "100vh"
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <LeftPanel />
      </Box>
      <Divider sx={{ height: "100%" }} orientation="vertical" flexItem />
      <Box sx={{ mt: "1rem", width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BluePaper>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "25ch" }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>Time Frame</InputLabel>
                    <Select
                      labelId="time-frame-select-filled-label"
                      id="time-frame-select-filled"
                      value={date}
                      onChange={handleChange}
                    >
                      <MenuItem value="day">Day</MenuItem>
                      <MenuItem value="month">Month</MenuItem>
                      <MenuItem value="year">Year</MenuItem>
                    </Select>
                  </FormControl>
                  <MuiDatePicker label="Day" date={date} value={value} variant="filled" onChange={handleChangeDate} />
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "25ch" }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>Team</InputLabel>
                    <Select
                      labelId="team-select-filled-label"
                      id="team-select-filled"
                      value={team}
                      onChange={handleChangeTeam}
                    >
                      {teamsList.map((item, i) => (
                        <MenuItem key={i} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    id="filled-goal"
                    label="Goal"
                    variant="filled"
                  />
                </Box>
              </Box>
              {loading ? (
                <GraphLoader items={dailyData} />
              ) : (
                <ResponsiveContainer width="100%" height={420}>
                  <BarChart
                    // eslint-disable-next-line no-nested-ternary
                    data={date === "day" ? dailyData : (date === "month" ? monthlyDataMock : yearlyDataMock)}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 10,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid vertical={false} stroke={graphStyles.line.color} />
                    <XAxis
                      dataKey="name"
                      padding={{ left: 10, right: 10 }}
                      tick={graphStyles.tick}
                    />
                    <YAxis
                      domain={[0, "dataMax + 20"]}
                      tick={graphStyles.tick}
                      tickLine={{ stroke: graphStyles.line.color }}
                    />
                    <Tooltip
                      contentStyle={graphStyles.content}
                      labelStyle={graphStyles.label as CSSProperties}
                      itemStyle={graphStyles.item}
                    />
                    <Bar dataKey="Loura Ngiaga" stackId="a" fill={graphStyles.colors[0]} />
                    <Bar dataKey="katherine Maddison" stackId="a" fill={graphStyles.colors[1]} width={20} />
                    <Bar dataKey="Bree Lee" stackId="a" fill={graphStyles.colors[2]} width={20} />
                    <Bar dataKey="Brendon Bishop" stackId="a" fill={graphStyles.colors[3]} width={20} />
                    <Bar dataKey="Anan McAlpin" stackId="a" fill={graphStyles.colors[4]} width={20} />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </BluePaper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <ServiceLineCard />
          </Grid>
          <Grid item xs={2}>
            <BluePaper>
              <Box sx={{ height: "22.5rem" }} />
            </BluePaper>
          </Grid>
        </Grid>
      </Box>
      {import.meta.env.DEV && (
        <Box sx={{
          position: "absolute",
          bottom: "2rem",
          left: "3rem",
          width: "22rem"
        }}
        >
          <Button onClick={testToast}>Test Toast</Button>
          <Button onClick={testHandlerError}>Test Handler Error</Button>
          <Button onClick={testHandlerErrorAsync}>Test Handler Error Async</Button>
        </Box>
      )}
    </Box>
  );
};

export default Home;
