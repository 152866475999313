import MenuListItem from "@components/menu-list-item/menu-list-item.component";
import { type MenuItem } from "@models/menu-item.model";
import { Box } from "@mui/material";
import type * as React from "react";

interface DynamicColumnProps {
  menuItems: MenuItem[];
  onClose: () => void;
}

const DynamicNavigationMenu: React.FC<DynamicColumnProps> = ({ menuItems, onClose }) => {
  const calculateColumns = (items: MenuItem[], columnLimit: number): MenuItem[][] => {
    const columns: MenuItem[][] = [];
    let currentColumn: MenuItem[] = [];

    items?.forEach((item: MenuItem, index: number) => {
      currentColumn.push(item);

      item.items?.forEach(() => {
        currentColumn.push({ title: "", type: 1, url: "" });
      });

      if (currentColumn.length === columnLimit || (currentColumn.length > 0 && index === items.length - 1)) {
        columns.push([...currentColumn]);
        currentColumn = [];
      }
    });

    return columns;
  };

  const columns = calculateColumns(menuItems, 10);

  return (
    <Box sx={{ display: "flex" }}>
      {columns.map((column, columnIndex) => (
        <Box key={columnIndex}>
          {column.map((item, menuIndex) => (
            <MenuListItem {...item} key={menuIndex} onClose={onClose} />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default DynamicNavigationMenu;
