import { useState, useRef } from "react";
import {
  MenuItem,
  Box,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  Typography
} from "@mui/material";
import { ChevronLeft, ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { type FieldOption } from "@models/field-option.model";

// This function checks whether a point (x, y) is on the left or right side of a line formed by two points (px, py) and (qx, qy).
// If the result is negative, the point is on the right side of the line. If positive, it"s on the left side.
// It helps us determine if a point is on the same side as a vertex of the triangle when compared to its edges.
const sign = (px: number, py: number, qx: number, qy: number, rx: number, ry: number): number => {
  const signValue = (px - rx) * (qy - ry) - (qx - rx) * (py - ry);
  return signValue;
};

// This function checks if a point (x, y) is inside a triangle formed by three points (x1, y1), (x2, y2), and (x3, y3).
const pointInTriangle = (currentMouseCoordinates: number[], triangleCoordinates: number[][]): boolean => {
  const x = currentMouseCoordinates[0] as number;
  const y = currentMouseCoordinates[0] as number;

  const x1 = triangleCoordinates[0]?.[0] as number;
  const y1 = triangleCoordinates[0]?.[1] as number;
  const x2 = triangleCoordinates[1]?.[0] as number;
  const y2 = triangleCoordinates[1]?.[1] as number;
  const x3 = triangleCoordinates[2]?.[0] as number;
  const y3 = triangleCoordinates[2]?.[1] as number;

  const b1 = sign(x, y, x1, y1, x2, y2) <= 0;
  const b2 = sign(x, y, x2, y2, x3, y3) <= 0;
  const b3 = sign(x, y, x3, y3, x1, y1) <= 0;

  // If all signs are the same (either all negative or all positive), the point is inside the triangle.
  return b1 === b2 && b2 === b3;
};

interface SubMenuProps {
  menuLevels: number;
  options: FieldOption[];
  isLoading: boolean;
  value: FieldOption | undefined | null;
  onOptionClick: (option: FieldOption) => void;
  keyValue: string;
  recomposeSubMenu: boolean;
}

const SubMenu = ({
  options, menuLevels, onOptionClick, value, isLoading, keyValue, recomposeSubMenu }: SubMenuProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchors, setAnchors] = useState<{
    elements: Array<null | HTMLElement>;
    options: Array<null | typeof options>;
  }>({
    elements: new Array(menuLevels).fill(null),
    options: new Array(menuLevels).fill(null)
  });

  const mouseEntered = useRef<Record<string, boolean>>({});
  const mouseLeftCordinates = useRef<number[]>([]);
  const buttonRef = useRef<HTMLInputElement>(null);
  const mouseIdleTimer = useRef<NodeJS.Timeout | null>(null);

  const optionHasChildren = (option: FieldOption): boolean => {
    const hasChildrens = (option.children?.length ?? 0) > 0;
    return hasChildrens;
  };

  const handleOpen = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    level = 0,
    children = options
  ): void => {
    const target = event.target as HTMLElement;

    setIsOpen(true);
    setAnchors(prevAnchors => ({
      elements: prevAnchors.elements.map((element, index) => (index === level ? target : element)),
      options: prevAnchors.options.map((element, index) => (index === level ? children : element))
    }));
  };

  const handleClose = (level: number): void => {
    if (level === 0) {
      setIsOpen(false);
      setAnchors({
        elements: new Array(menuLevels).fill(null),
        options: new Array(menuLevels).fill(null)
      });
    } else {
      setAnchors(prevAnchors => ({
        elements: prevAnchors.elements.map((element, index) => (index >= level ? null : element)),
        options: prevAnchors.options.map((element, index) => (index >= level ? null : element))
      }));
    }
  };

  const handleClickAway = (event: MouseEvent | TouchEvent): void => {
    if (event.target === buttonRef.current) {
      handleClose(0);
      return;
    }

    const optionWithoutSubMenu = anchors.elements.every(
      element => !event.composedPath().includes(element!)
    );

    if (optionWithoutSubMenu) {
      handleClose(0);
    }
  };

  const handleClickOption = (option: FieldOption): void => {
    if (!optionHasChildren(option)) {
      handleClose(0);
      onOptionClick(option);
    }
  };

  const handleMouseMove = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: FieldOption
  ): void => {
    let shouldComputeSubMenuOpenLogic = true;
    const submenu = document.querySelector(`#nested-menu-${option.menuLevel as number + 1}`);

    const computeSubMenuLogic = (): void => {
      if (!mouseEntered.current[getId(option)]) {
        mouseEntered.current[getId(option)] = true;

        // Close all prior submenus if the mouse transitions from an option with a submenu to an option without a submenu.
        if (!optionHasChildren(option)) {
          handleClose(option.menuLevel as number + 1);
        } else if (
          // If the mouse moves from an option with a submenu to another option with a submenu,
          // open the submenu of the current option and close the submenu of the previous option.
          optionHasChildren(option)
          && anchors.options[option.menuLevel as number + 1]
          && !(option.children as FieldOption[]).every(
            (val, i) => val.value === anchors.options[option.menuLevel as number + 1]?.[i]?.value
          )
        ) {
          handleClose(option.menuLevel as number + 1);
          handleOpen(event, option.menuLevel as number + 1, option.children);
        } else {
          handleOpen(event, option.menuLevel as number + 1, option.children);
        }
      }
    };

    if (mouseLeftCordinates.current.length > 0 && submenu) {
      const { x, y, height } = submenu.getBoundingClientRect();

      const currentMouseCoordinates = [event.clientX, -event.clientY];
      const virtualTriangleCordinates = [
        [x, -y],
        [x, -(y + height)],
        [mouseLeftCordinates.current[0], mouseLeftCordinates.current[1]]
      ];

      if (pointInTriangle(currentMouseCoordinates, virtualTriangleCordinates as number[][])) {
        shouldComputeSubMenuOpenLogic = false;

        if (mouseIdleTimer.current) {
          clearTimeout(mouseIdleTimer.current);
        }

        // if mouse is inside triangle and yet hasn"t moved, we need to compute submenu logic after a delay
        mouseIdleTimer.current = setTimeout(() => {
          computeSubMenuLogic();
        }, 50);
      } else {
        shouldComputeSubMenuOpenLogic = true;
      }
    }

    if (shouldComputeSubMenuOpenLogic) {
      if (mouseIdleTimer.current) {
        clearTimeout(mouseIdleTimer.current);
      }

      computeSubMenuLogic();
    }
  };

  const handleMouseLeave = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: FieldOption
  ): void => {
    mouseLeftCordinates.current = [event.clientX, -event.clientY];

    if (mouseIdleTimer.current) {
      clearInterval(mouseIdleTimer.current);
    }

    mouseEntered.current[getId(option)] = false;
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLLIElement>,
    option: FieldOption
  ): void => {
    if (optionHasChildren(option)) {
      if (event.key === "ArrowLeft" || event.key === "Enter") {
        handleOpen(event, option.menuLevel as number + 1, option.children);
      }
    }

    if (event.key === "ArrowRight" && option.menuLevel as number > 0) {
      handleClose(option.menuLevel as number);
      anchors.elements[option.menuLevel as number]?.focus();
    }

    if (event.key === "Escape") {
      handleClose(0);
    }
  };

  const getId = (option: (typeof options)[0]): string => {
    const id = option.value;
    return id;
  };

  const onClickIcon = (): void => {
    if (buttonRef?.current) {
      buttonRef.current.click();
    }
  };

  const renderPlaceholder = (): string => {
    if (isLoading) {
      return "Loading...";
    }

    if (!value?.value) {
      return "Select Status...";
    }

    return value?.label;
  };

  return (
    <div key={keyValue}>
      <div
        className="NestedStatus"
        style={{
          display: "flex",
          flexDirection: "row",
          minWidth: "9rem",
          paddingBottom: "0.25rem"
        }}
      >
        <div
          tabIndex={0}
          ref={buttonRef}
          onClick={event => { !isOpen ? handleOpen(event) : handleClose(0); }}
          onKeyDown={event => { if (event.key === "Enter") { onClickIcon(); } }}
          role="combobox"
          aria-controls=":rj:"
          aria-expanded={isOpen}
        >
          <Typography>{renderPlaceholder()}</Typography>
        </div>
        {
          isOpen
            ? <ArrowDropUp data-testid="ArrowDropUpIcon" onClick={() => onClickIcon()} />
            : <ArrowDropDown data-testid="ArrowDropDownIcon" onClick={() => onClickIcon()} />
        }
      </div>

      <div>
        {
          recomposeSubMenu && (
            <div>
              {anchors.elements.map((anchorElement, index) => (
                anchorElement ? (
                  <Popper
                    style={{ zIndex: 5000, minWidth: "10rem" }}
                    open={Boolean(anchorElement)}
                    anchorEl={anchorElement}
                    key={`${anchorElement?.dataset?.optionid as string}_${index}_menu`}
                    role={undefined}
                    placement={index > 0 ? "left-start" : "bottom-start"}
                    transition
                    data-testid="nested-menu-item"
                  >
                    {({ TransitionProps }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: "top right" }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <MenuList
                              autoFocusItem={Boolean(anchorElement)}
                              aria-labelledby="nested-button"
                              sx={{
                                pointerEvents: "auto",
                                maxHeight: "25rem",
                                overflowY: "scroll",
                                height: "100%"
                              }}
                            >
                              {(anchors.options[index] ?? []).map((option, optionIndex) => (
                                <MenuItem
                                  key={`${option.value}_${index}_${optionIndex}_menu`}
                                  data-optionid={option.value}
                                  aria-haspopup={optionHasChildren(option) ? true : undefined}
                                  aria-expanded={
                                    optionHasChildren(option)
                                      ? anchors.elements.some(element => element?.dataset?.optionid === option.value)
                                      : undefined
                                  }
                                  onClick={() => handleClickOption(option)}
                                  onMouseMove={event => handleMouseMove(event, option)}
                                  onMouseLeave={event => handleMouseLeave(event, option)}
                                  onKeyDown={event => handleKeyDown(event, option)}
                                  sx={{
                                    backgroundColor: ((optionHasChildren(option)
                                      && anchors.elements.some(element => element?.dataset?.optionid === option.value)))
                                      ? "#e8e8e8" : ""
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      minWidth: "5rem",
                                      flexDirection: "row-reverse",
                                      alignItems: "center"
                                    }}
                                  >
                                    <Typography>{option.label}</Typography>
                                    {optionHasChildren(option) ? (<ChevronLeft fontSize="small" />) : null}
                                  </Box>
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                ) : null
              ))}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default SubMenu;
