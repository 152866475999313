import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useOutlet } from "react-router-dom";

const AnimatedOutlet = (): JSX.Element => {
  const o = useOutlet();
  const [outlet] = useState(o);

  return (
    <div data-testid="animated-outlet">
      {outlet}
    </div>
  );
};

const RootContainer = (): JSX.Element => {
  const location = useLocation();

  const variants = {
    initial: (locationPathname: string) => ({
      x: locationPathname === "/" ? "-100%" : "100%"
    }),
    animate: { x: 0 },
    exit: (locationPathname: string) => ({
      x: locationPathname === "/" ? "100%" : "-100%"
    })
  };

  return (
    <AnimatePresence mode="popLayout" initial={false} custom={location.pathname}>
      <motion.div
        key={location.pathname}
        variants={variants}
        custom={location.pathname}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 1 }}
        style={{ padding: "4rem 2rem 2rem 2rem" }}
      >
        <AnimatedOutlet />
      </motion.div>
    </AnimatePresence>
  );
};

export default RootContainer;
