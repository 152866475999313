import { type MenuItem } from "@models/menu-item.model";

const menu: MenuItem[] = [
  {
    title: "add",
    type: 3,
    location: "right",
    items: [
      {
        title: "New account",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/new-account`
      },
      {
        title: "Account loader",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/import`
      }
    ]
  },
  {
    title: "reports",
    type: 2,
    location: "right",
    items: [
      {
        title: "Audit",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/report/audit`
      },
      {
        title: "Bills",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/report/regular/bills`
      },
      {
        title: "Closing claims",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/report/regular/closing`
      },
      {
        title: "Invoicing",
        type: 3,
        items: [
          {
            title: "HCFS data",
            type: 3,
            url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/report/invoicing/hcfs`,
            roles: [
              "Admin",
              "Manager"
            ]
          },
          {
            title: "Hughston data",
            type: 3,
            url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/report/invoicing/hughston`,
            roles: [
              "Admin",
              "Manager"
            ]
          },
          {
            title: "Invoicing data",
            type: 3,
            url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/report/invoicing/invoicing`,
            roles: [
              "Admin",
              "Manager"
            ]
          }
        ]
      },
      {
        title: "Operational reports",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/report/regular/powerbi-operational-report`
      },
      {
        title: "PPA",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/report/regular/ppa`
      },
      {
        title: "Production",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/report/regular/production`
      },
      {
        title: "Worker's compensation",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/report/regular/workerscomp`
      },
      {
        title: "Tally board",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/report/regular/powerbi-tally-report`
      }
    ]
  },
  {
    title: "settings",
    type: 1,
    location: "right",
    items: [
      {
        title: "Health insurances",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/insurance/0`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Coverages",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/insurance/1`
      },
      {
        title: "OSM health care plan",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/healthcareplan`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Employers",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/employer`
      },
      {
        title: "Law firms",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/lawfirm`
      },
      {
        title: "Facilities",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/facility`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Facility npis",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/facilityNpis`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Physicians",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/physicians`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Tags",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/tag`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Rates",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/rate`,
        roles: [
          "Admin"
        ]
      },
      {
        title: "Users",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/user`,
        roles: [
          "Admin"
        ]
      },
      {
        title: "Bills reassignment",
        type: 2,
        items: [
          {
            title: "Reassign from facility",
            type: 3,
            url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/reassign-from-facility`,
            roles: [
              "Admin",
              "Manager"
            ]
          },
          {
            title: "Reassign from user",
            type: 3,
            url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/reassign-from-user`,
            roles: [
              "Admin",
              "Manager"
            ]
          }
        ]
      },
      {
        title: "Age of majority",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/majority-age`,
        roles: [
          "Admin"
        ]
      },
      {
        title: "Owners",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/owners`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Contracts",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/contracts`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Correspondence",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/correspondence`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Batch printing",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/batchPrinting`,
        isBatchPrinting: true
      },
      {
        title: "Service locations",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/serviceLocations`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Treatment types",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/treatmentTypes`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Treatment type groups",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/treatmentTypeGroups`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Web forms",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/webforms`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Note templates",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/note-templates`,
        roles: [
          "Admin",
          "Manager"
        ],
        featureFlag: "newnotes"
      },
      {
        title: "Statuses",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/statuses`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "iPlans",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/iPlans`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Universal loader",
        type: 2,
        items: [
          {
            title: "View loader",
            type: 3,
            url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/universal-loader`,
            roles: [
              "Admin",
              "Manager"
            ]
          },
          {
            title: "Edit lookups",
            type: 3,
            url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/lookups`,
            roles: [
              "Admin"
            ]
          }
        ]
      },
      {
        title: "Documents repository",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/documentsRepository`,
        roles: [
          "Admin",
          "Manager"
        ]
      },
      {
        title: "Mass bill info changes",
        type: 3,
        url: `${import.meta.env.VITE_LEGACY_COMPASS_URL}/admin-management/mass-bill-changes`,
        roles: [
          "Admin",
          "Manager"
        ]
      }
    ]
  }
];

export default menu;
