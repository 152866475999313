import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { type GlobalSearchItem } from "@models/global-search-item.model";
import { type BaseStore } from "@models/base-store.model";

interface Store extends BaseStore<GlobalSearchItem> {
  globalSearch: GlobalSearchItem[];
  currentAccount: GlobalSearchItem | null;
  setCurrentAccount: (account: GlobalSearchItem | null) => void;
}

const useGlobalSearchStore = create<Store>()(
  devtools(
    (set): Store => ({
      globalSearch: [],
      currentAccount: null,

      setIsLoading: isLoading => {
        set(state => ({ ...state, isLoading }), false, "setIsLoading");
      },

      load: globalSearch => {
        set(state => ({ ...state, globalSearch }), false, "load");
      },

      setCurrentAccount: (account: GlobalSearchItem | null) => {
        set(state => ({ ...state, currentAccount: account }), false, "setCurrentAccount");
      }
    }),
    { name: "GlobalSearch" }
  )
);

export default useGlobalSearchStore;
