import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { type BaseStore } from "@models/base-store.model";
import { type MenuItem } from "@models/menu-item.model";
import { getError } from "@utils/error.util";

interface Store extends BaseStore<MenuItem> {
  menu: MenuItem[];
}

const useMenuStore = create<Store>()(
  devtools(
    (set): Store => ({
      menu: [],

      setIsLoading: isLoading => {
        set(state => ({ ...state, isLoading }), false, "setIsLoading");
      },

      setError: err => {
        const error = getError(err);
        set(state => ({ ...state, error }), false, "setError");
      },

      load: menu => {
        set(state => ({ ...state, menu }), false, "load");
      }
    }),
    { name: "Menu" }
  )
);

export default useMenuStore;
