import { type HubConnection, HubConnectionState } from "@microsoft/signalr";
import { useState, useEffect } from "react";
import getHubConnection from "@services/hub-connection.service";

const useHubConnection = (url: string): HubConnection | undefined => {
  const [connection, setConnection] = useState<HubConnection>();

  useEffect(() => {
    const conn = getHubConnection(url);
    if (conn?.state === HubConnectionState.Disconnected) {
      void conn?.start()
        .then(() => setConnection(conn))
        .catch(error => console.error(error));
    }

    // Close the connection at unmount if connected.
    return () => {
      if (connection?.state !== HubConnectionState.Disconnected) {
        void connection?.stop();
      }
    };
  }, []);

  return connection;
};

export default useHubConnection;
