import { type QueryFunction, type InfiniteQueryObserverResult, type FetchNextPageOptions,
  type QueryKey, useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { type BaseStore } from "@models/base-store.model";
import { type PaginatedEntity } from "@models/paginated-entity.model";

type fetchNextPageFunction = (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<unknown, unknown>>;

const useLoadInfiniteStore = <TEntity, TStore extends PaginatedEntity<TEntity>>(
  key: QueryKey, queryFn: QueryFunction<unknown, QueryKey, unknown>, store: BaseStore<TStore>
):
  fetchNextPageFunction => {
  const { fetchNextPage,
    status,
    error,
    data
  } = useInfiniteQuery<unknown, unknown, { pages: TStore[] }, QueryKey, unknown>({
    queryKey: key,
    queryFn,
    initialPageParam: 0,
    getNextPageParam: (_: unknown, allPages: unknown[]) => allPages.length ?? 1,
    // Disable window focus refetching.
    refetchOnWindowFocus: false
  });
  const storeRef = useRef(store);

  useEffect(() => {
    storeRef.current.setIsLoading?.(status === "pending");
  }, [status]);

  useEffect(() => {
    storeRef.current.setError?.(error);
  }, [error]);

  useEffect(() => {
    if (data?.pages?.length && data?.pages?.length > 0) {
      const newData = ([] as TEntity[]).concat(...data.pages.map(x => x.data ?? []));
      const entity = data.pages[data.pages.length - 1] as PaginatedEntity<TEntity>;
      const newTotalCount = entity.totalCount ?? 0;
      const newTotalBillCount = entity.totalBillCount ?? 0;
      const newState = {
        data: newData,
        totalCount: newTotalCount,
        totalBillCount: newTotalBillCount
      };
      storeRef.current.loadSingle?.(newState as TStore);
    }
  }, [data]);

  return fetchNextPage;
};

export default useLoadInfiniteStore;
