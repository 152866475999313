import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { type BaseStore } from "@models/base-store.model";
import { type Message } from "@models/message.model";
import { removeItemFromList } from "@utils/list.util";

interface Store extends BaseStore<Message> {
  messages: Message[];
}

const useMessageStore = create<Store>()(
  devtools(
    (set): Store => ({
      messages: [],

      add: message => {
        set(state => ({ ...state, messages: [...state.messages, message] }), false, "add");
      },

      remove: id => {
        set(state => ({ ...state, messages: removeItemFromList(state.messages, id) }), false, "remove");
      }
    }),
    { name: "Message" }
  )
);

export default useMessageStore;
