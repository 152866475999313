import useHubConnection from "@hooks/use-hub-connection.hook";
import { Box, styled, Button, Drawer } from "@mui/material";
import { useEffect, useCallback, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { type AlertColor } from "@mui/material/Alert";
import { HubConnectionState } from "@microsoft/signalr";
import useSessionStore from "@store/session.store";
import AnimatedAlert from "@components/animated-alert/animated-alert.component";
import { getWorkQueueNotWorkedTodayNextAccount } from "@services/search.service";
import defaultSort from "@data/sort-default.data";

interface PreviewView {
  name: string;
  [key: string]: any;
}

const AccountsInfoAndNavigation = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  width: "97%",
  margin: "auto",
  top: "3rem",
  position: "sticky",
  padding: "0.1rem 0rem",
  backgroundColor: theme.palette.background.default
}));

const AccountsInfo = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  color: theme.palette.secondary.dark,
  marginLeft: "1rem",
  maxWidth: "calc((9 / 12)* 100% - 3.65rem)",
  width: "100%",

  "@media (max-width: 1712px)": {
    maxWidth: "calc((8 / 12)* 100% - 3.4rem)"
  }
}));

const NavigationInfo = styled("div")(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginLeft: "auto",
  marginRight: "3.7rem"
}));

const NextAccountButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  borderRadius: 0
}));

const IframeWrapper = styled("div")(() => ({
  height: "100%",
  width: "97%",
  margin: "auto"
}));

const ACCOUNTS_HUB_URL = `${import.meta.env.VITE_WORK_QUEUE_API_URL}/notifications`;

interface AnalystsWorkingDetails {
  incidentId: string;
  userId: string;
  userName: string;
  dateTime: string;
  workCompleted?: boolean;
}

const BucketsAccountPage = (): JSX.Element => {
  const { accountId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { session: { user } } = useSessionStore();
  const workedAccountsHub = useHubConnection(ACCOUNTS_HUB_URL);

  const [analystsWorkingAccount, setAnalystsWorkingAccount] = useState<AnalystsWorkingDetails[] | null>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("warning");

  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const [previewBucketName, setPreviewBucketName] = useState<string>("");

  const cleanupState = (): void => {
    setAnalystsWorkingAccount(null);
    setShowAlert(false);
    setAlertMessage("");
    setAlertSeverity("warning");
  };

  const handleUnloadAndUnmount = useCallback(() => {
    if (workedAccountsHub?.state === HubConnectionState.Connected && user?.id && accountId) {
      void workedAccountsHub?.send("AnalystStoppedWorkingBucketAccount", accountId, user?.id);
    }
  }, [workedAccountsHub, accountId, user]);

  useEffect(() => {
    // Call AnalystStoppedWorkingBucketAccount from helix as well
    // ComponentWillUnmount and Beforeunload listeners are not triggered in iframe
    window.addEventListener("beforeunload", handleUnloadAndUnmount);

    return () => {
      handleUnloadAndUnmount();
      window.removeEventListener("beforeunload", handleUnloadAndUnmount);
    };
  }, [handleUnloadAndUnmount]);

  useEffect(() => {
    // Prevent users to navigate on accounts vie the app URL
    if (!state?.index && state?.index !== 0) {
      navigate("/", { replace: true });
    }
  }, [navigate, state]);

  useEffect(() => {
    // Request the analysts working the account after the connection started with success.
    if (workedAccountsHub?.state === HubConnectionState.Connected && user?.id && accountId) {
      void workedAccountsHub?.send("RequestAnalystsWorkingBucketAccount", accountId, user?.id);
    }
  }, [accountId, workedAccountsHub, user]);

  useEffect(() => {
    // Update alert details when analystsWorkingAccount is changed
    let message = "";
    let severity: "info" | "warning" = "warning";

    /*
      Severity logic (info | warning)
        => actions from the past (worked accounts/no one is viewing) => severity = info
        => actions in the present (someone is viewing) => severity = warning
    */

    // No analysts are viewing the account
    if (analystsWorkingAccount && analystsWorkingAccount?.length === 0) {
      message = "No users are currently viewing this account.";
      severity = "info";
    }

    // One analyst is viewing the account
    if (analystsWorkingAccount && analystsWorkingAccount?.length === 1) {
      const workingAnalyst = analystsWorkingAccount[0];

      // Toggle the work/view message based on workCompleted field
      if (workingAnalyst?.workCompleted) {
        message = `${workingAnalyst?.userName ?? ""} has already worked this account today.`;
        severity = "info";
      } else {
        message = `${workingAnalyst?.userName ?? ""} has this account open.`;
      }
    }

    // Multiple analysts are viewing the account
    if (analystsWorkingAccount && analystsWorkingAccount?.length > 1) {
      let workingAnalyst = analystsWorkingAccount[0];
      if (analystsWorkingAccount.some(analyst => analyst.workCompleted)) {
        workingAnalyst = analystsWorkingAccount.find(analyst => analyst.workCompleted);
      }

      // Toggle the work/view baseMessage based on workCompleted field
      let baseMessage = `${workingAnalyst?.userName ?? ""} has this account open,`;
      if (workingAnalyst?.workCompleted) {
        baseMessage = `${workingAnalyst?.userName ?? ""} has already worked this account today,`;
      }

      const count = (analystsWorkingAccount?.length ?? 0) - 1;
      let additionalMessage = ".";
      if (count > 0) {
        additionalMessage = count === 1
          ? ` and ${count} other is viewing it.`
          : ` and ${count} others are viewing it.`;
      }

      message = `${baseMessage}${additionalMessage}`;
    }

    if (message !== alertMessage || severity !== alertSeverity) {
      setShowAlert(false);

      setAlertMessage(prevMessage => {
        if (message !== prevMessage) {
          return message;
        }

        return prevMessage;
      });
      setAlertSeverity(prevSeverity => {
        if (severity !== prevSeverity) {
          return severity;
        }

        return prevSeverity;
      });
    }
  }, [analystsWorkingAccount, alertMessage, alertSeverity]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(true);
    }, 800);
  }, [alertMessage, alertSeverity]);

  const moveToNextAccount = (): void => {
    cleanupState();
    getWorkQueueNotWorkedTodayNextAccount(state?.bucketId, accountId as string, state?.index, defaultSort)
      .then(result => {
        if (result?.data?.length === 1 && (result?.data[0]?.incidentId ?? "")?.length > 0) {
          const nextAccountId = result?.data[0]?.incidentId as string;
          const nextIndex = state?.index as number + 1;
          const disableNextButton = result?.totalCount === 1 || result?.totalCount === nextIndex + 1;
          navigate(
            `/account/${nextAccountId}`,
            {
              state: {
                bucketId: state?.bucketId,
                index: nextIndex,
                disableNextButton
              },
              replace: true
            }
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  workedAccountsHub?.onreconnected(() => {
    if (user?.id && accountId) {
      cleanupState();

      // Request the analysts working the account after the connection re-started with success.
      void workedAccountsHub?.send("RequestAnalystsWorkingBucketAccount", accountId, user?.id);
    }
  });

  workedAccountsHub?.off("AnalystStartsWorkingBucketAccount");
  workedAccountsHub?.on("AnalystStartsWorkingBucketAccount", (incidentId, userId, userName, dateTime) => {
    // New analyst starting to work the same account.
    if (incidentId === accountId && userId !== user?.id) {
      setAnalystsWorkingAccount(prevState => {
        const existingAnalyst = prevState?.find(analyst => analyst.userId === userId);
        if (!existingAnalyst) {
          const newAnalystWorkingAccount: AnalystsWorkingDetails = { incidentId, userId, userName, dateTime };
          if (prevState) {
            return [...prevState, newAnalystWorkingAccount];
          }

          return [newAnalystWorkingAccount];
        }

        return prevState;
      });
    }
  });

  workedAccountsHub?.off("AnalystStoppedWorkingBucketAccount");
  workedAccountsHub?.on("AnalystStoppedWorkingBucketAccount", (incidentId, userId) => {
    // Analyst stopped to work the same account.
    if (incidentId === accountId && userId !== user?.id) {
      setAnalystsWorkingAccount(prevState => {
        if (prevState) {
          const existingAnalyst = prevState?.find(analyst => analyst.userId === userId);
          if (existingAnalyst) {
            return prevState?.filter(analyst => analyst.userId !== userId);
          }
        }

        return prevState;
      });
    }
  });

  workedAccountsHub?.off("SetAnalystAccountWorkCompletedForTheDay");
  workedAccountsHub?.on("SetAnalystAccountWorkCompletedForTheDay", (incidentId, userId, userName, dateTime) => {
    // Another analyst already worked on that account
    if (incidentId === accountId && userId !== user?.id) {
      setAnalystsWorkingAccount(prevState => {
        const existingAnalyst = prevState?.find(analyst => analyst.userId === userId);
        // Update message, from analyst is viewing to analyst worked the account
        if (existingAnalyst) {
          existingAnalyst.workCompleted = true;
          return [...prevState as AnalystsWorkingDetails[]];
        }

        const newAnalystWorkingAccount: AnalystsWorkingDetails = { incidentId, userId, userName, dateTime, workCompleted: true };
        if (prevState) {
          return [...prevState, newAnalystWorkingAccount];
        }

        return [newAnalystWorkingAccount];
      });
    }
  });

  workedAccountsHub?.off("UpdateAnalystsWorkingTheSameBucketAccount");
  workedAccountsHub?.on("UpdateAnalystsWorkingTheSameBucketAccount", (incidentId, userId, userName, dateTime) => {
    // Initial setup - get and update all analysts that are already working the same account.
    if (incidentId === accountId && userId !== user?.id) {
      setAnalystsWorkingAccount(prevState => {
        const existingAnalyst = prevState?.find(analyst => analyst.userId === userId);
        if (!existingAnalyst) {
          const newAnalystWorkingAccount: AnalystsWorkingDetails = { incidentId, userId, userName, dateTime };
          if (prevState) {
            return [...prevState, newAnalystWorkingAccount];
          }

          return [newAnalystWorkingAccount];
        }

        return prevState;
      });
    }
  });

  const onDrawerClick = (): void => {
    sessionStorage.setItem("openDrawerOnce", "true");
    navigate("/", { replace: true });
  };

  const getPreviewView = (storedData:string):PreviewView => JSON.parse(storedData) as PreviewView;

  /**
   * Set the fake drawer name and visibility
   */
  useEffect(() => {
    const storedPreviewView:string | null = sessionStorage.getItem("previewView");
    if (storedPreviewView !== null) {
      const data:PreviewView = getPreviewView(storedPreviewView);
      setIsPreviewMode(true);
      setPreviewBucketName(data.name);
    }
  }, []);

  return (
    <>
      {isPreviewMode && (
      <Drawer
        anchor="right"
        open
        onClick={onDrawerClick}
        ModalProps={{ style: { position: "initial" }, disableEscapeKeyDown: true }}
        PaperProps={{
          style: {
            cursor: "pointer",
            width: "24rem",
            right: "-20rem",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            animation: "animateBackground 1.5s infinite alternate"
          }
        }}
        hideBackdrop
      >
        <div style={{ paddingTop: "8rem", marginLeft: "1rem" }}>
          {previewBucketName}
        </div>
      </Drawer>
      )}
      <Box>
        <AccountsInfoAndNavigation>
          <AccountsInfo>
            <AnimatedAlert
              show={showAlert}
              message={alertMessage}
              severity={alertSeverity}
              onClose={() => setShowAlert(false)}
            />
          </AccountsInfo>
          <NavigationInfo>
            <NextAccountButton
              variant="contained"
              onClick={moveToNextAccount}
              disabled={state?.bucketId === "" || state?.disableNextButton}
            >
              Next Account
            </NextAccountButton>
          </NavigationInfo>
        </AccountsInfoAndNavigation>

        <IframeWrapper>
          <iframe
            src={`${import.meta.env.VITE_LEGACY_COMPASS_URL}/incident/${accountId ?? ""}`}
            width="100%"
            height="100%"
            style={{ minHeight: "85vh", border: "0" }}
            title="card-content-iframe"
            data-testid="iframe"
            allow="clipboard-read; clipboard-write; display-capture"
          />
        </IframeWrapper>
      </Box>
    </>
  );
};

export default BucketsAccountPage;
