import { styled, Skeleton, ListItemButton, ListItemText } from "@mui/material";

const LoadingHeaderText = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  width: "70%",
  "&::after": {
    animationDuration: "1s",
    background: `linear-gradient(
        90deg,
        transparent,
        rgba(0, 0, 0, 0.28),
        transparent
      )`
  }
}));

const LoadingRow = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  width: "60%",
  "&::after": {
    animationDuration: "1s",
    background: `linear-gradient(
        90deg,
        transparent,
        rgba(0, 0, 0, 0.28),
        transparent
      )`
  }
}));

const tempArr = [1, 2, 3];

const ViewDrawerLoader = (): JSX.Element => (
  <>
    {tempArr.map((item => (
      <>
        <ListItemButton key={item}>
          <ListItemText primary={<LoadingHeaderText />} />
        </ListItemButton>
        <ListItemButton>
          <ListItemText
            primary={<LoadingRow sx={{ width: "50%" }} />}
            secondary={<LoadingRow sx={{ width: "40%" }} />}
          />
        </ListItemButton>
      </>
    )))}
  </>
);

export default ViewDrawerLoader;
