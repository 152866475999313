interface Props {
  items: string[];
}

const UnorderedList = ({ items }: Props): JSX.Element => (
  <ul>
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </ul>
);

export default UnorderedList;
