import { styled, Skeleton } from "@mui/material";
import { type GenericProps } from "@models/generic-props.model";

const LoadingWrapper = styled("div")(() => ({
  width: "100%",
  height: "23.25rem",
  margin: "1.5rem 1.5rem 1.5rem 4rem",
  display: "flex"
}));

const LoadingBlock = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  margin: "0 0.625rem 0.625rem 0.625rem",
  "&::after": {
    animationDuration: "1s",
    background: `linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 0.28),
      transparent
    )`
  }
}));

const GraphLoader = ({ items }: GenericProps): JSX.Element => (
  <LoadingWrapper>
    {items.map((_item, index) => (
      <div key={index}>
        <LoadingBlock data-testid="loading-block" variant="rounded" width="2.5rem" height="19.5rem" animation="wave" />
        <LoadingBlock data-testid="loading-block" variant="rounded" width="2.5rem" height="2.5rem" animation="wave" />
      </div>
    ))}
  </LoadingWrapper>
);

export default GraphLoader;
