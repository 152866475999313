import { z } from "zod";

export const SharedSchema = z.object({
  userId: z.string(),
  userName: z.string(),
  sharedOn: z.date().optional(),
  accepted: z.boolean().optional(),
  deletedOn: z.date().optional()
});

export type Shared = z.infer<typeof SharedSchema>;
