import { styled } from "@mui/material/styles";
import { type SvgItem } from "@models/svg-item.model";

const SvgImg = styled("img")(() => ({
  width: "18rem",
  height: "18rem",
  display: "flex",
  margin: "auto"
}));

const Svg = ({ img }: SvgItem): JSX.Element => <SvgImg src={img} alt="svg" />;

export default Svg;
