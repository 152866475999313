import * as React from "react";
import { Table as MuiTable, Skeleton } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { motion, AnimatePresence } from "framer-motion";
import { type ItemProps, TableVirtuoso, type TableComponents, type TableProps } from "react-virtuoso";
import useWorkedAccountsStore from "@store/worked-accounts.store";

type Data = Record<string, string>;

interface Columns {
  key: string;
  label: string;
  width?: number;
  renderer?: (value: Data) => JSX.Element
}

const emptyRows = Array(25).fill({}).map((_, index) => ({
  id: index,
  isLoading: true
}));

const loadingRows = Array(25).fill({}).map((_, index) => ({
  id: index,
  isLoading: true
}));

interface Props {
  columns: Columns[];
  data: any[];
  fetchNextPage: (index: number) => void;
  totalCount: number;
  isLoading: boolean;
}

const MotionTableRow = motion(TableRow);

const VirtuosoTableComponents: TableComponents<Data> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props: TableProps) => (
    <MuiTable
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed", background: "white", backgroundColor: "white" }}
    />
  ),
  // @ts-expect-error: the wrapping: TableHead: () => (<TableHead />), will result in the head not being displayed.
  // This is caused by the update to the virtuoso and should be revisited to check whether another further
  // update fixed it. TODO:
  TableHead,
  TableRow: ({ item, ...props }: ItemProps<Data>) => {
    const { workedTodayAccounts } = useWorkedAccountsStore();

    // Since virtuose unmounts the row if it's not visible, we need to differentiate between the rows that
    // were filtered out from the data array because they were already worked today and the ones that are
    // no longer visible, so that the exit animation plays only for the former.
    const isWorkedToday = workedTodayAccounts.some(workedAcc => workedAcc.incidentId === item.incidentId);
    const transition = isWorkedToday ? { duration: 1.5, ease: "easeIn" } : undefined;

    return (
      <AnimatePresence>
        <MotionTableRow
          {...props}
          sx={{ color: "black" }}
          key={item.incidentId}
          initial={{ opacity: 1, height: "auto" }}
          exit={isWorkedToday ? { opacity: 0, x: "-200%", scaleX: 0, backgroundColor: "grey" } : undefined}
          transition={transition}
          whileHover={{ backgroundColor: "#d3d3d3" }}
        />
      </AnimatePresence>
    );
  },
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody
      {...props}
      ref={ref}
      sx={{ color: "black" }}
    />
  ))
};

const rowContent = (columns: Columns[]): ((_index: number, row: Data) =>
  React.ReactNode) => function (_index: number, row: Data): React.ReactNode {
  const { isLoading } = row;
  if (isLoading) {
    return (
      <>
        {columns.map((column, index) => (
          <TableCell
            key={index}
            sx={{ color: "black", background: "white", backgroundColor: "white" }}
          >
            <Skeleton sx={{
              width: `${10 + 5 * (index % 2)}rem`,
              backgroundColor: "rgba(0, 0, 0, 0.25)"
            }}
            />
          </TableCell>
        ))}
      </>
    );
  }

  return (
    <>
      {columns.map((column, index) => {
        const { [column.key]: columnKey } = row;
        return (
          <TableCell
            key={index}
            sx={{
              color: "black"
            }}
          >
            {column.renderer ? column.renderer(row) : columnKey}
          </TableCell>
        );
      })}
    </>
  );
};

const fixedHeaderContent = (columns: Columns[]): (() => React.ReactNode) => function (): React.ReactNode {
  return (
    <TableRow>
      {columns.map((column, index) => (
        <TableCell
          key={index}
          sx={{ color: "black", background: "#d3d3d3" }}
          width={column.width ? `${column.width}%` : undefined}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
};

const Table = ({ columns, data, fetchNextPage, totalCount, isLoading }: Props): JSX.Element => {
  const header = fixedHeaderContent(columns);
  const row = rowContent(columns);
  const results = data.length === 0 ? emptyRows : data;
  return (
    <Paper style={{ height: "89vh", width: "100%", backgroundColor: "white", background: "white" }} elevation={1}>
      <TableVirtuoso
        height={800}
        data={isLoading ? loadingRows : results}
        components={VirtuosoTableComponents}
        fixedHeaderContent={header}
        itemContent={row}
        endReached={index => {
          if (data.length > 0 && data.length < totalCount) {
            fetchNextPage(index + 1);
          }
        }}
        totalCount={totalCount}
        overscan={50}
      />
    </Paper>
  );
};

export default Table;
