import { useState } from "react";
import { useInterval } from "usehooks-ts";
import { Alert, Snackbar, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import getBuildVersion from "@services/update-checker.service";
import getNumber from "@utils/number.util";

// extend the window object to include the app version
// for now this is only used in the version message component
// so I decided to not do this globaly
// if we need to use this in other places we can move it to a global file
// eslint-disable-next-line no-underscore-dangle
declare const window: {
  __APP_VERSION__: string;
} & Window;

// Default to 15 minutes if not set.
// This is the interval at which the app checks for updates
const interval = getNumber(import.meta.env.VITE_VERSION_UPDATE_CALL_INTERVAL, 900000);

// The current version of the app that's stored in the window object by the build process
// eslint-disable-next-line no-underscore-dangle
const currentVersion = window.__APP_VERSION__;

const AlertWrapper = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  width: "100%"
}));

const ButtonWrapper = styled(Button)(() => ({
  ml: 1,
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: 0
}));

const VersionMessage = (): JSX.Element => {
  const [showAlert, setShowAlert] = useState(false);

  useInterval(async () => {
    // fetch the current build version from the CDN
    const buildVersion = await getBuildVersion();

    // if the build version is different from the current version
    if (buildVersion !== currentVersion) {
      // alow the user to refresh the page if they want to
      setShowAlert(true);
    }
  }, interval);

  const refreshPage = async (): Promise<void> => {
    const names = await caches.keys();
    // delete all the caches before reloading the page
    // this is to ensure that the new version is loaded
    // and not the old cached version
    await Promise.all(names.map(async name => caches.delete(name)));
    // reload the page
    window.location.reload();
  };

  return (
    <Snackbar
      open={showAlert}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <AlertWrapper
        severity="info"
        variant="filled"
        classes={{ message: "version-alert-message", icon: "version-alert-icon" }}
      >
        System Updated
        <ButtonWrapper
          color="secondary"
          size="small"
          variant="text"
          disableRipple
          onClick={refreshPage}
          sx={{
            color: "white",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent"
            },
            "&.MuiButtonBase-root:click": {
              bgcolor: "transparent"
            }
          }}
        >
          REFRESH
        </ButtonWrapper>
      </AlertWrapper>
    </Snackbar>
  );
};

export default VersionMessage;
