import { create } from "zustand";
import { persist } from "zustand/middleware";
import { type WorkedTodayAccount } from "@models/worked-today-account.model";

export interface Store {
  workedTodayAccounts: WorkedTodayAccount[];
  addWorkedTodayAccount: (incidentId: string) => void;
  purgeOldAccountsWorkedToday: () => void;
}

const useWorkedAccountsStore = create<Store>()(
  persist(
    (set): Store => ({
      workedTodayAccounts: [],

      purgeOldAccountsWorkedToday: () => {
        const twentyMinsAgo = Date.now() - 20 * 60 * 1000;
        set(state => ({
          ...state,
          workedTodayAccounts: state.workedTodayAccounts.filter(x => x.timestamp > twentyMinsAgo)
        }), false);
      },

      addWorkedTodayAccount: incidentId => {
        const newWorkedAccount = { incidentId, timestamp: Date.now() };
        set(
          state => ({ ...state, workedTodayAccounts: [...state.workedTodayAccounts, newWorkedAccount] }),
          false
        );
      }
    }),
    { name: "WorkedAccountsStore", getStorage: () => localStorage }
  )
);

export default useWorkedAccountsStore;
