import { type AxiosError } from "axios";
import { datadogRum } from "@datadog/browser-rum";
import { type ErrorType } from "@custom-types/error-type.type";
import { type BaseStore } from "@models/base-store.model";
import { type Error } from "@models/error.model";
import { type ProblemDetails } from "@models/problem-details.model";
import useGlobalSearchStore from "@store/global-search.store";

interface ErrorMetadata {
  errorSource: string;
  my_custom_tag: string;
  [key: string]: string | undefined;
}

const getProblemDetails = (axiosError: AxiosError): ProblemDetails | undefined => {
  if (typeof axiosError.response?.data === "object") {
    return axiosError.response?.data as ProblemDetails;
  }

  return undefined;
};

const getAxiosError = <T>(error: T): Error => {
  const axiosError = error as AxiosError;
  let type: ErrorType;
  const problemDetails = getProblemDetails(axiosError);

  switch (axiosError.response?.status) {
    case 400:
      type = problemDetails?.title === "One or more validation errors occurred." ? "validation" : "alert";
      break;
    default:
      type = "alert";
      break;
  }

  const url = axiosError.config?.url;
  const message = `${axiosError.message}${url ? ` (${url})` : ""}.`;
  return {
    type,
    message,
    problemDetails
  };
};

const getError = (error: unknown): Error | undefined => {
  if (!error) {
    return undefined;
  }

  if (`${error as string}`.startsWith("AxiosError")) {
    return getAxiosError(error);
  }

  return {
    type: "alert",
    message: error as string
  };
};

const logError = <TEntity>(error: string, dbnum: string | undefined | null, store: BaseStore<TEntity>): void => {
  // eslint-disable-next-line no-console
  console.error(Error(error)); // Log to console

  const errorMetadata: ErrorMetadata = {
    errorSource: "my_error_source",
    my_custom_tag: "my_custom_value"
  };

  // Include dbnum in Datadog metadata if available
  if (dbnum) {
    errorMetadata.dbnum = dbnum;
  }

  datadogRum.addError(error, errorMetadata);

  store.setError?.(error);
};

const handleError = <TEntity>(error: unknown, store: BaseStore<TEntity>): void => {
  const { currentAccount } = useGlobalSearchStore.getState();
  const dbnum = currentAccount?.data?.dbnum;

  if (error instanceof Error) {
    logError(error.message, dbnum, store);
  } else if (typeof error === "string") {
    logError(error, dbnum, store);
  }
};

export { getError, handleError };
