import { useEffect, useState } from "react";
import { type Error } from "@models/error.model";
import useGlobalSearchStore from "@store/global-search.store";
import useMenuStore from "@store/menu.store";
import useSessionStore from "@store/session.store";
import useBasicViewStore from "@store/basic-view.store";
import useViewStore from "@store/view.store";
import useSearchStore from "@store/search.store";
import { addItemToList } from "@utils/list.util";

const addErrorToList = (list: Error[], error?: Error): Error[] => {
  if (!error) {
    return list;
  }

  const newList = addItemToList(list, error);
  return newList;
};

const addErrorsToList = (list: Error[], errors: Error[]): Error[] => {
  const newList = [...list, ...errors];
  return newList;
};

interface Props {
  errors: Error[];
  clearErrors: VoidFunction;
}

const useStoreErrorsHook = (): Props => {
  const globalSearchStore = useGlobalSearchStore();
  const menuStore = useMenuStore();
  const basicViewStore = useBasicViewStore();
  const viewStore = useViewStore();
  const sessionStore = useSessionStore();
  const searchStore = useSearchStore();

  const globalSearchError = globalSearchStore.error;
  const menuError = menuStore.error;
  const basicViewError = basicViewStore.error;
  const viewError = viewStore.error;
  const sessionErrors = sessionStore.session.errors;
  const searchError = searchStore.error;

  const [errors, setErrors] = useState<Error[]>([]);

  useEffect(() => {
    let errorList: Error[] = [];

    // Add single errors
    errorList = addErrorToList(errorList, globalSearchError);
    errorList = addErrorToList(errorList, menuError);
    errorList = addErrorToList(errorList, viewError);
    errorList = addErrorToList(errorList, searchError);
    errorList = addErrorToList(errorList, basicViewError);

    // Add multiple errors
    errorList = addErrorsToList(errorList, sessionErrors ?? []);

    setErrors(errorList);
  }, [globalSearchError, menuError, sessionErrors, viewError, basicViewError, searchError, setErrors]);

  const clearErrors = (): void => {
    globalSearchStore.setError?.();
    menuStore.setError?.();
    basicViewStore.setError?.();
    viewStore.setError?.();
    sessionStore.setError?.();
    searchStore.setError?.();
  };

  return { errors, clearErrors };
};

export default useStoreErrorsHook;
