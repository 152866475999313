import FilterValueTypes from "@enums/filter-value-type.enum";

const queryBuilderOptions = {
  assignedTo: { name: "assignedTo", type: FilterValueTypes.MultiSelect },
  activeAge: { name: "activeAge", type: FilterValueTypes.String },
  admissionDate: { name: "admissionDate", type: FilterValueTypes.String },
  balance: { name: "balance", type: FilterValueTypes.String },
  coder: { name: "coder", type: FilterValueTypes.MultiSelect },
  courtDate: { name: "courtDate", type: FilterValueTypes.String },
  createdDate: { name: "createdDate", type: FilterValueTypes.String },
  placedDate: { name: "placedDate", type: FilterValueTypes.String },
  dob: { name: "dob", type: FilterValueTypes.String },
  dis: { name: "dis", type: FilterValueTypes.String },
  dsw: { name: "dsw", type: FilterValueTypes.String },
  nurse: { name: "nurse", type: FilterValueTypes.MultiSelect },
  contract: { name: "contract", type: FilterValueTypes.MultiSelect },
  denialType: { name: "denialType", type: FilterValueTypes.MultiSelect },
  dischargeDate: { name: "dischargeDate", type: FilterValueTypes.String },
  facility: { name: "facility", type: FilterValueTypes.MultiSelect },
  facilityState: { name: "facilityState", type: FilterValueTypes.MultiSelect },
  firstName: { name: "firstName", type: FilterValueTypes.String },
  followUpDate: { name: "followUpDate", type: FilterValueTypes.String },
  healthDueDate: { name: "healthDueDate", type: FilterValueTypes.String },
  interpleaderIndicator: { name: "interpleaderIndicator", type: FilterValueTypes.Bool },
  lastName: { name: "lastName", type: FilterValueTypes.String },
  lienDueDate: { name: "lienDueDate", type: FilterValueTypes.String },
  lienFollowUp: { name: "lienFollowUp", type: FilterValueTypes.String },
  medicaidState: { name: "medicaidState", type: FilterValueTypes.MultiSelect },
  participant: { name: "participant", type: FilterValueTypes.MultiSelect },
  paymentApprover: { name: "paymentApprover", type: FilterValueTypes.MultiSelect },
  primaryInsurances: { name: "primaryInsurances", type: FilterValueTypes.MultiSelect },
  secondaryInsurances: { name: "secondaryInsurances", type: FilterValueTypes.MultiSelect },
  tertiaryInsurances: { name: "tertiaryInsurances", type: FilterValueTypes.MultiSelect },
  totalCharge: { name: "totalCharge", type: FilterValueTypes.String },
  primaryInsuranceState: { name: "primaryInsuranceState", type: FilterValueTypes.MultiSelect },
  tags: { name: "tags", type: FilterValueTypes.MultiSelect },
  treatmentTypes: { name: "treatmentTypes", type: FilterValueTypes.MultiSelect },
  reconsiderationReasons: { name: "reconsiderationReasons", type: FilterValueTypes.MultiSelect },
  phase: { name: "phase", type: FilterValueTypes.MultiSelect },
  status: { name: "status", type: FilterValueTypes.SingleSelect },
  billType: { name: "billType", type: FilterValueTypes.MultiSelect },
  deadlineType: { name: "deadlineType", type: FilterValueTypes.MultiSelect },
  upcomingBillDeadlineDate: { name: "upcomingBillDeadlineDate", type: FilterValueTypes.String },
  upcomingBillDeadlineType: { name: "upcomingBillDeadlineType", type: FilterValueTypes.MultiSelect },
  denialCategory: { name: "denialCategory", type: FilterValueTypes.MultiSelect },
  payorType: { name: "payorType", type: FilterValueTypes.MultiSelect },
  serviceLine: { name: "serviceLine", type: FilterValueTypes.MultiSelect }
};

export default queryBuilderOptions;
