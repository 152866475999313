import { type QueryFunction, useQuery, type QueryKey } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { type BaseStore } from "@models/base-store.model";

const useLoadSingleStore = <TStore>(
  key: QueryKey,
  queryFn: QueryFunction,
  store: BaseStore<TStore>
): void => {
  const { error, data, isLoading } = useQuery({ queryKey: key, queryFn });
  const storeRef = useRef(store);

  useEffect(() => {
    storeRef.current.setIsLoading?.(isLoading);
  }, [isLoading]);

  useEffect(() => {
    storeRef.current.setError?.(error);
  }, [error]);

  useEffect(() => {
    if (data) {
      storeRef.current.loadSingle?.(data as TStore);
    }
  }, [data]);
};

export default useLoadSingleStore;
