import { styled } from "@mui/material/styles";
import BackgroundImage from "@assets/images/login-background.webp";

const LoginContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  color: "rgba(1,1,1, 1)",
  backgroundColor: "rgba(255,255,255, 1)",
  "& .bg-container": {
    backgroundColor: "rgba(10, 76, 119, 0.2)",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: "center bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
    boxShadow: "inset -0.5rem 0 0.5rem 0 rgba(10, 76, 119, 0.25)",
    position: "relative",
    width: "40%",

    "@media (max-width: 600px)": {
      display: "none"
    },

    "@media (min-width: 960px)": {
      width: "50%"
    },

    "& .compass": {
      bottom: "2rem",
      height: "auto",
      left: "2rem",
      position: "absolute",
      width: "20rem"
    }
  },

  "& .login-container": {
    transform: "scale(0.55)",
    background: "#FFF",
    height: "100vh",
    position: "relative",
    width: "60%",

    "@media (max-width: 599.95px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "auto",
      minHeight: "50rem"
    },

    "@media (max-width: 959.95px)": {
      width: "100%"
    },

    "@media (min-width: 960px)": {
      width: "50%"
    },

    "& .login-wrapper": {
      background: "#FFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      left: "50%",
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: "40rem",
      zIndex: 15,

      "@media (max-width: 599.95px)": {
        height: "auto",
        minHeight: "50rem",
        position: "static",
        transform: "translate(0, 0)",
        width: "100%"
      },

      "& img.team": {
        "@media (max-width: 599.95px)": {
          height: "auto",
          margin: "0 auto",
          width: "40rem"
        }
      },

      "& .logo-wrapper": {
        "& img": {
          margin: "5rem auto",
          maxWidth: "35rem",

          "@media (max-width: 599.95px)": {
            height: "auto",
            width: "100%"
          }
        }
      },

      "& .form-wrapper": {
        "& .btn-success": {
          background: "#A6D069",
          borderRadius: "0.4rem",
          color: "#FFF",
          fontSize: "2.2rem",
          padding: "0.75rem 4rem",
          textTransform: "uppercase",

          "&:hover": {
            background: "rgba(166, 208, 105, 0.85)",
            color: "#FFF"
          }
        },

        "& .loader-container": {
          "&.big-loader": {
            "&:before": {
              background: "rgba(255, 255, 255, 0.9)"
            },
            ".outer, .middle, .inner": {
              border: "0.5rem solid transparent",
              borderTopColor: "#0A4C77",
              borderRightColor: "#0A4C77",
              borderBottomColor: "transparent",
              borderLeftColor: "transparent"
            }
          }
        }
      }
    }
  },
  ".authorized-use": {
    marginBottom: "5rem",
    fontSize: "1.2rem",
    textAlign: "justify",

    "@media (max-width: 767px)": {
      padding: "0 1.5rem"
    },

    strong: {
      display: "block",
      fontWeight: "bold",
      marginBottom: "1rem"
    }
  }
});

export default LoginContainer;
