import { patch, get } from "@utils/api.util";

const URL = `${import.meta.env.VITE_WORK_QUEUE_API_URL}/user`;

const updateLastBucketId = async (workQueueId: string): Promise<void> => (
  patch(`${URL}/last-work-queue`, workQueueId, { "Content-Type": "application/json" })
);

const getLastSelectedBucketId = async (): Promise<string> => (
  get(`${URL}/last-work-queue`)
);

export { updateLastBucketId, getLastSelectedBucketId };
