import { produce } from "immer";
import { type UserRole } from "@custom-types/user-role.type";
import MenuItemType from "@enums/menu-item-type.enum";
import { type MenuItem } from "@models/menu-item.model";
import { sortMenuItems } from "@utils/menu.util";

const IS_BATCH_PRINTING_RESPONSIBLE = localStorage.getItem("isBatchPrintingResponsable") === "true";

const addToSubmenu = (item: MenuItem, roles: UserRole[], submenu: MenuItem[], featureFlags: string[]): void => {
  const items: MenuItem[] = [];
  // Below is needed cause we are basically doing recursion through this helper function.
  // eslint-disable-next-line no-use-before-define
  item.items?.forEach(x => addMenuItem(x, roles, items, featureFlags));
  const newItem = produce(item, draft => {
    draft.items = sortMenuItems(items);
  });

  // If the menu item is a folder and ends up with no items, skip it.
  if (newItem.type === MenuItemType.Folder && !newItem.items?.length) {
    return;
  }

  // If the menu item is "batch printing" and the user doesn't have that responsibility, skip it.
  if (newItem.isBatchPrinting && !IS_BATCH_PRINTING_RESPONSIBLE) {
    return;
  }

  // If the menu item has a feature flag and it doesn't match any of the feature flags passed, skip it.
  if (newItem.featureFlag && !featureFlags.includes(newItem.featureFlag)) {
    return;
  }

  submenu.push(newItem);
};

const addMenuItem = (item: MenuItem, roles: UserRole[], submenu: MenuItem[], featureFlags: string[]): void => {
  const hasRoles = item.roles?.length;
  if (!hasRoles) {
    addToSubmenu(item, roles, submenu, featureFlags);
    return;
  }

  const found = roles?.some(x => item.roles?.includes(x));
  if (found) {
    addToSubmenu(item, roles, submenu, featureFlags);
  }
};

const filterMenu = (menu: MenuItem[], roles: UserRole[], featureFlags: string[]): MenuItem[] => {
  // Validate input data (trust no input data).
  if (menu.length === 0) {
    return [];
  }

  // Business logic.
  const filteredMenu: MenuItem[] = [];
  menu.forEach(item => addMenuItem(item, roles, filteredMenu, featureFlags));

  // Create success output.
  return sortMenuItems(filteredMenu);
};

export default filterMenu;
