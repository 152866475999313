import {
  type RuleGroupTypeAny, type RuleGroupType, type ValidationMap, type RuleType
} from "react-querybuilder";
import { isDayjs } from "dayjs";
import CombinatorTypes from "@enums/combinator-type.enum";

const isGroup = (possibleGroup: RuleGroupTypeAny): boolean => Array.isArray(possibleGroup.rules);

const disableMoreOptions = (query: RuleGroupType): boolean => {
  // Validate input data (trust no input data).
  if (!query) {
    return true;
  }

  // Business logic.
  // Check if the query has groups.
  const hasGroups = query.rules.some(rule => isGroup(rule as RuleGroupTypeAny));
  if (hasGroups) {
    return true;
  }

  // Check if the query main parent has the "OR" combinator.
  const parentGroupHasAnyCombinator = query.combinator === CombinatorTypes.Or;

  // Create success output.
  return parentGroupHasAnyCombinator;
};

const queryBuilderRecursiveValidator = (query: RuleGroupType, errors: ValidationMap): void => {
  // Validate input data (trust no input data).
  // Check if the group is empty (has no rules).
  if (Array.isArray(query.rules) && query.rules.length === 0) {
    errors[query.id as string] = { valid: false };
    return;
  }

  // Business logic.
  // Validate rules.
  const rules = query.rules.filter(rule => !isGroup(rule as RuleGroupTypeAny)) as RuleType[];
  if (rules.length > 0) {
    rules.forEach((rule: RuleType): void => {
      // Empty fields validation.
      if (rule.field === "~") {
        errors[rule.id as string] = { valid: false };
        return;
      }

      // Boolean validation - skipped.
      if (typeof (rule.value) === "boolean") {
        return;
      }

      // Date validation.
      if (isDayjs(rule.value) && !rule.value.isValid()) {
        errors[rule.id as string] = { valid: false };
        return;
      }

      // Empty rules value validation.
      if ((Array.isArray(rule.value) && rule.value.length === 0) || !rule.value) {
        errors[rule.id as string] = { valid: false };
      }
    });
  }

  // Validate groups.
  const groups = query.rules.filter(rule => isGroup(rule as RuleGroupTypeAny)) as RuleGroupType[];
  if (groups.length > 0) {
    groups.forEach((group: RuleGroupType): void => {
      queryBuilderRecursiveValidator(group, errors);
    });
  }
};

const queryBuilderValidator = (query: RuleGroupTypeAny): ValidationMap => {
  // Business logic.
  const errors = {} as const satisfies ValidationMap;
  queryBuilderRecursiveValidator(query as RuleGroupType, errors);

  // Create success output.
  return errors;
};

const isQueryValid = (query: RuleGroupTypeAny | undefined): boolean => {
  // Validate input data (trust no input data).
  if (!query) {
    return false;
  }

  // Business logic.
  const errors = queryBuilderValidator(query);

  // Create success output.
  return Object.keys(errors).length === 0;
};

export { disableMoreOptions, queryBuilderValidator, isQueryValid };
