/* eslint-disable max-len */
import { type Theme } from "@mui/material";
import { createTheme } from "@mui/material";
import { type GraphStyles } from "@models/graph-styles.model";
import { type ThemeColors } from "@models/theme-colors.model";

const getTheme = (colors: ThemeColors): Theme => {
  const graphStyles: GraphStyles = {
    height: 420,
    margin: {
      top: 20,
      right: 30,
      left: 10,
      bottom: 5
    },
    tick: {
      stroke: colors.graph.line,
      strokeWidth: 0.2,
      fill: colors.graph.line
    },
    line: {
      color: colors.graph.line
    },
    text: {
      color: colors.graph.line,
      width: 0.2
    },
    padding: 10,
    content: {
      background: colors.graph.contentBackground,
      borderRadius: 10,
      padding: 0,
      border: "none"
    },
    label: {
      padding: 6,
      textAlign: "center"
    },
    item: {
      padding: 6
    },
    colors: colors.graph.bars
  };

  const muiTheme = createTheme({
    palette: {
      primary: colors.primary,
      secondary: colors.secondary,
      warning: colors.warning,
      error: colors.error,
      info: colors.info,
      background: {
        default: colors.background.default,
        paper: colors.background.paper
      },
      text: {
        primary: colors.text.primary,
        secondary: colors.text.secondary,
        disabled: colors.text.disabled
      },
      divider: colors.divider,
      action: {
        selected: colors.card.selected
      }
    },
    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250, // Most basic recommended timing.
        standard: 300, // To be used in complex animations.
        complex: 375, // Recommended when something is entering screen.
        enteringScreen: 225, // Recommended when something is leaving screen.
        leavingScreen: 195
      },
      easing: {
        easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)", // Most common easing curve.
        easeOut: "cubic-bezier(0.0, 0, 0.2, 1)", // Objects enter the screen at full velocity from off-screen and slowly decelerate to a resting point.
        easeIn: "cubic-bezier(0.4, 0, 1, 1)", // Objects leave the screen at full velocity. They do not decelerate when off-screen.
        sharp: "cubic-bezier(0.4, 0, 0.6, 1)" // Used by objects that may return to the screen at any time.
      }
    },
    typography: {
      fontSize: 16,
      h1: {
        fontSize: "2.5rem"
      },
      h2: {
        fontSize: "2rem"
      },
      h3: {
        fontSize: "1.5rem",
        lineHeight: 1.23
      },
      h4: {
        fontSize: "1.25rem"
      },
      h5: {
        fontSize: "1rem"
      },
      h6: {
        fontSize: "0.75rem"
      },
      subtitle1: {
        fontSize: "1rem"
      },
      subtitle2: {
        fontSize: "0.875rem"
      },
      body1: {
        fontSize: "1rem"
      },
      body2: {
        fontSize: "0.875rem"
      },
      caption: {
        fontSize: "0.75rem"
      },
      overline: {
        fontSize: "0.75rem"
      }
    },
    shape: {
      borderRadius: 0
    },
    components: {
      MuiSelect: {
        styleOverrides: {

          icon: {
            color: colors.info.main
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            borderRadius: "0.25rem",
            ...(ownerState.variant === "filled" && {
              backgroundColor: colors.secondary.light,
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5,
              svg: {
                color: colors.info.main
              }
            })
          })
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: colors.info.main
          }
        }
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: colors.text.primary
          }
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            maxHeight: "25rem"
          }
        }
      }
    }
  }, {
    graphStyles
  });

  return muiTheme satisfies Theme;
};

export default getTheme;
