import React from "react";
import Dialog, { type DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography, Zoom } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";

interface AccountDialogProps {
  open: boolean;
  close: () => void;
  id?: string;
  dbnum?: string;
  billId?: string;
  workedOn?: boolean | null;
}

const Transition = React.forwardRef((
  props: any,
  ref: React.Ref<unknown>
) => <Zoom timeout={1000} ref={ref} {...props} />);

const AccountDialog: React.FC<AccountDialogProps> = ({ open, close, id, dbnum, billId, workedOn }) => {
  const [scroll] = React.useState<DialogProps["scroll"]>("paper");
  return (
    <Dialog
      fullWidth
      PaperProps={{
        style: {
          maxWidth: "100vw"
        }
      }}
      open={open}
      aria-label="account-dialog-title"
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
    >
      <DialogTitle id="account-dialog-title">
        <Typography color={theme => theme.palette.grey[800]} variant="body1">Search result : {dbnum}</Typography>
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[800]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"} sx={{ padding: 0, border: "none" }}>
        {workedOn && (
          <Box sx={{ padding: "0.25rem 1rem" }}>
            <Typography color={theme => theme.palette.grey[800]} variant="body2">Analysts are working</Typography>
          </Box>
        )}
        <iframe
          src={`${import.meta.env.VITE_LEGACY_COMPASS_URL}/incident/${id ?? ""}/${billId ?? ""}`}
          width="100%"
          height="100%"
          title="dialog-content-iframe"
          style={{ minHeight: "85vh" }}
          data-testid="iframe"
          allow="clipboard-read; clipboard-write; display-capture"
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <MenuItem onClick={close} sx={{ color: theme => theme.palette.grey[800] }}>
          CLOSE
        </MenuItem>
      </DialogActions>
    </Dialog>
  );
};
AccountDialog.defaultProps = {
  id: "",
  billId: "",
  dbnum: "",
  workedOn: false
};

export default AccountDialog;
