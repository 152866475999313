import { type ThemeColors } from "@models/theme-colors.model";
import getTheme from "@utils/theme.util";

const colors: ThemeColors = {
  primary: {
    main: "#1570ef",
    light: "#53b1fd",
    dark: "#1849a9",
    contrastText: "#fff"
  },
  secondary: {
    main: "#4a5578",
    light: "#7d89b0",
    dark: "#30374f",
    contrastText: "#000"
  },
  warning: {
    main: "#F4AA47",
    light: "#FCB931",
    dark: "#ed6c02"
  },
  error: {
    main: "#ff8389"
  },
  info: {
    main: "#00000099", // Assuming this is the info color; adjust if needed
    light: "#E0C714",
    dark: "#283041"
  },
  text: {
    primary: "#000000de",
    secondary: "#ABC7FF",
    disabled: "#B5B5B5"
  },
  graph: {
    line: "#005cbc",
    contentBackground: "#041B3F",
    bars: ["#F9E557", "#ACD95A", "#9EEDAB", "#427890", "#423378"]
  },
  card: {
    selected: "#36BFFA14"
  },
  divider: "rgba(0, 0, 0, 0.12)", // Assuming primary main color is used for divider; adjust if needed
  background: {
    paper: "#ffffff",
    default: "#f5f5f5",
    paperElevation0: "#ffffff",
    paperElevation1: "#ffffff",
    paperElevation2: "#f5f5f5"
  }
};

const defaultTheme = getTheme(colors);

export default defaultTheme;
