import { useEffect, useState } from "react";
import Alert from "@components/alert/alert.component";
import UnorderedList from "@components/unordered-list/unordered-list.component";
import useStoreErrorsHook from "@hooks/store-errors.hook";
import { type AlertButton } from "@models/alert-button.model";

const ErrorsAlert = (): JSX.Element => {
  const { errors: storeErrors, clearErrors } = useStoreErrorsHook();
  const errors = storeErrors.filter(x => x.type === "alert").map(x => x.message!);

  const [showAlert, setShowAlert] = useState(storeErrors.length > 0);

  const errorCount = errors.length;
  const title = `The following error${errorCount === 1 ? "" : "s"} occurred:`;
  const message = errorCount === 1 ? errors[0] : <UnorderedList items={errors} />;
  const handleClose = (): void => {
    clearErrors();
    setShowAlert(false);
  };
  const buttons: AlertButton[] = [{
    text: "OK",
    onClick: () => handleClose()
  }];

  useEffect(() => {
    setShowAlert(errors.length > 0);
  }, [errors.length]);

  return (
    <Alert show={showAlert} title={title} message={message} buttons={buttons} handleClose={handleClose} />
  );
};

export default ErrorsAlert;
