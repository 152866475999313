import { jwtDecode } from "jwt-decode";
import { type UserRole } from "@custom-types/user-role.type";
import ServiceLine from "@enums/service-line.enum";
import { type User } from "@models/user.model";

interface Decoded {
  oid: string;
  name: string;
  upn: string;
  roles: string[];
  preferred_username: string;
}

const getUser = (): User | undefined => {
  const token = localStorage.getItem("accessToken")!;
  if (!token) {
    return undefined;
  }

  const decoded: Decoded = jwtDecode(token);
  const { oid: id, name, upn: email, roles } = decoded;
  const user = {
    id,
    name,
    email: email.toLowerCase(),
    serviceLines: getServiceLines(roles),
    roles: getUserRoles(roles)
  } as const satisfies User;
  return user;
};

const getUserRoles = (roles: string[]): UserRole[] => {
  const userRoles: UserRole[] = [];

  roles.forEach(role => {
    switch (role) {
      case "00dba41b-43d3-4c9d-9fa5-36188a7bd900":
        userRoles.push("Admin");
        break;
      case "cd30d7ee-76ca-417a-8439-5ca3d8e7fd77":
        userRoles.push("Analyst");
        break;
      case "0d8c7c58-3583-47e9-8c19-f3d219d1152f":
        userRoles.push("Manager");
        break;
      case "6bfedf43-ba21-4843-ac88-a9898c0bf048":
        userRoles.push("Legal");
        break;
      case "c391e9ec-0bc1-4930-a681-2d4637771b44":
        userRoles.push("AccountingAdmin");
        break;
      case "dae775e6-5298-46b9-8e24-b319544b06cb":
        userRoles.push("Nurse");
        break;
      case "e47b47e6-464f-4874-b9bb-a758ac280600":
        userRoles.push("Coder");
        break;
    }
  });

  return userRoles;
};

const getServiceLines = (roles: string[]): ServiceLine[] => {
  const serviceLines: ServiceLine[] = [];

  roles.forEach(role => {
    switch (role) {
      case "'5aa0c11a-2582-4ec1-8a94-ccdd575b1152":
        serviceLines.push(ServiceLine.MVA);
        break;
      case "fb4e7e20-d1d6-43b7-b0d7-dc982009ac73":
        serviceLines.push(ServiceLine.WC);
        break;
      case "8feb45f3-22f8-4697-8447-cd734acafcc4":
        serviceLines.push(ServiceLine.Tricare);
        break;
      case "5a3de28d-36fe-4170-bdf1-ed34d10d79c8":
        serviceLines.push(ServiceLine.VA);
        break;
      case "'83157111-925c-4361-aaac-33498fc80eba":
        serviceLines.push(ServiceLine.PL);
        break;
      case "c085b723-56ef-4622-96e1-50a8e8fa0411":
        serviceLines.push(ServiceLine.Denials);
        break;
      case "dad02ef1-c49d-4254-83dd-081ea774ac47":
        serviceLines.push(ServiceLine.OSM);
        break;
      case "37560485-5b48-467a-82e7-fb9574b53014":
        serviceLines.push(ServiceLine.CD);
        break;
      case "e4744f1e-ec21-4b9b-a320-a47ccf396ec9":
        serviceLines.push(ServiceLine.AMB_WC);
        break;
      case "5515c49e-d2f8-45a5-a5d0-2f2fb80f8888":
        serviceLines.push(ServiceLine.AMB_MVA_PL);
        break;
    }
  });

  return serviceLines;
};

export default getUser;
