import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface ConfirmationDialogProps {
  title: string;
  message: string;
  confirmLabel?: string;
  cancelLabel?: string;
  open: boolean;
  confirm: () => void;
  cancel?: () => void;
}

const ConfirmationDialog = ({ open, title,
  message, confirm, cancel, confirmLabel, cancelLabel }: ConfirmationDialogProps): JSX.Element => (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancel && (<Button onClick={cancel}>{cancelLabel}</Button>)}
        <Button onClick={confirm} variant="contained" autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
);

ConfirmationDialog.defaultProps = {
  cancel: null,
  confirmLabel: "Ok",
  cancelLabel: "Cancel"
};

export default ConfirmationDialog;
