enum SortType {
  AssignedTo = "AssignedTo",
  AdmissionDate = "AdmissionDate",
  Balance = "Balance",
  Dsw = "Dsw",
  FollowupDate = "FollowupDate",
  TimelyFiling = "TimelyFiling",
  TotalCharge = "TotalCharge"
}

export default SortType;
